

















import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { FormattingService } from '../services/FormattingService';
import { PlaybackStateChangedEvent } from '../services/PlaybackService';
import { SelectionChangedEvent, SelectionMode, SelectionState } from '../services/SelectionService';
import { ServiceBarrier, Services } from '../services/Services';
@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class PlaybackComponent extends Vue{
    playbackState = false;
    time: string= "Out of time";
    time2: string = "blubber";
    
    private internal_speed_ok = true;
    private internal_speed_str = "1d";

    stalled = false;

    speedChanged(){
        let speed_milliseconds = FormattingService.duration_string_to_milliseconds(this.internal_speed_str);
        if(speed_milliseconds != null){
            Services.PlaybackService.setSpeed(speed_milliseconds);
            this.internal_speed_ok = true;
        }else{
            this.internal_speed_ok = false;
        }
    }

    usualSpeed: number;

    mounted(){
        Services.InteractionService.mouseDownHandlers.set("selections", () => {});
        this.updateTime();
        Services.TimeService.addEventListener("CurrentTimeChanged", () => this.updateTime());
        Services.PlaybackService.addEventListener("PlaybackStateChanged", (e: PlaybackStateChangedEvent) => this.playbackState = e.state);
        Services.InteractionService.mouseUpHandlers.set("playback-fast-forward", (e) => {
            Services.PlaybackService.playback_overridden = false;
            Services.PlaybackService.begin_playing(1);
        });
        Services.InteractionService.mouseUpHandlers.set("playback-fast-reverse", (e) => {
            Services.PlaybackService.playback_overridden = false;
            Services.PlaybackService.begin_playing(1);
        });
    }

    private updateTime(){
        let tr = Services.TimeService.getCurrentTimeRange();
        this.stalled = Services.PlaybackService.stalled;
        if(tr[1] == tr[0]){
            this.time = FormattingService.time_to_string(Services.TimeService.getMeanTime());
            this.time2 = null;
        }else{
            this.time = FormattingService.time_to_string(tr[0]);
            this.time2 = FormattingService.time_to_string(tr[1]);
        }
    }

    playpause(){
        Services.PlaybackService.togglePlaying();
        this.stalled = Services.PlaybackService.stalled;
    }

    reverse(e){
        Services.InteractionService.mousedown("playback-fast-forward", e);
        Services.PlaybackService.playback_overridden = true;
        Services.PlaybackService.begin_playing(-16);
    }

    forward(e){
        Services.InteractionService.mousedown("playback-fast-reverse", e);
        Services.PlaybackService.playback_overridden = true;
        Services.PlaybackService.begin_playing(16);
    }

    start(){
        let pbs = this.playbackState;
        Services.PlaybackService.setPlaybackState(false);
        Services.TimeService.moveTimeStart();
        Services.PlaybackService.setPlaybackState(pbs);
    }

    end(){
        let pbs = this.playbackState;
        Services.PlaybackService.setPlaybackState(false);
        Services.TimeService.moveTimeEnd();
        Services.PlaybackService.setPlaybackState(pbs);
    }
    
}
