




















import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { Services } from '../services/Services';
import { PlaybackStateEvent, RecordingStateEvent } from '../services/RecordingService';
@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class RecordingPlaybackComponent extends Vue{
    isPlayingBack = false;
    isRecording = false;
    recordingEventCount = 0;
    playbackEventCount = 0;
    usualSpeed: number;

    mounted(){
        Services.InteractionService.mouseDownHandlers.set("selections", () => {});
        Services.RecordingService.addEventListener("RecordingState", (re: RecordingStateEvent) => {
            this.isRecording = re.recording;
            this.recordingEventCount = re.event_number;
        });
        Services.RecordingService.addEventListener("PlaybackState", (pe: PlaybackStateEvent) => {
            this.isPlayingBack = pe.playback;
            this.playbackEventCount = pe.event_number;
        });
    }

    playpause(){
        if(this.isPlayingBack){
            console.log("Stopping pb")
            Services.RecordingService.stopPlayback();
        }else{
            console.log("Starting pb")
            Services.RecordingService.startPlayback();
        }
    }

    frameRecord(){
        if(this.isPlayingBack){
            console.log("Stopping pb")
            Services.RecordingService.stopPlayback();
        }else{
            console.log("Starting pb")
            Services.RecordingService.startPlayback(true);
        }
    }

    record(){
        if(this.isRecording){
            Services.RecordingService.stopRecording();
        }else{
            Services.RecordingService.startRecording();
        }
    }

    start(){
        Services.RecordingService.goStartPlayback();
    }

    end(){
        Services.RecordingService.goEndPlayback();
    }

    exportActions(){
        let json = JSON.stringify(Services.RecordingService.recordingActions, null, 4);
        let bytes = new TextEncoder().encode(json);
        let b = new Blob([bytes]);
        let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
        a_elem.setAttribute("download", Services.InitializationService.SceneName.replaceAll(/[^a-zA-Z0-9]/g, "_") + "_actions.json");
        a_elem.setAttribute("href", URL.createObjectURL(b));
        a_elem.click();
    }

    importActions(){
        document.getElementById("actionsFile").click();
    }

    async loadActionsFile(e){
        let resp = new Response(e.target.files[0]);
        let file = await resp.json();
        Services.RecordingService.stopRecording();
        Services.RecordingService.stopPlayback();
        Services.RecordingService.recordingActions = file;
        Services.RecordingService.goStartPlayback();
    }
    
}
