













































import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import { Coord, UEC, UECArea } from '../modules/tile';
import { SamplePoint, SamplePointData } from '../services/PointSamplingService';
import { SelectionMode } from '../services/SelectionService';
import { ServiceBarrier, Services } from '../services/Services';
import { SourceLayerInfo } from '../services/SourceInfoService';
import * as D3Graphs from '../modules/D3Graphs';
import { select } from '../d3';

type DimensionType = {
    name: string,
    value: {
        type: "inherent" | "layer",
        name: string
    }
}

@Component({
    components: {
    },
    props:{
    }
})
export default class GraphComponent extends Vue{
    loadingState = "null";
    hasSVG = false;
    status = null;
    graphTypes = [
        {name: "Scatterplot", value: {"type": "scatter", "dimensions": ["X", "Y", "Intensity"], "instantiator": D3Graphs.drawScatterPlot}},
        {name: "Lineplot", value: {"type": "line", "dimensions": ["X", "Y", "Intensity"], "instantiator": D3Graphs.drawXYPlot}},
        {name: "Featherplot", value: {"type": "feather", "dimensions": ["Direction"], "instantiator": D3Graphs.drawFeatherPlot}},
        //{name: "Histogram", value: {type: "histogram", "dimensions": ["Variable"]}}
    ];
    selectedGraph = this.graphTypes[0].value;
    
    layer: RenderLayer = null;

    dimensionTypes: DimensionType[] = [
        {name: "Longitude", value:{type: "inherent", name:"x"}},
        {name: "Latitude", value:{type: "inherent", name:"y"}},
        {name: "Height", value:{type: "inherent", name:"z"}},
        {name: "Time", value: {type: "inherent", name:"t"}}
    ]

    selectedDimension: {[name: string]: DimensionType} = {}

    getDimensions(): DimensionType[]{
        //Request all source layer infos
        let layer_slis = this.layer?.getSourceInfos() || [];
        //Add the usual dimensions
        let dimensions = [...this.dimensionTypes];
        //Set for dedup
        let paths = new Set();
        //Add layers already used in the renderlayer
        for(let sli of layer_slis){
            if(!paths.has(sli.getPath())){
                dimensions.push({
                    name: `${sli.instance_name} (${sli.layer_name})`,
                    value: {
                        type: "layer",
                        name: sli.getPath()
                    }
                });
                paths.add(sli.getPath());   
            }
        }
        //Add other layers
        for(let sli of layer_slis){
            for(let layer of sli.source.layers){
                let layer_sli = new SourceLayerInfo(sli.source, layer);
                if(!paths.has(layer_sli.getPath())){
                    dimensions.push({
                        name: `${layer_sli.instance_name} (${layer_sli.layer_name})`,
                        value: {
                            type: "layer",
                            name: layer_sli.getPath()
                        }
                    });
                    paths.add(layer_sli.getPath());   
                }
            }
        }
        return dimensions;
    }

    fillDimensions(){
        let dimtypes = this.getDimensions();
        let ctr = 0;
        for(let dimname of this.selectedGraph.dimensions){
            this.selectedDimension[dimname] = dimtypes[ctr];
            ctr++;
            if(ctr > dimtypes.length){
                ctr = 0;
            }
        }
    }

    selectedGraphChanged(){
        this.fillDimensions();
    }

    created(){
        this.layer = Services.RenderLayerService.getSelectedLayer();
        this.fillDimensions();
    }

    async createGraph(){
        //1. Find data that needs to be requested
        let slis_set: Set<SourceLayerInfo> = new Set();
        for(let dimname of this.selectedGraph.dimensions){
            let selectedDimension: DimensionType = this.selectedDimension[dimname];
            if(selectedDimension.value.type == "layer"){
                slis_set.add(Services.SourceInfoService.get_source_layer_info_by_path(selectedDimension.value.name));
            }
        }
        //2. If no actual layer has been selected, add the one that was selected at the start
        if(slis_set.size == 0){
            slis_set.add(this.layer.getSourceInfos()[0]);
        }
        let slis = [...slis_set];
        //3. Requestslayerdata
        let layerdatas: Map<string, SamplePointData> = new Map();
        this.status = "Loading data";
        let total_slis = slis.length;
        let loaded_slis = 0;

        //Get selection
        let selection = Services.SelectionService.getSelectedRectangle();
        //Extend selection to everything if nothing is selected
        if(selection.extent.x == 0 && selection.extent.y == 0){
            selection = new UECArea(new UEC(0,0), new UEC(1,1));
        }


        for(let sli of slis){
            this.status = `Loading ${loaded_slis + 1}/${total_slis}`;
            layerdatas.set(sli.getPath(), 
                await Services.PointSamplingService.sampleUECArea(
                    sli,
                    selection,
                    null,
                    Services.TimeService.getCurrentTimeRange(),
                    (_p) => {}
                ));
        }

        //4. Combine slis if more than one
        let graphdata: SamplePointData;
        let slis_paths = {};
        if(slis.length == 1){
            graphdata =  layerdatas.get(slis[0].getPath());
            slis_paths[slis[0].getPath()] = 0;
        }else{
            this.status = "Combining data";
            //We have more than one layer. Luckily, SamplePointData allows us to merge data by time and location
            //This will almost never do what we want it to do, but for now it's okay I think
            //In future this should be handled by the server
            console.warn("Merging data! This almost never does what you want it to do!");
            
            //This collects the first three keys. We can't merge more than three layers, because what would that even mean. Also our variables are Vector3D.
            let ldkeys = [... layerdatas.keys()].slice(0,3);
            //This is our base layer
            graphdata = layerdatas.get(ldkeys[0]);
            //We tell our merger function that the base layer occupies the leftmost slot
            slis_paths[ldkeys[0]] = 0;
            //Now, for the other two layers
            for(let i = 1; i < 3; i++){
                if(ldkeys[i]){ //Only if we actually have data there
                    //We merge them into the pool
                    //console.log("Merging with", layerdatas.get(ldkeys[i]), i, ldkeys, layerdatas);
                    graphdata = SamplePointData.Merged(graphdata, layerdatas.get(ldkeys[i]), (v1, v2) => {
                        //And we do that by copying our first layer
                        let result = v1;
                        //Except for the slot where we copy the first slot from our layer
                        result[i] = v2[0];
                        return result
                    });
                    //Don't forget to tell the merger function
                    slis_paths[ldkeys[i]] = i;
                }
            }
        }

        //5. Build the array to call the function with
        this.status = "Building data array";
        let extractors = this.selectedGraph.dimensions.map(d => {
            let dimension: DimensionType = this.selectedDimension[d];
            if(dimension.value.type == "inherent"){
                switch(dimension.value.name){
                    case "x":
                        return (p: SamplePoint) => Coord.from_UEC(p.position).lon;
                    case "y":
                        return (p: SamplePoint) => Coord.from_UEC(p.position).lat;
                    case "z":
                        return (p: SamplePoint) => p.height;
                    case "t":
                        return (p: SamplePoint) => new Date(p.time);
                }
            }else{
                return (p: SamplePoint) => p.value[slis_paths[dimension.value.name]];
            }
        });

        let dataarray = [];
        for(let i = 0; i < graphdata.length; i++){
            dataarray.push(
                extractors.map(e => e(graphdata.readPoint(i)))
            );
        }

        //6. Create the graph
        this.status = "Rendering graph";
        let svgel: Element = this.$refs["graphSVG"] as Element;
        svgel.innerHTML = ""; //Clear graph
        this.hasSVG = true;
        this.selectedGraph.instantiator(dataarray, svgel, this.selectedGraph.dimensions[0], this.selectedGraph.dimensions[1]);
        this.status = null;
    }

    async loadData(){
        let region = Services.SelectionService.getSelectedRectangle();
        this.loadingState = "Loading data";
        let result = await Services.PointSamplingService.sampleSelection(this.layer, region, (progress) => {
            this.loadingState = `Loading data: ${Math.ceil(progress * 100)}%`;
        });
        this.loadingState = `Loaded ${result.data.length} points`;
    }
    
}
