//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export type ImageDimensions = {
    x: number,
    y: number
}

export class TextRenderingService{
    private cache: Map<string, ImageData>;
    textureSize = {
        x: 480,
        y: 100
    }
    private font: string = "48px sans";
    private ctx: CanvasRenderingContext2D;

    constructor(){
        let el = document.createElement('canvas');
        el.style.display = "none";
        el.style.backgroundColor = "white";
        //Change these to the size needed
        el.width = this.textureSize.x;
        el.height = this.textureSize.y;
        document.body.appendChild(el);
        this.cache = new Map();
        this.ctx = el.getContext("2d");
        this.ctx.fillStyle = "black";
        this.ctx.font = this.font;
        this.ctx.textBaseline = "top";
        this.renderText("DigitalEarthViewer");
    }

    public renderText(text: string): ImageData{
        if(this.cache.has(text)){
            return this.cache.get(text);
        }else{
            let dims = this.ctx.measureText(text);
            let imdims = {
                x: dims.actualBoundingBoxLeft + dims.actualBoundingBoxRight,
                y: dims.actualBoundingBoxAscent + dims.actualBoundingBoxDescent
            };
            this.ctx.clearRect(0,0,this.textureSize.x, this.textureSize.y);
            this.ctx.fillText(text, 0,0);

            let imagedata = this.ctx.getImageData(0,0, imdims.x, imdims.y);
            this.cache.set(text, imagedata);
            return imagedata;
        };
    }
}