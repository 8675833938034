











import Vue from 'vue';
import Component from 'vue-class-component';
import { Coord } from '../modules/tile';
import { DialogBox } from '../services/DialogBoxService';
import { FormattingService } from '../services/FormattingService';
import { InteractionState } from '../services/InteractionService';
import { SelectionChangedEvent, SelectionMode, SelectionState } from '../services/SelectionService';
import { Services } from '../services/Services';
@Component({
    components: {
    },
    props:{}
})
export default class SelectionComponent extends Vue{
    selectionState = "Nothing";
    loadingState = "";
    topLeft = "";
    bottomRight = "";

    selectEverything(){
        Services.SelectionService.selectAll();
    }

    selectNothing(){
        Services.SelectionService.selectNothing();
    }

    mounted(){
        let removeHandler = (e) => {
            if(e.id == "Selection"){
                Services.DialogBoxService.removeEventListener("DialogBoxClose", removeHandler);
                Services.InteractionService.setInteractionState(e.arguments || InteractionState.Move);
            }
        }
        Services.DialogBoxService.addEventListener("DialogBoxClose", removeHandler)

        Services.InteractionService.mouseDownHandlers.set("selections", () => {});

        Services.SelectionService.addEventListener("SelectionChanged", (e: SelectionChangedEvent) => {
            let topLeft = Coord.from_UEC(e.selection.topLeft());
            let bottomRight = Coord.from_UEC(e.selection.bottomRight());
            this.topLeft = FormattingService.coord_to_string(topLeft.lat, topLeft.lon,3);
            this.bottomRight = FormattingService.coord_to_string(bottomRight.lat, bottomRight.lon, 3);
        });

        let selection = Services.SelectionService.getSelectedRectangle();
        let topLeft = Coord.from_UEC(selection.topLeft());
        let bottomRight = Coord.from_UEC(selection.bottomRight());
        this.topLeft = FormattingService.coord_to_string(topLeft.lat, topLeft.lon,3);
        this.bottomRight = FormattingService.coord_to_string(bottomRight.lat, bottomRight.lon, 3);
    }

    
}
