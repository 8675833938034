









































































import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import {Services} from '../services/Services';
import { DialogBox } from '../services/DialogBoxService';
import { InteractionState, InteractionStateChangedEvent } from '../services/InteractionService';

@Component({
    components: {
        SvgIcon
    },
    props:{}
})
export default class menucomponent extends Vue{

    private istate: InteractionState = InteractionState.Move;

    register_mousedown(e) {
        Services.InteractionService.mousedown("menucomponent", e);
    }

    downloadLayerConfig(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        let renderlayertext = "data:application/json;base64," + btoa(renderlayers);
        let a_elem = document.getElementById('downloadlink') as HTMLAnchorElement;
        a_elem.setAttribute("download", "initial_layers.json");
        a_elem.setAttribute("href", renderlayertext);
        a_elem.click();
    }

    uploadLayerConfig(){
        document.getElementById('inputlayerfile').click();
    }

    async handleLayerConfig(e){
        let input: any = document.getElementById('inputlayerfile');
        let file: File = input.files[0];
        if(file){
            let resp = await new Response(file).json();
            try{
                await Services.InitializationService.load_initial_layers(resp);
            }catch(e){
                alert("Layer config could not be loaded: " + e);
            }
        }
    }

    get interactionStateMove () {
        return this.istate == InteractionState.Move;
    }
    
    get interactionStateSelection () {
        return this.istate == InteractionState.Selection;
    }

    get interactionStateOverlay () {
        return this.istate == InteractionState.Overlay;
    }

    mounted() {
        Services.InteractionService.addEventListener("InteractionStateChanged", (e: InteractionStateChangedEvent) => {this.istate = e.state;});
    }

    showSettings(e){
        Services.DialogBoxService.try_insert("Settings", new DialogBox("Settings", 128, 128, "Settings"));
    }

    addLayer(e){
        Services.DialogBoxService.try_insert("New Layer", DialogBox.centered("TypeSelectionDialog", "Select Layer Type"));
    }

    layerSettings(e){
        Services.DialogBoxService.try_insert("Layer Settings", DialogBox.centered("LayerStack", "Layers"))
    }

    modeSelection(e){
        Services.InteractionService.setInteractionState(InteractionState.Selection);
    }

    modeMove(e){
        Services.InteractionService.setInteractionState(InteractionState.Move);
    }

    modePin(e){
        Services.InteractionService.setInteractionState(InteractionState.Overlay);
    }

    graph(){
        let graphid = Services.UIDService.getNextTopicId("graph") + 1;
        Services.DialogBoxService.try_insert("Graph " + graphid, new DialogBox("GraphComponent", 256, 256, "Graph " + graphid));
    }
    
    showPlayback(e){
        Services.DialogBoxService.try_insert("Playback Controls", new DialogBox("PlaybackComponent", 128, 128, "Playback Controls"));
    }

    showDebug(e){
        Services.DialogBoxService.try_insert("Debug", new DialogBox("DebugComponent", 128, 128, "Debug"));
    }

    showRecording(e){
        Services.DialogBoxService.try_insert("Recording", new DialogBox("RecordingPlaybackComponent", 128, 128, "Recording"));
    }

}
