//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { RenderLayer } from "../modules/RenderLayer";
import { UEC, UECArea } from "../modules/tile";
import { Services } from "./Services";
import { SourceLayerInfo } from "./SourceInfoService";

export class SamplePoint{
    public position: UEC;
    public height: number;
    public id: number;
    public time: number;
    public value: [number, number, number];
    constructor(position: UEC, height: number, id: number, time: number, value: [number, number, number]){
        this.position = position;
        this.height = height;
        this.id = id;
        this.time = time;
        this.value =value;
    }
}

export class SamplePointData{
    data: ArrayBuffer;
    view: DataView;
    offset: UEC;
    length: number;
    private static FLOAT_LENGTH = 4;
    private static POSITION_LENGTH: number = 16;
    private static VEC3POINT_LENGTH: number = 28;
    private extent_buffer: UECArea = null;
    constructor(data: ArrayBuffer){
        this.data = data;
        this.view = new DataView(this.data);
        this.readOffsetPosition();
        this.length = (this.data.byteLength - SamplePointData.POSITION_LENGTH) / SamplePointData.VEC3POINT_LENGTH;
    }

    private readOffsetPosition(){
        let x = this.view.getFloat64(0, true);
        let y = this.view.getFloat64(8, true);
        this.offset = new UEC(x,y);
    }

    private anchor(index: number): number{
        return SamplePointData.POSITION_LENGTH + (SamplePointData.VEC3POINT_LENGTH * index);
    }

    readPosition(index: number): UEC{
        let anchor = this.anchor(index);
        let x = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 0), true);
        let y = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 1), true);
        let pos = new UEC(x,y).add(this.offset);
        return pos;
    }

    readTime(index: number): number{
        let anchor = this.anchor(index);
        let t = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 6), true);
        return t;
    }

    readPoint(index: number): SamplePoint{
        let anchor = this.anchor(index);
        let x = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 0), true);
        let y = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 1), true);
        let pos = new UEC(x,y).add(this.offset);
        let h = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 2), true);
        let v1 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 3), true);
        let v2 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 4), true);
        let v3 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 5), true);
        let t = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 6), true);
        return new SamplePoint(pos, h, NaN, t, [v1,v2,v3]);
    }

    private writePoint(index: number, point: SamplePoint){
        let anchor = this.anchor(index);
        let pos = point.position.subtract(this.offset);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 0), pos.x, true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 1), pos.y, true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 2), point.height, true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 3), point.value[0], true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 4), point.value[1], true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 5), point.value[2], true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 6), point.time, true);
    }

    private setValue(index: number, value: [number, number, number]){
        let anchor = this.anchor(index);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 3), value[0], true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 4), value[0], true);
        this.view.setFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 5), value[0], true);
    }

    public getValue(index: number): [number, number, number]{
        let anchor = this.anchor(index);
        let v1 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 3), true);
        let v2 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 4), true);
        let v3 = this.view.getFloat32(anchor + (SamplePointData.FLOAT_LENGTH * 5), true);
        return [v1, v2, v3];
    }

    *[Symbol.iterator](){
        for(let i = 0; i < this.length; i++){
            yield this.readPoint(i);
        }
    }

    extent(): UECArea{
        if(this.extent_buffer == null){
            let positions= [];
            for(let i = 0; i < this.length; i++){
                positions.push(this.readPosition(i));
            }
            this.extent_buffer = UECArea.fromPoints(positions);
        }
        return this.extent_buffer;
    }

    positionsToIdMap(): Object{
        let posmap = {};
        for(let i = 0; i < this.length; i++){
            let position = this.readPosition(i);
            let time = this.readTime(i);
            let obj_index: any = [time, position.x, position.y];
            posmap[obj_index] = i;
        }
        return posmap;
    }

    static Merged(data1: SamplePointData, data2: SamplePointData, merger: (v1: [number, number, number], v2: [number, number, number])=> [number, number, number]): SamplePointData{
        let longer = data1;
        let shorter = data2;
        if(data2.length > data1.length){
            longer = data2;
            shorter = data1;
        }

        //Copy longer array
        let result = new SamplePointData(
            longer.data.slice(0)
        );

        let shorter_objmap = shorter.positionsToIdMap();

        for(let i = 0; i < longer.length; i++){
            let pos = longer.readPosition(i);
            let time = longer.readTime(i);
            let pos_index: any = [time, pos.x, pos.y];
            let id_if_any: number = shorter_objmap[pos_index];
            //This discards any points not found in both arrays
            if(id_if_any){
                if(longer == data1){
                    longer.setValue(i, 
                        merger(
                            longer.getValue(i), 
                            shorter.getValue(id_if_any)
                        )
                    );
                }else{
                    longer.setValue(i, 
                        merger(
                            shorter.getValue(id_if_any),
                            longer.getValue(i)
                        )
                    );
                }
                
            }
        }

        return result;
    }

    public static Downsampled(data: SamplePointData, max_number_points: number): SamplePointData{
        if(data.length < max_number_points){
            return data;
        }
        let points_per_point = Math.floor(data.length / max_number_points);
        let new_data = new SamplePointData(data.data.slice(0, SamplePointData.POSITION_LENGTH + (max_number_points * SamplePointData.VEC3POINT_LENGTH)));
        for(let i = 0; i < max_number_points; i++){
            let point = data.readPoint(i * points_per_point);
            new_data.writePoint(i, point);
        }
        return new_data;
    }

}

export class SamplingResult{
    layer: RenderLayer;
    extent: UECArea;
    timeRange: [number, number];
    components: number;
    data: SamplePointData

    constructor(layer: RenderLayer, extent: UECArea, timeRange: [number, number], components: number, data: SamplePointData){
        this.layer = layer;
        this.extent = extent;
        this.timeRange = timeRange;
        this.components = components;
        this.data = data;
    }
}


export class PointSamplingService{

    public async sampleUECArea(sli: SourceLayerInfo, area: UECArea, zrange: [number, number] = null, timerange: [number, number], progress_callback: (progress: number)=> void, max_number_points: number =  5000): Promise<SamplePointData>{
        if(!zrange){
            zrange = [-99999999999,+99999999999];
        }
        let main_data = await Services.ProgressReportingDownloadService.downloadArrayBuffer(
            this.bbox_request_url_for_params(
                sli, 
                area.topLeft(), 
                area.bottomRight(), 
                zrange[0], 
                zrange[1], 
                Math.round(timerange[0]),
                Math.round(timerange[1])
            ),
            (progress) => progress_callback(progress)
        );

        return SamplePointData.Downsampled(
            new SamplePointData(main_data), max_number_points
        );

    }

    public async sampleSelection(layer: RenderLayer, region: UECArea, progress_callback: (progress: number)=> void, max_number_points: number =  1000): Promise<SamplingResult>{
        //Fill progress_callback if not provided
        if(!progress_callback){
            progress_callback = (_progress) => {};
        }
        //The primary data source for this layer, i.e. the color
        let dataslot = null;
        //The additional data source for this layer, i.e. the delta for a difference layer
        let additional_dataslot = null;
        //How many components does the output have (between 1 and 3 both inclusive)
        let components = 0;
        //Should two layers be merged into one? I.e. layer1.0 -> result.0, layer2.0 -> result.1
        let merge_into_different_components = false;
        switch(layer.typeHint){
            case "ColormapScalar":
                dataslot = "data0";
                components = 1;
            break;
            case "ColormapDifference":
                dataslot = "data0";
                additional_dataslot = "data1";
                components = 1;
                break;
            case "Image":
                dataslot = "data0";
                components = 1;
                break;
            case "Points":
                dataslot = "points";
                components = 1;
                break;
            case "Vec2Points":
                dataslot = "points";
                components = 2;
                break;
            case "Vector2":
                dataslot = "datau";
                additional_dataslot = "datav";
                components = 2;
                merge_into_different_components = true;
                break;
            case "Lines":
            default: 
                throw "No sampling method implemented for layertype " + layer.typeHint;
                break;
        }

        
        let timerange = Services.TimeService.getCurrentTimeRange();
        
        
        //Request the data
        let partscount = additional_dataslot == null ? 1 : 2;
        let main_data = await Services.ProgressReportingDownloadService.downloadArrayBuffer(
            this.bbox_request_url_for_selection_slot_and_time(
                layer.source.slots[dataslot].source,
                region,
                timerange
            ),
            (progress) => progress_callback(progress / partscount)
        );

        let main_sample_data = new SamplePointData(main_data);
                
        let additional_data = null;
        let additional_sample_data = null;

        if(additional_dataslot){
            additional_data = await Services.ProgressReportingDownloadService.downloadArrayBuffer(
                this.bbox_request_url_for_selection_slot_and_time(
                    layer.source.slots[additional_dataslot].source,
                    region,
                    timerange
                ),
                (progress) => progress_callback((progress / partscount) + (1 / partscount))
            );
        }

        if(additional_data == null){
            return new SamplingResult(layer, region, timerange, components, 
                SamplePointData.Downsampled(main_sample_data, max_number_points)
            );
        }else{
            let merger;
            //We need to merge here
            if(merge_into_different_components){
                merger = (v1: [number, number, number], v2: [number, number, number]) => [v1[0], v2[0], v1[2]];
            }else{
                //This is the delta one
                merger = (v1: [number, number, number], v2: [number, number, number]) => [v1[0] - v2[0],v1[1], v1[2]]
            }
            additional_sample_data = new SamplePointData(additional_data);
            let merged_data = SamplePointData.Merged(main_sample_data, additional_sample_data, merger);
            return new SamplingResult(layer, region, timerange, components, 
                SamplePointData.Downsampled(merged_data, max_number_points)
            );
        }
        

    }

    public bbox_request_url_for_selection_slot_and_time(layer: SourceLayerInfo, region: UECArea, time: [number, number]): string{
        let zrange = layer.layer.zrange || [-999999999, +999999999];
        return this.bbox_request_url_for_params(
            layer,
            region.topLeft(),
            region.bottomRight(),
            zrange[0],
            zrange[1],
            time[0],
            time[1]
        );
    }

    public bbox_request_url_for_params(layer: SourceLayerInfo, pos_min: UEC, pos_max: UEC, height_min: number, height_max: number, t_min: number, t_max: number): string{
        return `sample_points/${encodeURIComponent(layer.instance_name)}/${encodeURIComponent(layer.layer_name)}/${pos_min.x}/${pos_min.y}/${height_min}/${pos_max.x}/${pos_max.y}/${height_max}/${t_min}/${t_max}`;
    }

}