//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { ArrayData } from "../../services/TileCacheService";
import { UECArea } from "../tile";

export class LineRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.lines;
        this.name = "LineRenderSource";
        this.parameters = {
            "displacement_scale": Services.SettingsService.getSetting("Exaggeration"),
            "displacement_offset": new Parameter("Vertical Offset", 0, "number", true),
            "line_width": new Parameter("Line Width", 1, "number", true),
            "snap_to_timestep": new Parameter("Snap to Timestep", true, "boolean", true),
        };
        this.parameters["displacement_offset"].shader_name = "displacement_offset";

        this.slots = {
            "lines": new RenderSourceSlot(
                "Line Layer",
                "lines",
                null,
                "lines",
                null
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["lines"]?.source?.layer?.zrange) {
            let min_scaled = this.applyScaling(this.slots["lines"].source.layer.zrange[0]);
            let max_scaled = this.applyScaling(this.slots["lines"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.slots["lines"]?.source?.layer?.zrange) {
            let min_scaled = this.applyOffset(this.slots["lines"].source.layer.zrange[0]);
            let max_scaled = this.applyOffset(this.slots["lines"].source.layer.zrange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getExtent(): UECArea {
        if(this.slots["lines"]?.source?.layer){
            return this.slots["lines"].source.layer.extent;
        }
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        if(!this.slots["lines"]?.source)return;
        
        let tr = Services.TimeService.getCurrentTimeRange();
        let tm = Services.TimeService.getMeanTime();
        if (this.parameters["snap_to_timestep"].value == true){
            tr = [tr[0], tr[0]];
        }
        let buffs: ArrayData[] = Services.TileCacheService.get_array_data(this.slots["lines"].source, tr[0], tr[1]).filter(e => e && context.gl.isBuffer(e.buffer));
        if (this.parameters["snap_to_timestep"].value == true){
            if(buffs.length > 0){
                buffs = [buffs[0]];
            }
        }
        if(buffs.length <= 0) return;

        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);

        let [t_start, t_end] = Services.TimeService.getCurrentTimeRange();
        /*if (this.parameters["snap_to_timestep"].value == true && this.slots['lines'].source.layer.timesteps != null){
            let [i_start, i_end] = this.slots['lines'].source.resolve_time(t_start, t_end);
            let temp = this.slots['lines'].source.layer.timesteps[t_start];
            t_start = temp;
            t_end = temp;
        }*/
        if(this.slots["lines"]?.source?.layer.timerange){
            context.gl.uniform1f(this.shader.uniforms["time_min"], t_start);
            context.gl.uniform1f(this.shader.uniforms["time_max"], t_end);
        } else {
            context.gl.uniform1f(this.shader.uniforms["time_min"], 0);
            context.gl.uniform1f(this.shader.uniforms["time_max"], 0);
        }


        let line_geo = Services.GLService.Geometries.lines;
        context.gl.enableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, line_geo.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["geometry_position"], 3, context.gl.FLOAT, false, 0, 0);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["geometry_position"], 0);

        let screensize = Services.PositionService.getScreenDimensions();
        context.gl.uniform2f(this.shader.uniforms["point_size"], this.parameters["line_width"].value / screensize.width, this.parameters["line_width"].value / screensize.height);

        buffs.forEach(buff => {
            switch (Services.PositionService.projection_mode) {
                case "EQUIRECT": {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(1, 0, 0, buff.referencePoint.x, 0, 1, 0, buff.referencePoint.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    break;
                }
                case "SPHERE": {
                    break;
                }
                case "POLAR": {
                    break;
                }
            }
            context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 10 * 4, 0);
        
            context.gl.enableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["value"], 1, context.gl.FLOAT, false, 10 * 4, 3 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["time"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["time"], 1, context.gl.FLOAT, false, 10 * 4, 4 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["position2"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position2"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["position2"], 3, context.gl.FLOAT, false, 10 * 4, 5 * 4);
        
            context.gl.enableVertexAttribArray(this.shader.attributes["value2"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value2"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["value2"], 1, context.gl.FLOAT, false, 10 * 4, 8 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["time2"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time2"], 1);
            context.gl.vertexAttribPointer(this.shader.attributes["time2"], 1, context.gl.FLOAT, false, 10 * 4, 9 * 4);

            context.gl.uniform2f(this.shader.uniforms["reference_position"], buff.referencePoint.x, buff.referencePoint.y);

            context.ANGLE_instanced_arrays.drawArraysInstancedANGLE(context.gl.TRIANGLE_STRIP, 0, line_geo.length, buff.elements / 2);
         
        });

        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["value"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["time"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);
    }
}
