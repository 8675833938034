//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { UECArea } from "../tile";

export class PointRenderSourceHack extends RenderSource {
    private buffer: WebGLBuffer;
    private buffer_stride: number;
    private sourceData: Float32Array;
    private started = false;
    private loaded = false;

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.scalarPointSource;
        this.name = "PointRenderSource";
        this.parameters = {
            "displacement_scale": new Parameter("Exaggeration", 1, "number", true),
            "displacement_offset": new Parameter("Vertical Offset", 0, "number", true),
            "point_size": new Parameter("Point Size", 8, "number", true),
            "time_range": new Parameter("Time Range", 1 / 24 / 60,"number",true).setStep(1 / 24 / 60).setUnit("d"),
            "value_sizing_enabled": new Parameter("Size by Value", false, "boolean", true),
            "value_sizing_zero": new Parameter("Minimum Size at", 0, "number", true),
            "value_sizing_one": new Parameter("Maximum Size at", 1, "number", true),
            "value_sizing_power": new Parameter("Proportionality", 1, "number", true).setStep(0.1),
            "stride": new Parameter("Stride", 20, "number", true).setStep(1)
        };
        this.parameters["displacement_scale"].shader_name = "displacement_scale";
        this.parameters["displacement_offset"].shader_name = "displacement_offset";
        this.parameters["point_size"].shader_name = "point_size";
        this.parameters["value_sizing_enabled"].shader_name = "value_sizing_enabled";
        this.parameters["value_sizing_zero"].shader_name = "value_sizing_zero";
        this.parameters["value_sizing_one"].shader_name = "value_sizing_one";
        this.parameters["value_sizing_power"].shader_name = "value_sizing_power";
        this.parameters["stride"].shader_name = "_*_*_";
        this.slots = {
            "points": new RenderSourceSlot(
                "Point Layer",
                "points",
                null,
                "points",
                null
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["points"] && this.slots["points"].source){
            if(this.slots["points"].source.layer.zrange) {
                let min_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[0]);
                let max_scaled = this.applyScaling(this.slots["points"].source.layer.zrange[1]);
                return [
                    Math.min(min_scaled, max_scaled),
                    Math.max(min_scaled, max_scaled)
                ];
            }
            return[0.9, 1.1];
        }
        return[1, 1];
    }

    getExtent(): UECArea {
        if(this.slots["points"] && this.slots["points"].source){
            return this.slots["points"].source.layer.extent;
        }
    }

    applyScaling(val: number): number {
        return 1 + (val 
                * this.parameters["displacement_scale"].value
                + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        if(!(this.slots["points"] && this.slots["points"].source))return;
        if(!this.started){
            this.buffer = context.gl.createBuffer();
            this.buffer_stride = 0;
            let p = Services.TileCacheService.array_path(this.slots["points"].source, 0);
            Services.TileCacheService.load_arraybuffer(p, (ab) => {
                this.sourceData = new Float32Array(ab);
                this.loaded = true;
            }, console.error);
            this.started = true;
            return;
        }
        if(!this.loaded)return;

        
        let tr = Services.TimeService.getCurrentTimeRange();
        let tm = Services.TimeService.getMeanTime();
        let t_radius = ((this.parameters["time_range"].value) * (60 * 60 * 24 * 1000));
        let t_start = Math.min(tr[0], tm - t_radius);
        let t_end = Math.max(tr[1], tm + t_radius);
        //let buffs: ArrayData[] = Services.TileCacheService.get_array_data(this.slots["points"].source, t_start, t_end).filter(e => e && context.gl.isBuffer(e.buffer));
        //if(buffs.length <= 0) return;

        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);

        /*
            Do the thing here.
        */

        try{
            let new_stride = parseInt(this.parameters["stride"].value);
            let target_array = new Float32Array(new_stride * 5);
            for(var i = 0; i < new_stride; i++){
                target_array[i * 5] = this.sourceData[i * 5];
                target_array[i * 5 + 1] = this.sourceData[i* 5 + 1];
                target_array[i * 5 + 2] = this.sourceData[i * 5 + 2];
                target_array[i * 5 + 3] = 0;
                target_array[i * 5 + 4] = tm;
            }
            let averaged_steps = new Array(new_stride).fill(0);
            for(var i = 0; i < this.sourceData.length; i += new_stride){
                for(var j = 0; j < new_stride; j++){
                    let t = this.sourceData[i + j * 5 + 4];
                    if(t > t_start && t < t_end){
                        target_array[j * 5 + 3] += this.sourceData[i + j * 5 + 3]
                        averaged_steps[j]++;
                    }
                }
            }
            for(var i = 0; i < new_stride; i++){
                target_array[i * 5 + 3] /= averaged_steps[i];
            }
            if(new_stride == this.buffer_stride){
                context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.buffer);
                context.gl.bufferSubData(context.gl.ARRAY_BUFFER, 0, target_array);
            } else {
                context.gl.deleteBuffer(this.buffer);
                this.buffer = context.gl.createBuffer();
                context.gl.bindBuffer(context.gl.ARRAY_BUFFER, this.buffer);
                context.gl.bufferData(context.gl.ARRAY_BUFFER, target_array, context.gl.DYNAMIC_DRAW);
                this.buffer_stride = new_stride;
            }
        }catch(e){
            console.error(e);
            return;
        }
    
        let buffs = [{buffer: this.buffer, elements: this.buffer_stride}];
        if(buffs.length <= 0) return;

        context.gl.uniform1f(this.shader.uniforms["time_min"], t_start);
        context.gl.uniform1f(this.shader.uniforms["time_max"], t_end);

        buffs.forEach(buff => {

            context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 5 * 4, 0);
        
            context.gl.enableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["value"], 1, context.gl.FLOAT, false, 5 * 4, 3 * 4);

            context.gl.enableVertexAttribArray(this.shader.attributes["time"]);
            context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
            context.gl.vertexAttribPointer(this.shader.attributes["time"], 1, context.gl.FLOAT, false, 5 * 4, 4 * 4);

            context.gl.drawArrays(context.gl.POINTS, 0, buff.elements);

            context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
            context.gl.disableVertexAttribArray(this.shader.attributes["value"]);
            context.gl.disableVertexAttribArray(this.shader.attributes["time"]);
         
        });
    }
}
