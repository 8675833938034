//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import Vue from 'vue'
import App from './App.vue'

(async function(){

    Vue.config.productionTip = false
    Vue.config.errorHandler = (e, vm, info) => {
        console.trace(e, vm, info);
    },
    Vue.config.warnHandler = (e, vm, info) => {
        console.trace(e, vm, info);
    }
    new Vue({
        el: '#app',
        template: '<App/>',
        components: { App },
        render: h => h(App),
    });
    
    })();
