//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Parameter, SerializedParameter } from "../modules/Parameter";

export class SettingsChangedEvent extends Event{
    public parameter: Parameter;
    public remote: boolean = false;
    constructor(parameter: Parameter, remote: boolean = false){
        super("SettingsChanged");
        this.parameter = parameter;
        this.remote = remote;
    }
}

export class SettingsService extends EventTarget{
    parameters: {[name: string]: Parameter} = {};
    skip_storing: boolean = false;

    constructor(){
        super();
        let serializedArray = localStorage.getItem("digitalearthviewer_settings");
        if(serializedArray){
            try{
                let deserializedArray: SerializedParameter[] = JSON.parse(serializedArray);
                for(let param of deserializedArray){
                    let parameter = Parameter.deserialize(param);
                    if(this.parameters[parameter.name]){
                        Object.assign(this.parameters[parameter.name], parameter);
                    }else{
                        this.parameters[parameter.name] = parameter;
                    }
                }
            }catch(e){
                console.warn("Settings unparseable!", e);
            }
        }

        window.addEventListener("beforeunload", () => {
            if(this.skip_storing){
                return;
            }
            let paramlist = [];
            for(let param in this.parameters){
                paramlist.push(this.parameters[param]);
            }
            //Serialize settings on exit
            localStorage.setItem("digitalearthviewer_settings", JSON.stringify(paramlist.map(p => p.serialize())));
        });
    }

    public initializeSetting(default_values: Parameter){
        if(!this.parameters.hasOwnProperty(default_values.name)){
            this.parameters[default_values.name] = default_values;
        }
    }

    public getSetting(setting: string): Parameter{
        return this.parameters[setting];
    }

    public getValueOrDefault(setting: string, default_value: any): any{
        let setting_value = this.parameters[setting]?.value;
        if(setting_value == null){
            return default_value;
        }else{
            return setting_value;
        }
    }

    public storeSetting(setting: Parameter){
        this.parameters[setting.name] = setting;
    }
}