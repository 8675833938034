//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { SourceLayerInfo } from './SourceInfoService';
import { Services } from './Services';
import { LRUMap } from 'lru_map';
import { Tile, UEC } from '../modules/tile';
import { Barrier } from "../modules/barrier";

const PRELOAD_STEPS = 5;
//Exchange class for TileData that is already on the GPU. Be careful with this one and call dispose() once you don't need it anymore
export class TileData {
    public path: string;
    public texture: WebGLTexture;
    public coord_offset: [number, number];
    public coord_scale: [number, number];
    public value_range: [number, number];

    dispose(gl: WebGLRenderingContext){
        if(this.texture && gl.isTexture(this.texture)){
            gl.deleteTexture(this.texture);
        }else{
            console.error("texture could not be deleted:", gl, this.texture);
        }
    }

}
export class ArrayData {
    public path: string;
    public buffer: WebGLBuffer;
    public elements: number;
    public referencePoint: UEC;

    dispose(gl: WebGLRenderingContext){
        if(this.buffer && gl.isBuffer(this.buffer))
            gl.deleteBuffer(this.buffer);
    }
}


class TileRequest{
    source: SourceLayerInfo;
    expirationFrame: number;
    tilePath: string;
    height: number;
    heightIndex: number;
    time: number;
    timeIndex: number;

    constructor(source: SourceLayerInfo, expirationFrame: number, tilePath: string, heightIndex: number, timeIndex: number) {
        this.source = source;
        this.expirationFrame = expirationFrame;
        this.tilePath = tilePath;
        this.heightIndex = heightIndex;
        if(source.layer.zsteps)
            this.height = source.layer.zsteps[heightIndex];
        this.timeIndex = timeIndex;
        if(source.layer.timesteps)
            this.time = source.layer.timesteps[timeIndex];
    }
}

class ArrayRequest{
    source: SourceLayerInfo;
    expirationFrame: number;
    time: number;
    timeIndex: number;

    constructor(source: SourceLayerInfo, expirationFrame: number, timeIndex: number) {
        this.source = source;
        this.expirationFrame = expirationFrame;
        this.timeIndex = timeIndex;
        if(source.layer.timesteps)
            this.time = source.layer.timesteps[timeIndex];
    }
}

class FailedRequest{
    code: number;
    private time: number;
    attempts: number;

    should_retry(): boolean {
        return Date.now() > (this.time + 1000 * Math.pow(2, Math.min(5, this.attempts)));
    }

    new_attempt() {
        this.attempts += 1;
        this.time = Date.now();
    }

    constructor(attempt: number, code: number = 0) {
        this.code = code;
        this.time = Date.now();
        this.attempts = attempt;
    }
}


export class TileCacheService extends EventTarget{
    gl: WebGLRenderingContext;

    dataDB: IDBDatabase;
    dataDB_Barrier: Barrier;

    frameCounter: number = 0;

    timing_mode: "previous";

    detailedData: LRUMap<string, TileData | ArrayData>;
    
    array_queries: Map<string, ArrayRequest>;
    arrays_loading: Set<string>;

    tile_queries: Map<string, TileRequest>;
    tiles_loading: Set<string>;

    failed_paths: Map<string, FailedRequest>;

    empty_paths: Set<string>;

    maximum_loading = 8;
    drop_after = 0.5 * 60;

    ratio = [0.0, 0.0];
    available_queried_elements: Set<string>;
    get loading(): boolean{
        return this.ratio[0] < this.ratio[1] && this.ratio[1] != 0;
    }

    constructor(gl: WebGLRenderingContext){
        super();
        this.gl = gl;
        this.dataDB_Barrier = new Barrier();
        var DBOpenRequest = indexedDB.open("data");
        //Report errors
        DBOpenRequest.onerror = (ev) => {console.error(ev);}
        //On success resolve the Barrier
        DBOpenRequest.onsuccess = (ev) => {
            this.dataDB = DBOpenRequest.result;
            this.dataDB_Barrier.resolve();
        };
        //When a new database needs to be created, also create a new object store
        DBOpenRequest.onupgradeneeded = (ev) => {
            DBOpenRequest.result.createObjectStore("data");
        };

        this.detailedData = new LRUMap(1000);

        this.array_queries = new Map();
        this.arrays_loading = new Set();
        this.tile_queries = new Map();
        this.tiles_loading = new Set();

        this.empty_paths = new Set();

        this.available_queried_elements = new Set();

        this.failed_paths = new Map();
        let get_gl = () => {return this.gl};
        this.detailedData.shift = function(){
            let [key, texture] = LRUMap.prototype.shift.call(this);
            texture.dispose(get_gl());
            return [key, null];
        }

    }


    get_perf_stats(){
        return {
            "lrumap": {
                "size": this.detailedData.size,
                "limit": this.detailedData.limit
            },
            "loading": {
                "arrays": this.arrays_loading.size,
                "tiles": this.tiles_loading.size,
                "queued arrays": this.array_queries.size,
                "queued tiles": this.tile_queries.size
            },
        };
    }

    //todo: move this further upstream.
    tile_path(source: SourceLayerInfo, tile: Tile, heightindex: number, timeindex: number): string{
        return "tiles/" + encodeURIComponent(source.instance_name) + "/" + encodeURIComponent(source.layer_name) + "/"  + tile.path + "/" + timeindex + "/" + heightindex;
    }

    array_path(source: SourceLayerInfo, timeindex: number): string {
        return "array/" +  encodeURIComponent(source.instance_name) + "/" + encodeURIComponent(source.layer_name) + "/" + timeindex;
    }

    store_arraybuffer_db(path: string, data: ArrayBuffer) {
        let t = this.dataDB.transaction(["data"], "readwrite");
        let db_request = t.objectStore("data").put(data,path);
        db_request.onsuccess = (ev) => {
        };
        db_request.onerror = (ev) => {
            console.error(ev);
        }
        //@ts-ignore
        t.commit(); //Yes it does, see https://developer.mozilla.org/en-US/docs/Web/API/IDBTransaction
    }

    load_arraybuffer(path: string, cb: (a: ArrayBuffer)=>void, cbe: (e: any)=>void){
        let http_get = () => {
            let r = new XMLHttpRequest();
            r.open("GET", path);
            r.responseType = "arraybuffer";
            r.onprogress = (e) => {

            };
            r.onload = (e) => {
                if(r.status >= 200 && r.status < 300){
                    Services.AdaptivePerformanceService.RequestRerender();
                    cb(r.response);
                    if(this.dataDB_Barrier.isResolved())
                        this.store_arraybuffer_db(path, r.response);
                }else{
                    cbe(r.statusText);
                }
            };
            r.onabort = (e) => {
                cbe(e);
            };
            r.onerror = (e) => {
                cbe(e);
            };
            r.send();
        }
        if (this.dataDB_Barrier.isResolved()){
            let t = this.dataDB.transaction(["data"], "readonly");
            let db_request = t.objectStore("data").get(path);
            db_request.onsuccess = (ev) => {
                if(db_request.result){
                    Services.AdaptivePerformanceService.RequestRerender();
                    cb(db_request.result);
                } else {
                    http_get();
                }
            };
        }else{
            http_get();
        }
    }

    load_tile_data_for(source: SourceLayerInfo, tile: Tile, timeindex: number, heightindex: number){
        let type = source.layer.layer_type;
        let p = this.tile_path(source, tile, heightindex, timeindex);
        this.tile_queries.set(p, new TileRequest(source, this.frameCounter, tile.path, heightindex, timeindex));
    }

    load_array_data_for(source: SourceLayerInfo, timeindex: number) {
        let p = this.array_path(source, timeindex);
        this.array_queries.set(p, new ArrayRequest(source, this.frameCounter, timeindex));
    }

    //TODO: add z level selection
    get_tile_data(source: SourceLayerInfo, tile: Tile, time: number, height: number): TileData {
        if(!source)return;
        let hindex = source.resolve_height(height);
        let tindex = source.resolve_time(time, time)[0];
        return this.get_tile_data_direct(source, tile, tindex, hindex);
    }

    get_tile_data_direct(source: SourceLayerInfo, tile: Tile, tindex: number, hindex: number): TileData {
        if(!source)return;
        if(source.layer.timesteps)for(var t = Math.max(tindex - PRELOAD_STEPS, 0); t < Math.min(tindex + PRELOAD_STEPS + 1, source.layer.timesteps.length - 1); t++){
            if(t != tindex)this.get_tile_data_internal(source, tile.copy(), t, hindex);
        }
        return this.get_tile_data_internal(source, tile.copy(), tindex, hindex);
    }

    get_tile_data_range(source: SourceLayerInfo, tile: Tile, time: number, height: number): [number, number] {
        if(!source)return;
        let copied_tile = tile.copy();
        let hindex = source.resolve_height(height);
        let tindex = source.resolve_time(time, time)[0];
        if(copied_tile.path.length > source.layer.max_zoom_level){            
            copied_tile = Tile.from_tilepath(copied_tile.path.substr(0, Math.max(1, source.layer.max_zoom_level))); //Shrink down tilepath to max zoomlevel
        }
        let range;
        while(copied_tile.path.length >= 1 && !range){
            let tile = this.detailedData.get(this.tile_path(source,copied_tile,hindex,tindex)) as TileData;
            range = tile?.value_range;
            if(copied_tile.path.length == 1) break;
            if(!range) copied_tile = copied_tile.parent()
        }
        return range;
    }

    get_tile_data_internal(source: SourceLayerInfo, tile: Tile, timeindex: number, heightindex: number): TileData {
        let req_tile = tile.copy();
        if(tile.path.length > source.layer.max_zoom_level){            
            tile = Tile.from_tilepath(tile.path.substr(0, Math.max(1, source.layer.max_zoom_level))); //Shrink down tilepath to max zoomlevel
        }
        let first = true;
        while(tile.path.length >= 1){
            let path = this.tile_path(source,tile,heightindex,timeindex);
            if(this.empty_paths.has(path)){
                this.available_queried_elements.add(path);
                return;
            }
            if(this.detailedData.has(path)){
                let t = this.detailedData.get(path) as TileData;
                let offset = req_tile.posInTile(tile);
                t.coord_offset = [offset[0], offset[1]];
                t.coord_scale = [offset[2], offset[3]];
                if(first)this.available_queried_elements.add(path);
                return t;
            }else{
                if(first || tile.path.length == 1){
                    this.load_tile_data_for(source, tile.copy(), timeindex, heightindex);
                    first = false;
                } 
                if(tile.path.length == 1){
                    break;
                }
                tile = tile.parent();
            }
        }
    }

    //TODO: add range finding??
    get_array_data(source: SourceLayerInfo, timeStart: number, timeEnd: number): ArrayData[] {
        let times = source.resolve_time(timeStart, timeEnd);
        let result = []
        for(var t = times[0]; t <= times[1]; t++){
            result.push(this.get_array_data_internal(source, t));
        }
        return result;
    }

    get_array_data_internal(source: SourceLayerInfo, timeindex: number): ArrayData {
        if(!source)return;
        let path = this.array_path(source, timeindex);
        if(this.empty_paths.has(path)){
            this.available_queried_elements.add(path);
            return;
        }
        if(this.detailedData.has(path)){
            let a  = this.detailedData.get(path) as ArrayData;
            this.available_queried_elements.add(path);
            return a;
        }else{
            this.load_array_data_for(source, timeindex);
        }
    }

    //reset the list of queried tiles before drawing a frame, to 
    reset_queried() {
        this.available_queried_elements.clear();
    }

    is_complete() {
        //this does not cover failures!
        return this.tile_queries.size == 0 && this.array_queries.size == 0;
    }

    private retrying_independently = false;
    load_queried() {
        if(this.failed_paths.size > 0 && !this.retrying_independently){
            this.retrying_independently = true;
            setTimeout(()=>{
                Services.AdaptivePerformanceService.RequestRerender();
                this.retrying_independently = false;}, 1000);
            }
        if(this.tiles_loading.size + this.arrays_loading.size >= this.maximum_loading)return;
        if(this.available_queried_elements.size + this.tile_queries.size + this.array_queries.size == 0){
            this.ratio = [0, 0];
        } else {
            this.ratio = [this.available_queried_elements.size, (this.available_queried_elements.size + this.tile_queries.size + this.array_queries.size)];
        }
        let now = this.frameCounter;
        let display_time = Services.TimeService.getMeanTime();
        let tqs_sorted_local: [string, TileRequest][] = [];
        this.tile_queries.forEach((v, k) => {
            if(now - v.expirationFrame > this.drop_after || (this.failed_paths.has(k) && !this.failed_paths.get(k).should_retry()) || this.tiles_loading.has(k)){
                this.tile_queries.delete(k);
            } else {
                tqs_sorted_local.push([k, v]);
            }
        });
        tqs_sorted_local = tqs_sorted_local.sort((a, b) => {
            let a_: TileRequest = a[1];
            let b_: TileRequest = b[1];
            let r = b_.tilePath.length - a_.tilePath.length;
            if (r == 0)
                return Math.abs(b_.time - display_time) - Math.abs(a_.time - display_time)
            else
                return r;
        }); //sort by tile path length, shortest gets queried first (taken from back of list) TODO make easily adjustable
        let aqs_sorted_local: [string, ArrayRequest][] = []; 
        this.array_queries.forEach((v, k) => {
            if(now - v.expirationFrame > this.drop_after || (this.failed_paths.has(k) && !this.failed_paths.get(k).should_retry()) || this.arrays_loading.has(k)){
                this.array_queries.delete(k);
            } else {
                aqs_sorted_local.push([k, v]);
            }
        });
        aqs_sorted_local = aqs_sorted_local.sort((a, b) => 
            Math.abs(b[1].time - display_time) - Math.abs(a[1].time - display_time)
        ); //sort by request time, earliest gets queried first.
        while (this.tiles_loading.size + this.arrays_loading.size < this.maximum_loading && (tqs_sorted_local.length > 0 || aqs_sorted_local.length > 0)) {
                let free_space = this.maximum_loading - this.arrays_loading.size - this.tiles_loading.size;
                //start loading an array if there is one to be loaded and there are less than 4 arrays loading or there is more free loading space left than tiles may be requested
                if(aqs_sorted_local.length > 0  && (this.arrays_loading.size < 4 || tqs_sorted_local.length < Math.min(4, free_space)))
                {
                    let q = aqs_sorted_local.pop();
                    this.arrays_loading.add(q[0]);
                    this.array_queries.delete(q[0]);
                    this.load_arraybuffer(q[0], (a) => {
                        let data = new ArrayData();
                        if(a.byteLength > 0){
                            let gl = this.gl;
                            let b = gl.createBuffer();
                            let a_view = new DataView(a, 0);
                            data.referencePoint = new UEC(a_view.getFloat64(0, true), a_view.getFloat64(8, true))
                            gl.bindBuffer(gl.ARRAY_BUFFER, b);
                            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(a, 16), gl.STATIC_DRAW);
                            gl.bindBuffer(gl.ARRAY_BUFFER, null);
                            if(q[1].source.layer.layer_type == "Vector2DPoints")
                                data.elements = (a.byteLength - 16) / 24;
                            else
                                data.elements = (a.byteLength - 16) / 20;
                            data.buffer = b;
                            this.detailedData.set(q[0], data);
                            Services.AdaptivePerformanceService.RequestRerender();
                        } else {
                            this.empty_paths.add(q[0]);
                        }
                        this.arrays_loading.delete(q[0]);
                        this.failed_paths.delete(q[0]);
                        this.load_queried();
                        }, 
                    (e) => {
                        this.arrays_loading.delete(q[0])
                        if (this.failed_paths.has(q[0]))
                            this.failed_paths.get(q[0]).new_attempt();
                        else
                            this.failed_paths.set(q[0], new FailedRequest(0));
                        Services.AdaptivePerformanceService.RequestRerender();
                        this.load_queried();
                    });
                }
                //start loading a tile if there is one to be loaded and there are less than 4 tiles loading or there is more free loading space left than arrays may be requested
                else if(tqs_sorted_local.length > 0 && (this.tiles_loading.size < 4 || aqs_sorted_local.length < Math.min(4, free_space)))
                {
                    let q = tqs_sorted_local.pop();
                    this.tiles_loading.add(q[0]);
                    this.tile_queries.delete(q[0]);
                    this.load_arraybuffer(q[0], (a) => {
                        var td = new TileData();
                        if(a.byteLength > 0){
                            var gl = this.gl;
                            var texture = gl.createTexture();
                            gl.bindTexture(gl.TEXTURE_2D, texture);
                            let min = Infinity;
                            let max = -Infinity;
                            if(q[1].source.layer.layer_type == "ColorTiles"){
                                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, Services.InitializationService.getTileWidth(), Services.InitializationService.getTileHeight(), 0, gl.RGBA, gl.UNSIGNED_BYTE, new Uint8Array(a));
                            }else{
                                let floats = new Float32Array(a);
                                gl.texImage2D(gl.TEXTURE_2D, 0, gl.LUMINANCE, Services.InitializationService.getTileWidth(), Services.InitializationService.getTileHeight(), 0, gl.LUMINANCE, gl.FLOAT, floats);
                                const STEP = 32;
                                for(var i = 0; i < floats.length; i++){
                                    if(floats[i] < min){
                                        min = floats[i];
                                    }
                                    if(floats[i] > max){
                                        max = floats[i];
                                    }
                                }
                            }
                            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                            gl.bindTexture(gl.TEXTURE_2D, null);
                            td.path = q[0];
                            td.coord_offset = [0,0];
                            td.coord_scale = [1,1];
                            td.texture = texture;
                            if(min != Infinity && max != -Infinity){
                                td.value_range = [min, max];
                            }
                            this.detailedData.set(q[0], td);
                            Services.AdaptivePerformanceService.RequestRerender();
                        } else {
                            this.empty_paths.add(q[0]);
                        }
                        this.tiles_loading.delete(q[0]);
                        this.failed_paths.delete(q[0]);
                        this.load_queried();
                    },
                    (e) => {
                        this.tiles_loading.delete(q[0]);
                        if (this.failed_paths.has(q[0]))
                            this.failed_paths.get(q[0]).new_attempt();
                        else
                            this.failed_paths.set(q[0], new FailedRequest(0)); 
                        Services.AdaptivePerformanceService.RequestRerender();
                        this.load_queried();
                    });
            }
        }
        //reset frame counter when no queries are pending, to keep the counter from getting too big.
        if(this.array_queries.size + this.tile_queries.size <= 0)this.frameCounter = 0;
    }
}