













































































































































import { KeyDownEvent } from '../services/InteractionService';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Vec3 } from '../modules/vecmat';
import { FormattingService } from '../services/FormattingService';
import { Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
import NumberInput from './guikit/numberinput.vue';
import { Parameter } from '../modules/Parameter';
@Component({
    components: {
        SvgIcon,
        NumberInput
    }
})
export default class Settings extends Vue{
    private shown = false;
    stupid = true;
    private LOD = Services.InitializationService.LOD;
    private AlwaysRerender = Services.SettingsService.getSetting("AlwaysRerender").value;
    private WaitForLoad = Services.SettingsService.getSetting("WaitForLoad").value;
    private SceneName = "scene";
    private SceneError = "";
    private SceneLink = "";
    private CopySuccessful = false;
    private Synchronized = Services.SettingsService.getSetting("Synchronized").value;
    private HideUI = !Services.SettingsService.getSetting("UIVisible").value;
    private get DomeMode(): string{
        return Services.SettingsService.getValueOrDefault("DomeMode", "normal");
    }
    private set DomeMode(value: string){
        Services.SettingsService.getSetting("DomeMode").value = value;
        console.log("Setting Dome Mode to: ", value);
        this.$forceUpdate();
    }

    private get Gamepads(){
        let gamepads =  new Array(...navigator.getGamepads()).filter(e => !!e);
        console.log("Found ", gamepads.length, " gamepads: ", gamepads);
        return gamepads;
    }

    private get SelectedGamepad(): Gamepad{
        return Services.InteractionService.getGamepad();
    }

    private set SelectedGamepad(gamepad: Gamepad){
        Services.InteractionService.gamepadid = gamepad.index;
        console.log("Set gamepad to ", gamepad.id)
    }

    private get DomeTilt(): Parameter{
        return Services.SettingsService.getSetting("DomeTilt");
    }

    private get ClearColor():string{
        let clearcolor: Vec3 = Services.SettingsService.getSetting("ClearColor").value;
        return FormattingService.color_vec3_to_hex(clearcolor);
    }

    private set ClearColor(hex: string){
        let color = FormattingService.color_hex_to_vec3(hex);
        Services.SettingsService.getSetting("ClearColor").value = color;
    }

    private get Versions(){
        return Services.InitializationService.getVersionInfo();
    }

    private FontSize =  Services.SettingsService.getValueOrDefault("FontSize", 11);

    private fontSizeChanged(){
        let fontsize = Services.SettingsService.getSetting("FontSize");
        let size = this.FontSize | 0;
        fontsize.value = Math.min(Math.max(fontsize.range[0], size), fontsize.range[1]);
        document.querySelector("html").style["font-size"] = fontsize.value + "pt";
        this.FontSize = fontsize.value;
    }

    private get ScalingFactor(){
        return Services.SettingsService.getSetting("ScalingFactor").value;
    }

    private set ScalingFactor(value){
        Services.SettingsService.getSetting("ScalingFactor").value = parseFloat(value + "");
        Services.AdaptivePerformanceService.RequestRerender();
    }

    private get TargetTileCount(){
        return Services.SettingsService.getSetting("TargetTileCount").value;
    }

    private set TargetTileCount(value){
        Services.SettingsService.getSetting("TargetTileCount").value = parseInt(value + "");
        Services.AdaptivePerformanceService.RequestRerender();
    }

    private copyLinkToClipboard(){
        navigator.clipboard.writeText(this.SceneLink).catch((e) => this.SceneError = e).then(() => {
            this.CopySuccessful = true;
            setTimeout(() => this.CopySuccessful = false, 1000);
        });
    }

    private sourcetype = "csv";
    private sourcetypes = [];
    private sourcestatus = "";

    createSource(){
        fetch("/add_dyn_source/" + this.sourcetype, {method: "POST"}).then(r => r.json()).then(id => {
            this.sourcestatus = "";
            if(id){
                let interval = setInterval(() => {
                    fetch("/dyn_source_status/" + id).then(r => r.text()).then(t => this.sourcestatus = t);
                    if(this.sourcestatus.startsWith("Error") || this.sourcestatus.startsWith("Cancelled") || this.sourcestatus.startsWith("Finished")){
                        clearInterval(interval);
                    }
                }, 1000)
            }
        });
    }

    async mounted(){
        fetch("/registry").then(r => r.json()).then(types => {this.sourcetypes = types; this.sourcetype = this.sourcetypes[0]});
        Services.InteractionService.addEventListener("KeyDown", (k: KeyDownEvent) => {
            if(Services.InteractionService.getTarget() == "threedee"){
                if (k.key == "h"){
                    this.HideUI = !Services.SettingsService.getSetting("UIVisible").value;
                }
            }
        });
        Services.SettingsService.addEventListener("SettingsChanged", () => {
            this.stupid = !this.stupid;
            this.$forceUpdate();
            });
        window.addEventListener("gamepaddisconnected", (e: GamepadEvent) => {
            this.stupid = !this.stupid;
            this.$forceUpdate();
        });
    }

    async clearCache(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            window.indexedDB.deleteDatabase("data");
            window.location.search = result;
        }catch(e){
            this.SceneError = e.message;
        }

    }

    clearSettings(){
        localStorage["digitalearthviewer_settings"] = [];
        Services.SettingsService.skip_storing = true;
        window.location.reload();
    }

    setLOD(e){
        Services.InitializationService.LOD = e.target.checked;
    }

    setAlwaysRerender(e){
        Services.SettingsService.getSetting("AlwaysRerender").value = e.target.checked;
    }

    setWaitForLoad(e){
        Services.SettingsService.getSetting("WaitForLoad").value = e.target.checked;
    }

    async createLink(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        try{
            let result = await fetch(`create_scene/${encodeURIComponent(this.SceneName)}`, {
                method: "POST",
                body: renderlayers
            }).then(r => r.text());
            this.SceneLink = window.location.origin + "?" + result;
            Services.InitializationService.SceneName = result;
        }catch(e){
            this.SceneError = e.message;
        }
    }

    downloadLayerConfig(){
        let renderlayers = JSON.stringify(Services.InitializationService.getInitialLayerConfig(),null, 4);
        let renderlayertext = "data:application/json;base64," + btoa(renderlayers);
        let a_elem = document.getElementById('downloadlink');
        a_elem.setAttribute("href", renderlayertext);
        a_elem.click();
    }

    uploadLayerConfig(){
        document.getElementById('inputlayerfile').click();
    }

    async handleLayerConfig(e){
        let input: any = document.getElementById('inputlayerfile');
        let file: File = input.files[0];
        if(file){
            let resp = await new Response(file).json();
            try{
                await Services.InitializationService.load_initial_layers(resp);
            }catch(e){
                alert("Layer config could not be loaded: " + e);
            }
        }
    }

    setHideUI(){
        this.HideUI = !this.HideUI;
        Services.SettingsService.getSetting("UIVisible").value = !this.HideUI;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    synchronize(){
        console.log("Synchronize: ", this.Synchronized);
        if(this.Synchronized){
            if(Services.InitializationService.SceneName == ""){
                Services.InitializationService.SceneName = "default";
            }
            Services.SettingsService.getSetting("Synchronized").value = true;
            Services.SynchronizationService.tryStart();
        }else{
            Services.SynchronizationService.stop();
            Services.SettingsService.getSetting("Synchronized").value = false;
        }
    }

    async loadFrustraFile(e){
        let resp = new Response(e.target.files[0]);
        let file = await resp.json();
        Services.InitializationService.DOME_FRUSTRA = file;
        console.log("Loaded " + file.frustra.length + " frustra");
    }

    loadFrustra(){
        document.getElementById("frustraFile").click();
    }

    syncScene(){
        Services.SynchronizationService.syncScene();
    }
}
