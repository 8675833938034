

























import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
})
export default class ScrollBox extends Vue {

    innerwidth = "0px";
    mounted() {
        //this.innerwidth = this.$el.getBoundingClientRect().width + "px";
    }

    beforeUpdate(){
        //this.innerwidth = this.$el.getBoundingClientRect().width + "px";
    }

}
