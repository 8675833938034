//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { UEC } from "../modules/tile";
import { Vec3, Vec4 } from "../modules/vecmat";

export class LayerMetaDataService{
        coord: UEC = new UEC(0,0);
        z: number = 0;
        meshZ: number = 0;
        normal: Vec3 = new Vec3(0,0,0);
        value: Vec4 = new Vec4(0,0,0,0);
        isUpToDate: boolean = false;
}