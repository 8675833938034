//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
export class Barrier{
    private internal_barrier: Promise<void>;
    private is_resolved = false;

    private resolve_handler: any;
    private reject_handler: any;

    constructor(){
        this.internal_barrier = new Promise((res, rej) => {
            this.resolve_handler = res;
            this.reject_handler = rej;
        });
    }

    resolve(){
        if(!this.is_resolved){
            this.resolve_handler();
            this.is_resolved = true;
        }
    }

    reject(){
        this.reject_handler();
    }

    async wait(){
        if(this.is_resolved){
            return;
        }else{
            await this.internal_barrier;
        }
    }

    isResolved(){
        return this.is_resolved;
    }


}