

















import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
@Component({
    props:{
        overlay: Overlay
    }
})
export default class ScalarOverlay extends Vue {
    get value() {
        let info = this.$props.overlay.information;
        return FormattingService.color_0_1_to_hex(info[0], info[1], info[2], "#");
    }

    get color(): string{
        let info = this.$props.overlay.information;
        return "rgba(" + info[0] * 255 + "," + info[1] * 255 + "," + info[2] * 255 + "," + info[3] + ")";
    }
}
