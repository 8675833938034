








































import Vue from 'vue';
import Component from 'vue-class-component';
import {SourceLayerInfo} from '../services/SourceInfoService';
import { RenderSourceSlot } from '../modules/rendersources/RenderSource';
import { Parameter, ParameterAction } from '../modules/Parameter';
import { Services } from '../services/Services';
import NumberInput from './guikit/numberinput.vue';
import VectorInput from './guikit/vectorinput.vue';

@Component({
    components: {
        NumberInput,
        VectorInput
    },
    props:{
        param: Parameter
    }
})
export default class ParameterComponent extends Vue{
    min = null;
    max = null;

    mounted(){
        if(this.$props.param.range){
            this.min = this.$props.param.range[0];
            this.max = this.$props.param.range[1];
        }
    }

    triggerAction(action: ParameterAction){
        action.Action();
        Services.AdaptivePerformanceService.RequestRerender();
        if(this.$props.refreshRequester){
            this.$props.refreshRequester();
        }
    }

    getColormapNames(){
        return Services.ColormapService.getColormapNames();
    }

    getCSSGradient(name){
        return Services.ColormapService.get_CSSGradient(name);
    }

    change(){
        Services.AdaptivePerformanceService.RequestRerender();
        this.$emit("change");
    }



}
