//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { StitchedTileData } from "../../services/StitchedTilesService";
import { UEC, UECArea } from "../tile";
import { Particles } from "../../services/ParticlesService";

export class VectorTracerArrowRenderSource extends RenderSource {
    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.particleArrows;
        this.name = "TileRenderSource";
        this.parameters = {
            displacement_scale: Services.SettingsService.getSetting("Exaggeration"),
            displacement_offset: new Parameter("Vertical Offset", 0, "number", true).setShaderName("displacement_offset"),
            particle_speed: new Parameter("Particle Speed", 100, "number", true, false).setStep(10).setShaderName("_"),
            particle_lifetime: new Parameter("Particle Lifetime", 32, "number", true, false).setShaderName("_"),
            particle_amount: new Parameter("Particle Amount",100,"number",true, false).setRange(0,100).setUnit("%"),
            point_size: new Parameter("Point Size", 3, "number", true, false).setShaderName("point_size"),
            "value_sizing_enabled": new Parameter("Size by Value", false, "boolean", true).setShaderName("value_sizing_enabled"),
            "value_sizing_zero": new Parameter("Minimum Size at", 0, "number", true).setShaderName("value_sizing_zero"),
            "value_sizing_one": new Parameter("Maximum Size at", 1, "number", true).setShaderName("value_sizing_one"),
            "value_sizing_power": new Parameter("Proportionality", 1, "number", true).setStep(0.1).setShaderName("value_sizing_power"),
            z_level: new Parameter("Z Level", 0,"select", true).addOption(0).setShaderName("_")
        };

        this.slots = {
            "displacement": new RenderSourceSlot(
                "Displacement Layer",
                "displacement",
                null,
                "tile",
                null
            ),
            "datau": new RenderSourceSlot(
                "Data Layer U",
                "datau",
                null,
                "tile",
                null
            ),
            "datav": new RenderSourceSlot(
                "Data Layer V",
                "datav",
                null,
                "tile",
                null
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["displacement"]?.source?.layer?.datarange){
            let min_scaled = this.applyScaling(this.slots["displacement"].source.layer.datarange[0]);
            let max_scaled = this.applyScaling(this.slots["displacement"].source.layer.datarange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.slots["displacement"]?.source?.layer?.datarange){
            let min_scaled = this.applyOffset(this.slots["displacement"].source.layer.datarange[0]);
            let max_scaled = this.applyOffset(this.slots["displacement"].source.layer.datarange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getExtent(): UECArea {
        let e_layers = Object.values(this.slots).filter((v: RenderSourceSlot) => 
            v.shaderName != "displacement" && v.source?.layer?.extent
        ).map(v => v.source.layer.extent);
        if (e_layers.length > 0) return e_layers.reduce((b, nb) => {
            let tlc = new UEC(Math.max(nb.position.x, b.position.x), Math.max(nb.position.y, b.position.y));
            let brc = new UEC(Math.min(nb.position.x + nb.extent.x, b.position.x + b.extent.x), Math.min(nb.position.y + nb.extent.y, b.position.y + b.extent.y));
            return new UECArea(tlc, new UEC(brc.x - tlc.x, brc.y - tlc.y));
        }, new UECArea(new UEC(0, 0), new UEC(1, 1)));
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    requestStitchedTiles(){
        let time = Services.TimeService.getMeanTime();
        let height = 0;
        if(this.slots["datau"].source && this.slots["datav"].source){
            Services.StitchedTilesService.getStitchedVector2Tiles(this.slots["datau"].source, this.slots["datav"].source, time, height);
        }
        if(this.slots["displacement"].shaderName == "displacement" && this.slots["displacement"].source){
            Services.StitchedTilesService.getStitchedScalarTiles(this.slots["displacement"].source, time, height);
        }
    }

    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        super.execute(context);

        context.gl.uniform1f(this.shader.uniforms["point_size"], 30000 * this.parameters["point_size"].value * Services.PositionService.camera_distance_filtered);


        let time = Services.TimeService.getMeanTime();
        let height = 0;
        if(!this.slots["datau"].source || !this.slots["datav"].source)return;
        if(this.slots["datau"].source.layer.zsteps){
            height = parseFloat(this.parameters["z_level"].value);
        }

        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        let buff = Services.GLService.Geometries.tracers;
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 2, context.gl.FLOAT, false, 0, 0);
        let texi = 0;

        let arrow_geo = Services.GLService.Geometries.arrow;
        context.gl.enableVertexAttribArray(this.shader.attributes["geometry_position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, arrow_geo.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["geometry_position"], 3, context.gl.FLOAT, false, 0, 0);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["geometry_position"], 0);

        let vect: StitchedTileData;
        if(this.slots["datau"].source && this.slots["datav"].source){
            vect = Services.StitchedTilesService.getStitchedVector2Tiles(this.slots["datau"].source, this.slots["datav"].source, time, height);
            if(vect){
                context.gl.activeTexture(context.gl.TEXTURE0 + texi);
                context.gl.bindTexture(context.gl.TEXTURE_2D, vect.texture);
                context.gl.uniform1i(this.shader.uniforms["stitched_vector_map"], texi);
                context.gl.uniform2f(this.shader.uniforms["stitched_vector_coord_scale"], vect.coord_scale[0], vect.coord_scale[1]);
                context.gl.uniform2f(this.shader.uniforms["stitched_vector_coord_offset"], vect.coord_offset[0], vect.coord_offset[1]);
                texi++;
            } else {
                return
            }
        } else {
            return;
        }
        let disp: StitchedTileData;
        context.gl.activeTexture(context.gl.TEXTURE0 + texi);
        context.gl.bindTexture(context.gl.TEXTURE_2D, null);
        if(this.slots["displacement"].shaderName == "displacement" && this.slots["displacement"].source){
            disp = Services.StitchedTilesService.getStitchedScalarTiles(this.slots["displacement"].source, time, height);
            if(disp){
                context.gl.bindTexture(context.gl.TEXTURE_2D, disp.texture);
                context.gl.uniform2f(this.shader.uniforms["stitched_displacement_coord_scale"], disp.coord_scale[0], disp.coord_scale[1]);
                context.gl.uniform2f(this.shader.uniforms["stitched_displacement_coord_offset"], disp.coord_offset[0], disp.coord_offset[1]);
            }
        }
        context.gl.uniform1i(this.shader.uniforms["stitched_displacement_map"], texi);
        texi++;
        let part: Particles = Services.ParticlesService.getParticles(
            this.slots["datau"].source,
            this.slots["datav"].source,
            time, height,
            this.parameters["particle_speed"].value * Math.sqrt(Services.PositionService.camera_distance_filtered),
            this.parameters["particle_lifetime"].value
        );
        if(part){
            let pdata = part.get_data();
            context.gl.activeTexture(context.gl.TEXTURE0 + texi);
            context.gl.bindTexture(context.gl.TEXTURE_2D, pdata.source);
            context.gl.uniform1i(this.shader.uniforms["particle_pos"], texi);
            context.gl.uniform1f(this.shader.uniforms["source_offset"], pdata.sourceOffset);
            texi++;
        } else {
            return;
        }
        for(var i = texi; i < 8; i++){
            context.gl.activeTexture(context.gl.TEXTURE0 + i);
            context.gl.bindTexture(context.gl.TEXTURE_2D, null);
        }

        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 1);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 6 * 4, 0);

        context.ANGLE_instanced_arrays.drawArraysInstancedANGLE(context.gl.TRIANGLES, 0, arrow_geo.length, buff.length * (this.parameters["particle_amount"].value / 100));

        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
        context.ANGLE_instanced_arrays.vertexAttribDivisorANGLE(this.shader.attributes["position"], 0);
        context.gl.disableVertexAttribArray(this.shader.attributes["geometry_position"]);
    }
}