








































































































import Vue from 'vue'
import Component from 'vue-class-component';
import { SourceLayerInfo} from '../services/SourceInfoService';
import SourceLayerBadge from './sourcelayerbadge.vue';
import { Services } from '../services/Services';
import SVGIcon from './guikit/svgIcon.vue';

interface row {
    source: string, 
    layer: string, 
    unit: string, 
    layertype: string,
    original_layertype: string,
    sli: SourceLayerInfo,
    category: string
};

@Component({
    components: {
        SourceLayerBadge,
        SVGIcon
    },
    props: {
        data: {
            type: Object,
            default: {}
        }
    }
})
export default class SourceSelectionDialog extends Vue{
    expanded_category = "";
    expanded_source = ""
    filtered_sources: row[] = [];
    sources: row[] = [];
    filter_term = "";
    type_filter = "";
    title: string = null;

    get categories(){
        return [... new Set(this.filtered_sources.map(f => f.category || "Other")),];
    }

    sources_for_category(category){
        return [... new Set(this.filtered_sources.filter(r => r.category == category).map(f => f.source))];
    }

    rows_for_source(source){
        return this.filtered_sources.filter(r => r.source == source);
    }

    get filter(){
        return this.filter_term;
    }

    set filter(value){
        this.filter_term = value.toLowerCase();
        this.filtered_sources = this.sources.filter((s: row) => {
            return (this.type_filter == "" || !this.type_filter || s.original_layertype == this.type_filter) && (this.filter_term == "" || (
                   s.source.toLowerCase().includes(this.filter_term) 
                || s.layer.toLowerCase().includes(this.filter_term)
                || s.unit.toLowerCase().includes(this.filter_term)
                || s.layertype.toLowerCase().includes(this.filter_term))
            );
        });
    }

    toggle_category(category: string){
        if(this.expanded_category == category){
            this.expanded_category = "";
        }else{
            this.expanded_category = category;
        }
    }

    toggle_source(source: string){
        if(this.expanded_source == source){
            this.expanded_source = "";
        }else{
            this.expanded_source = source;
        }
    }

    return_source(sli: SourceLayerInfo){
        //Remove yourself first since you may be recreated immediately in the next step!!!
        Services.DialogBoxService.remove("Select Source");
        this.$props.data.sourceSelectionCallback(sli);
    }

    sort_filtered_by(key: string){
        this.filtered_sources.sort((a,b) => a[key].localeCompare(b[key]));
    }

    mounted(){
        this.type_filter = this.$props.data.typeFilter;
        this.refresh_sources();
    }

    refresh_sources(){
        this.sources = Services.SourceInfoService.get_all_source_layer_infos().map((sli: SourceLayerInfo) => {
            return ({
                source: sli.instance_name || "",
                layer: sli.layer.long_name || sli.layer_name || "",
                unit: sli.layer.unit || "",
                layertype: sli.layer.layer_type || "",
                original_layertype: sli.layer.layer_type,
                sli: sli,
                category: sli.source.category
            });
        });
       this.filter = "";
    }

}
