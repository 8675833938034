//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import {ServiceBarrier, Services} from './Services';
import {Vec4, AaBb, Vec3} from '../modules/vecmat';
import {Tile, UEC, UECArea} from '../modules/tile';
import { SourceLayerInfo } from './SourceInfoService';
import { Parameter } from '../modules/Parameter';

export class RequiredTilesChangedEvent extends Event{
    public required_tiles: Tile[]; //The current set of tiles needed to render the screen
    public new_tiles: Tile[]; //Which of the current tiles are new as compared to the last frame
    public obsolete_tiles: Tile[]; //Which of the tiles from last frame are now obsolete?
    constructor(required_tiles: Tile[], new_tiles: Tile[], obsolete_tiles: Tile[]){
        super("RequiredTilesChanged");
        this.required_tiles = required_tiles;
        this.new_tiles = new_tiles;
        this.obsolete_tiles = obsolete_tiles;
    }
}

export class RequiredTilesService extends EventTarget{

    constructor(){
        super();
        this.required_tiles = new Map();
        ServiceBarrier.wait().then(() => {
            Services.SettingsService.initializeSetting(new Parameter("TargetTileCount", 8, "number"));
        });
    }

    private required_tiles: Map<string, Tile[]>; //The currently required set of tiles
    private last_required_tiles: Tile[] = []; //The last required set of tiles, for diffing

    private planesets: Vec4[][] = [];

    public clearRequiredTiles(){
        this.last_required_tiles = this.required_tiles.get("default") || [];
        this.required_tiles.clear();
        this.planesets = [];
    }

    public addRequiredTiles(){
        let matrix_world_inverse = Services.PositionService.world_transform.transpose();
        let projection_matrix = Services.PositionService.camera_transform.transpose();
        var mvcols = matrix_world_inverse.mul_mat4(projection_matrix).as_cols();
        this.planesets.push([
        mvcols[3].add(mvcols[0]), //left plane
        mvcols[3].sub(mvcols[0]), //right plane
        mvcols[3].add(mvcols[1]), //bottom plane
        mvcols[3].sub(mvcols[1]), //top plane
        mvcols[3].add(mvcols[2]), //near plane
        mvcols[3].sub(mvcols[2]) //far plane
        ]);
    }

    public finalizeRequiredTiles(){
        let required_tiles = 
            this.get_required_tiles_internal(
                this.planesets,
                new UECArea(new UEC(0, 0), new UEC(1, 1)),
                (tile) => {
                    return Services.RenderLayerService.get_euclidian_global_height_range()
                },
                (x) => x,
                false
            );
        let new_tiles = required_tiles.filter(t => !this.last_required_tiles.some(t_ => t.path == t_.path));
        let obsolete_tiles = this.last_required_tiles.filter(t => !required_tiles.some(t_ => t.path == t_.path));
        if(new_tiles.length > 0 || obsolete_tiles.length > 0){
            this.dispatchEvent(new RequiredTilesChangedEvent(required_tiles, new_tiles, obsolete_tiles));
        }
    }

    public getRequiredTiles(): Tile[]{
        return this.required_tiles.get("default");
    }

    public getTilespaceBounds(): UECArea{
        let r = this.required_tiles.get("default").reduce((b, t) => [
            new UEC(Math.min(t.position.x, b[0].x), Math.min(t.position.y, b[0].y)),
            new UEC(Math.max(t.position.x + t.size.x, b[1].x), Math.max(t.position.y + t.size.y, b[1].y))
        ], [new UEC(1, 1), new UEC(0, 0)]);
        return new UECArea(r[0], new UEC(r[1].x - r[0].x, r[1].y - r[0].y));
    }

    private get_required_tiles_internal(
        planesets: Vec4[][],
        extent: UECArea,
        verticalRange: (tile: Tile) => [number, number],
        applyScaling: (value: number) => number,
        filter_again: boolean,
        tag: string = "default",
    ): Tile[] {
        let tiles;
        let is_clipping;
        switch (Services.PositionService.projection_mode) {
            case "SPHERE": {
                is_clipping = (tile: Tile) => {
                    let heightrange = verticalRange(tile);
                    let bb = tile.bounds(applyScaling(heightrange[0]), applyScaling(heightrange[1]));
                    return planesets.some(clipplanes => clipplanes.every(c => !bb.check_for_all_corners(p => Vec4.from_vf(p, 1.0).dot(c) <= 0)) && tile.in_extent(extent));
                }
                break;
            }
            case "EQUIRECT": {
                is_clipping = (tile: Tile) => {
                    let heightrange = verticalRange(tile);
                    let bb = new AaBb(new Vec3(tile.position.x, tile.position.y, applyScaling(heightrange[0]) - 1), new Vec3(tile.position.x + tile.size.x, tile.position.y + tile.size.y, applyScaling(heightrange[1]) - 1));
                    //if (t.position.x > 0.99){
                    //    return clipplanes.every(c => bb.corners().some(p => Vec4.from_vf(p, 1.0).dot(c) > 0 || Vec4.from_vf(p.sub(new Vec3(1, 0, 0)), 1.0).dot(c) > 0)) && t.in_extent(extent);
                    //} else if (t.position.x < 0.01){
                    //    return clipplanes.every(c => bb.corners().some(p => Vec4.from_vf(p, 1.0).dot(c) > 0 || Vec4.from_vf(p.add(new Vec3(1, 0, 0)), 1.0).dot(c) > 0)) && t.in_extent(extent);
                    //} else {
                        return planesets.some(clipplanes => clipplanes.every(c => !bb.check_for_all_corners(p => Vec4.from_vf(p, 1.0).dot(c) <= 0)) && tile.in_extent(extent));
                    //}
                }
                break;
            }
        }
        if(this.required_tiles.has(tag)){
            tiles = this.required_tiles.get(tag);
        }else{
            let targetcount = Services.SettingsService.getValueOrDefault("TargetTileCount", 8)
            * Services.RenderService.width 
            * Services.RenderService.height
            / Services.InitializationService.getTileWidth()
            / Services.InitializationService.getTileHeight();
            tiles = [Tile.from_tilepath("W"), Tile.from_tilepath("E")].filter(is_clipping);
            while (tiles.length > 0){
                let ntiles = tiles.flatMap(t => t.split()).filter(is_clipping);
                if(ntiles.length >= targetcount)break;
                tiles = ntiles;
            }
            this.required_tiles.set(tag, tiles);
        }
        if(filter_again){
            let matrix_world_inverse = Services.PositionService.world_transform.transpose();
            let projection_matrix = Services.PositionService.camera_transform.transpose();
            var mvcols = matrix_world_inverse.mul_mat4(projection_matrix).as_cols();
            planesets = [[
                mvcols[3].add(mvcols[0]), //left plane
                mvcols[3].sub(mvcols[0]), //right plane
                mvcols[3].add(mvcols[1]), //bottom plane
                mvcols[3].sub(mvcols[1]), //top plane
                mvcols[3].add(mvcols[2]), //near plane
                mvcols[3].sub(mvcols[2]) //far plane
                ]];
            return tiles.filter(is_clipping);
        }else{
            return tiles;
        }
    }

    public getRequiredTilesBoundedDisplaced(extent: UECArea, layer: SourceLayerInfo, time: number, height: number, applyScaling: (value: number) => number): Tile[] {
        return this.get_required_tiles_internal(
            this.planesets,
            extent,
            (tile) => {
                return Services.TileCacheService.get_tile_data_range(layer, tile, time, height) || layer.layer.datarange || [0, 0]
            },
            applyScaling,
            true,
            JSON.stringify([
                extent.topLeft().x,
                extent.topLeft().y,
                extent.bottomRight().x,
                extent.bottomRight().y,
                layer.getPath()
            ])
        )
    }

    public getRequiredTilesBounded(extent: UECArea, height_extent: [number, number] = null): Tile[] {
        return this.get_required_tiles_internal(
            this.planesets,
            extent,
            (tile) => {
                return height_extent || Services.RenderLayerService.get_euclidian_global_height_range()
            },
            (x) => x,
            true,
            JSON.stringify([
                extent.topLeft().x,
                extent.topLeft().y,
                extent.bottomRight().x,
                extent.bottomRight().y
            ])
        )

    }
}