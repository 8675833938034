//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { glenv } from "../services/RenderService";
import { UEC } from "../modules/tile";
import { Vec3, Vec4 } from "./vecmat";

//For easier use below, make sure that this does NOT unbind the texture.
let  create_texture = (gl: glenv, w: number, h: number, format: number, type: number, filter: number, wrap: number) => {
    let t = gl.gl.createTexture();
    gl.gl.bindTexture(gl.gl.TEXTURE_2D, t);
    gl.gl.texImage2D(gl.gl.TEXTURE_2D, 0, format, w, h, 0, format, type, null);
    gl.gl.texParameteri(gl.gl.TEXTURE_2D, gl.gl.TEXTURE_MAG_FILTER, filter);
    gl.gl.texParameteri(gl.gl.TEXTURE_2D, gl.gl.TEXTURE_MIN_FILTER, filter);
    gl.gl.texParameteri(gl.gl.TEXTURE_2D, gl.gl.TEXTURE_WRAP_S, wrap);
    gl.gl.texParameteri(gl.gl.TEXTURE_2D, gl.gl.TEXTURE_WRAP_T, wrap);
    return t;
};

export class LayerPipelineFramebuffer {
    context: glenv;
    value_1: WebGLTexture;
    value_2: WebGLTexture;
    depth_1: WebGLTexture;
    depth_2: WebGLTexture;
    normal: WebGLTexture;
    worldPos: WebGLTexture;
    input_framebuffer: WebGLFramebuffer;
    filter_framebuffer_1: WebGLFramebuffer;
    filter_framebuffer_2: WebGLFramebuffer;
    worldPosReadFramebuffer: WebGLFramebuffer;
    worldNormalReadFramebuffer: WebGLFramebuffer;
    flip_state: boolean = false;

    constructor(ctx: glenv, w: number, h: number) {
        this.context = ctx;
        let gl: WebGLRenderingContext = ctx.gl;
        let ext: WEBGL_draw_buffers = ctx.WEBGL_draw_buffers;

        this.input_framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.input_framebuffer);

        this.value_1 = create_texture(ctx, w, h, gl.RGBA, gl.FLOAT, gl.LINEAR, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT0_WEBGL, gl.TEXTURE_2D, this.value_1, 0);

        this.depth_1 = create_texture(ctx, w, h, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, gl.NEAREST, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth_1, 0);

        this.normal = create_texture(ctx, w, h, gl.RGBA, gl.FLOAT, gl.LINEAR, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT1_WEBGL, gl.TEXTURE_2D, this.normal, 0);

        this.worldPos = create_texture(ctx, w, h, gl.RGBA, gl.FLOAT, gl.LINEAR, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT2_WEBGL, gl.TEXTURE_2D, this.worldPos, 0);

        this.worldPosReadFramebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.worldPosReadFramebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT0_WEBGL, gl.TEXTURE_2D, this.worldPos, 0);

        gl.bindTexture(gl.TEXTURE_2D, this.depth_1);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth_1, 0);

        this.worldNormalReadFramebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.worldNormalReadFramebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth_1, 0);

        gl.bindTexture(gl.TEXTURE_2D, this.normal);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT0_WEBGL, gl.TEXTURE_2D, this.normal, 0);

        this.filter_framebuffer_1 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.filter_framebuffer_1);

        gl.bindTexture(gl.TEXTURE_2D, this.value_1);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.value_1, 0);

        gl.bindTexture(gl.TEXTURE_2D, this.depth_1);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth_1, 0);

        this.filter_framebuffer_2 = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.filter_framebuffer_2);

        this.value_2 = create_texture(ctx, w, h, gl.RGBA, gl.FLOAT, gl.LINEAR, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.value_2, 0);
        
        this.depth_2 = create_texture(ctx, w, h, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, gl.NEAREST, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth_2, 0);
    }

    /*
     * This function must be called directly after rendering a layer since it does not bind the required framebuffer
     * for the first readback.
     */
    readInputFramebuffer(x, y): {coord: UEC, z: number, meshZ: number, normal: Vec3, value: Vec4, exists: boolean} {
        let gl = this.context.gl;
        let readPixelArray = new Float32Array(4);
        gl.readPixels(x, y, 1, 1, gl.RGBA, gl.FLOAT, readPixelArray);
        let result_value = new Vec4(readPixelArray[0], readPixelArray[1], readPixelArray[2], readPixelArray[3]);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.worldPosReadFramebuffer);
        gl.readPixels(x, y, 1, 1, gl.RGBA, gl.FLOAT, readPixelArray);
        let result_coord = new UEC(readPixelArray[0], readPixelArray[1]);
        let result_z = readPixelArray[2];
        let result_meshZ = readPixelArray[3];
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.worldNormalReadFramebuffer);
        gl.readPixels(x, y, 1, 1, gl.RGBA, gl.FLOAT, readPixelArray);
        let result_normal = new Vec3(readPixelArray[0], readPixelArray[1], readPixelArray[2]);
        let result_exists = readPixelArray[3] == 1.0;
        return {
            value: result_value,
            coord: result_coord,
            z: result_z,
            meshZ: result_meshZ,
            normal: result_normal,
            exists: result_exists
        };
    }

    assignDrawBuffers() {
        let ext = this.context.WEBGL_draw_buffers;
        ext.drawBuffersWEBGL([
            ext.COLOR_ATTACHMENT0_WEBGL,
            ext.COLOR_ATTACHMENT1_WEBGL,
            ext.COLOR_ATTACHMENT2_WEBGL
        ]);
    }

    //clears all framebuffers and sets the input framebuffer for multilayer rendering.
    setInputFramebuffer() {
        this.flip_state = false;
        let gl = this.context.gl;
        let ext = this.context.WEBGL_draw_buffers;
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.filter_framebuffer_1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.filter_framebuffer_2);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.input_framebuffer);
        gl.clear(gl.COLOR_BUFFER_BIT);
        ext.drawBuffersWEBGL([
            ext.COLOR_ATTACHMENT0_WEBGL,
            ext.COLOR_ATTACHMENT1_WEBGL,
            ext.COLOR_ATTACHMENT2_WEBGL
        ]);
    }

    //sets the framebuffer for fx rendering and flips. call ONCE only for every fx call.
    setFxFramebufferAndGetTextures() {
        let gl = this.context.gl;
        this.flip_state = !this.flip_state;
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.flip_state ? this.filter_framebuffer_2 : this.filter_framebuffer_1);
        return this.flip_state ? {
            value: this.value_1,
            normal: this.normal,
            depth: this.depth_1,
            worldPos: this.worldPos
        } : {
            value: this.value_2,
            normal: this.normal,
            depth: this.depth_2,
            worldPos: this.worldPos
        }
    }

    //gets the output textures that would contain the results of an fx call that was set up with setFxFramebufferAndGetTextures
    getResultTextures() {
        return this.flip_state ? {
            value: this.value_2,
            normal: this.normal,
            depth: this.depth_2,
            worldPos: this.worldPos
        } : {
            value: this.value_1,
            normal: this.normal,
            depth: this.depth_1,
            worldPos: this.worldPos
        };
    }

    dispose() {
        this.context.gl.deleteTexture(this.value_1);
        this.context.gl.deleteTexture(this.value_2);
        this.context.gl.deleteTexture(this.depth_1);
        this.context.gl.deleteTexture(this.depth_2);
        this.context.gl.deleteTexture(this.normal);
        this.context.gl.deleteTexture(this.worldPos);
        this.context.gl.deleteFramebuffer(this.input_framebuffer);
        this.context.gl.deleteFramebuffer(this.filter_framebuffer_1);
        this.context.gl.deleteFramebuffer(this.filter_framebuffer_2);
        this.context.gl.deleteFramebuffer(this.worldPosReadFramebuffer);
    }
}

export class CompositionPipelineFramebuffer{
    context: glenv;
    color: WebGLTexture;
    //normal: WebGLTexture;
    depth: WebGLTexture;
    framebuffer: WebGLFramebuffer;

    constructor(ctx: glenv, w: number, h: number){
        this.context = ctx;
        let gl = ctx.gl;
        let ext: WEBGL_draw_buffers = ctx.WEBGL_draw_buffers;
        this.color = gl.createTexture();
        //this.normal = gl.createTexture();
        this.depth = gl.createTexture();

        this.framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer);

        gl.bindTexture(gl.TEXTURE_2D, this.color);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, w, h, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT0_WEBGL, gl.TEXTURE_2D, this.color, 0);

        /*
        gl.bindTexture(gl.TEXTURE_2D, this.normal);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, w, h, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT1_WEBGL, gl.TEXTURE_2D, this.normal, 0);
        */
        gl.bindTexture(gl.TEXTURE_2D, this.depth);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT, w, h, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depth, 0);
    }

    assignDrawBuffers(){
        /*let ext = this.context.WEBGL_draw_buffers;
        ext.drawBuffersWEBGL([
            ext.COLOR_ATTACHMENT0_WEBGL,
            ext.COLOR_ATTACHMENT1_WEBGL,
        ]);*/
    }

    dispose() {
        this.context.gl.deleteTexture(this.color);
        this.context.gl.deleteTexture(this.depth);
        //this.context.gl.deleteTexture(this.normal);
        this.context.gl.deleteFramebuffer(this.framebuffer);
    }
}