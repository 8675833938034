




































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import {ColormapService} from '../services/ColormapService';
import { RenderLayer} from '../modules/RenderLayer';
import {SelectedLayerChangedEvent} from '../services/RenderLayerService';
import { Services } from '../services/Services';

@Component
export default class Scale extends Vue{
    selectedLayer: RenderLayer = null;
    boxheight = 1;
    labels= [];
    selectedValue = "";
    selectedIndex = -1;

    mounted(){
        this.selectedLayer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", (e: SelectedLayerChangedEvent) => this.selectedLayer = e.selectedLayer);
        setTimeout(() => {
            this.boxheight = document.getElementById('colormap').getBoundingClientRect().height;
            this.labels.push(document.getElementById('scale-label-1'));
            this.labels.push(document.getElementById('scale-label-2'));
            this.labels.push(document.getElementById('scale-label-3'));
            this.labels.push(document.getElementById('scale-label-4'));
        },100);
    }

    mousemove(e){
        let height_fraction = (e.layerY / this.boxheight);
        if(height_fraction < 0 || height_fraction > 1){
            return;
        }
        const positions = [0.05, (0.9 / 3 + 0.05), (1.8 / 3 + 0.05), 0.95];
        let min_distance = 1;
        let min_index = 0;
        for(var i = 0; i < positions.length; i++){
            let distance = Math.abs(positions[i] - height_fraction);
            if(distance < min_distance){
                min_distance = distance;
                min_index = i;
            }
        }
        this.labels[min_index].style['transform'] = `translateY(calc(30vh * ${height_fraction}))`;
        this.selectedValue = this.range(1 - height_fraction);
        if(this.selectedIndex != min_index && this.selectedIndex >= 0){
            this.labels[this.selectedIndex].style['transform'] = '';
        }
        this.selectedIndex = min_index;
    }

    mouseleave(e){
        this.labels[0].style['transform'] = '';
        this.labels[1].style['transform'] = '';
        this.labels[2].style['transform'] = '';
        this.labels[3].style['transform'] = '';
        this.selectedIndex = -1;
    }

    getCurrentColormapCss(): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            return Services.ColormapService.get_CSSGradient(filter.parameters["colormap"].value);
        }else{
            return "black";
        }
    }

    range(pos): string {
        let filter;
        if(this.selectedLayer && (filter = this.selectedLayer.filterPipeline.find(x => x.name == 'colormap'))){
            let vmax = filter.parameters["colormap_max"].value;
            let vmin = filter.parameters["colormap_min"].value;
            let decs = FormattingService.decimals_for_range(vmin, vmax);
            let v = (vmax * pos) + (vmin * (1 - pos));
            if (filter.parameters["colormap_max"].unit) {
                let unit = filter.parameters["colormap_max"].unit;
                return FormattingService.num_to_string_unit(v, unit, decs);
            } else {
                return FormattingService.number_to_string(v, decs);
            }
        }
    }

    mousedown(e){
        Services.InteractionService.mousedown("threedee");
    }

}
