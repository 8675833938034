



























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './svgIcon.vue';
import { Services } from '../../services/Services';
import { FormattingService } from '../../services/FormattingService';
import { InteractionService } from '../../services/InteractionService';
@Component({
    components: {
        SvgIcon
    },
    props:{
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            required: false
        },
        max: {
            type: Number,
            required: false
        },
        step: { 
            type: Number,
            required: false
        },
        unit: {
            type: String,
            required: false
        },
        decimals: {
            type: Number,
            required: false
        }
    }
})
export default class NumberInput extends Vue{
    uid;
    travelledDistance: number;
    editEnabled = false;

    get progress(){
        if(this.$props.min != null && this.$props.max != null)
            return (this.$props.value - this.$props.min) / (this.$props.max - this.$props.min);
        return 0;
    }

    get raw_value(){
        return FormattingService.num_to_string_unit(this.$props.value, this.$props.unit, 3);
    }

    mounted(){
        this.uid = Services.UIDService.getUid();
        Services.InteractionService.mouseMoveHandlers.set(this.uid, this.mousemove);
        Services.InteractionService.mouseUpHandlers.set(this.uid, this.mouseup);
    }

    checkEnter(e){
        if(e.keyCode == 13){
            this.editDone(e);
        }
    }

    editDone(e){
        let v = parseFloat(e.target.value);
        if(!isNaN(v)){
            this.setValue(v);
        }
        this.editEnabled = false;
        return false;
    }

    gotFocus(e){
        this.editEnabled = true;
        e.target.setSelectionRange(0, e.target.value.length);
    }

    destroyed(){
        Services.InteractionService.mouseMoveHandlers.delete(this.uid);
        Services.InteractionService.mouseUpHandlers.delete(this.uid);
    }

    mousedown(e){
        Services.InteractionService.mousedown(this.uid);
        this.travelledDistance = 0;
    }

    setValue(v) {
        if(this.$props.min != null)
            v = Math.max(v, this.$props.min);
        if(this.$props.max != null)
            v = Math.min(v, this.$props.max);
        this.$emit("input", v);
    }

    mousemove(e: MouseEvent){
        if(e.buttons == 1){
            if(!this.editEnabled){
                this.travelledDistance += Math.abs(e.movementX);
                let tw = this.$el.getBoundingClientRect().width;
                let mscale;
                if(this.$props.min != null && this.$props.max != null){
                    mscale = this.$props.max - this.$props.min;
                } else {
                    mscale = Math.max(1, Math.abs(this.$props.value / 10));
                }
                this.setValue(this.$props.value + e.movementX / tw * mscale);
            }
        }
    }

    mouseup(e: MouseEvent){
        if(this.travelledDistance == 0){
            this.editEnabled = true;
            (this.$el.getElementsByClassName("number-input-input")[0] as HTMLInputElement).focus();
        }
    }

    decrease(e){
        if(this.$props.step){
            this.setValue(this.$props.value - this.$props.step) + "";
        }else{
            this.setValue(this.$props.value - 1) + "";
        }
    }

    increase(e){
        if(this.$props.step){
            this.setValue(this.$props.value + this.$props.step) + "";
        }else{
            this.setValue(this.$props.value + 1) + "";
        }
    }


}
