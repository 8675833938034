





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Vec2, Vec3, Mat3 } from '../../modules/vecmat';
import { Services } from '../../services/Services';
import SvgIcon from './svgIcon.vue';
@Component({
    components: {
        SvgIcon
    },
    props:{
        value: {
            type: Vec3,
            default: new Vec3(1, 1, 1)
        }
    }
})
export default class VectorInput extends Vue{
    uid;
    start_value: Vec3;
    start_mouse: Vec2;

    get sun_top() {
        return (1 - this.$props.value.norm().x3 * 0.8) * 50 + "%";
    }

    get sun_left() {
        return (1 + this.$props.value.norm().x2 * 0.8) * 50 + "%";
    }

    get moon_top() {
        return (1 + this.$props.value.norm().x3 * 0.8) * 50 + "%";
    }

    get moon_left() {
        return (1 - this.$props.value.norm().x2 * 0.8) * 50 + "%";
    }

    mounted(){
        this.uid = Services.UIDService.getUid();
        Services.InteractionService.mouseMoveHandlers.set(this.uid, this.mousemove);
        Services.InteractionService.mouseUpHandlers.set(this.uid, this.mouseup);
    }

    destroyed(){
        Services.InteractionService.mouseMoveHandlers.delete(this.uid);
        Services.InteractionService.mouseUpHandlers.delete(this.uid);
    }

    setValue(v){
        this.$emit("input", v);
    }

    mousedown(e: MouseEvent){
        Services.InteractionService.mousedown(this.uid, e);
        this.start_value = this.$props.value;
        this.start_mouse = new Vec2(e.clientX, e.clientY);
    }

    mousemove(e: MouseEvent) {
        if(e.buttons == 1){
            let delta = new Vec2(e.clientX, e.clientY).sub(this.start_mouse);
            let r_axis = new Vec3(0.0, delta.x2, delta.x1);
            let r_amount = delta.abs() / 100;
            this.setValue(Mat3.rot(r_amount, r_axis).mul_vec3(this.start_value).norm());
        }
    }

    mouseup(e: MouseEvent) {

    }
}
