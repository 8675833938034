//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { UEC } from '../modules/tile';
import { RenderLayer } from '../modules/RenderLayer';
import { Vec3 } from '../modules/vecmat';
import { Services } from './Services';
import { SourceLayerInfo } from './SourceInfoService';

export class SerializedOverlay {
    location: [number, number, number];
    information: [number, number, number, number];
}

export class Overlay{
    public location: UEC;
    public height: number;
    public screen_position: Vec3;
    public layer: RenderLayer;
    public information: [number, number, number, number];
    public id: number;
    public unit: string;
    public hovered: boolean;
    public component: string;
    public get sourcelayerinfo():SourceLayerInfo{
        if(this.layer.source){
            return (this.layer.source.slots["data0"] || this.layer.source.slots["points"]).source;
        }else{
            return null;
        }
    }

    constructor(location: UEC, height: number, layer: RenderLayer, information: [number, number, number, number], id: number){
        this.location = location;
        this.height = height;
        this.layer = layer;
        this.information = information;
        this.unit = layer.unit;
        this.id = id;
        this.component = layer.overlayHint;
        this.updateScreenPosition();
        Services.GLService.addEventListener("FrameDone", () => this.updateScreenPosition());
    }

    public updateScreenPosition(){
        this.screen_position = Services.PositionService.UECHeightToScreenPixels(
            this.location,
            this.layer.source.applyScaling(this.height)
            ).toVec3();
    }

    serialize(): SerializedOverlay{
        let r = new SerializedOverlay();
        r.location = [this.location.x, this.location.y, this.height];
        r.information = this.information;
        return r;
    }
}

export class OverlaysChangedEvent extends Event{
    public overlays: Overlay[] = [];
    constructor(overlays: Overlay[]){
        super("OverlaysChanged");
        this.overlays = overlays;
    }
}

export class OverlayService extends EventTarget{

    public overlays: Overlay[] = [];
    private next_overlay_id = 1;
    
    public addOverlay(location: UEC, height: number, layer: RenderLayer, information: [number, number, number, number]){
        this.overlays.push(new Overlay(location,height,layer,information,this.next_overlay_id++));
        this.dispatchEvent(new OverlaysChangedEvent(this.overlays));
    }

    public removeOverlay(overlay: Overlay){
        var index = this.overlays.indexOf(overlay);
        if (index !== -1) this.overlays.splice(index, 1);
        this.dispatchEvent(new OverlaysChangedEvent(this.overlays));
    }

    public getOverlays(): Overlay[]{
        return this.overlays;
    }
}
