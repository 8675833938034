//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

/*
Thew initialization service initializes a few constants, such as the tileserver URL (which it gets from the location of the site) and the tilesize (which it loads from the server)
It serves as somewhat of a settings service as well, but that usage should be replaced with a real settings service which uses localstorage or the like
*/

import {CameraPosition, PositionService} from './PositionService';
import {Barrier} from '../modules/barrier';
import { GLBarrier, ServiceBarrier, Services } from './Services';
import { RenderLayerFactory, SerializedRenderLayer } from '../modules/RenderLayer';
import { AwaitableDelay } from '../modules/awaitable_delay';
import { DialogBox, SerializedDialog } from './DialogBoxService';
import { Parameter } from '../modules/Parameter';
import { Vec2, Vec3 } from '../modules/vecmat';
import { UEC } from '@/modules/tile';

export type InitialLayerConfig = {
    timerange: [number, number],
    selectedLayerName: string,
    layers: SerializedRenderLayer[],
    dialogs: SerializedDialog[],
    cameraPosition: CameraPosition
};

export class InitializationService extends EventTarget{
    init_barrier: Barrier;

    SERVER_URL = "https://localhost:8080";
    SOURCELIST_URL = "sourcelist";
    STATUSLIST_URL = "statuslist";
    SOURCEINFO_URL = "source_info";
    INITIAL_LAYERS_URL = "initial_layers";
    TILESIZE_URL = "tilesize";
    SAMPLE_URL = "sample";
    VERSIONS_URL = "versions";
    PRELOAD_ALL = false;
    PRODUCTION = true;
    LOD = true;

    FOV_LR = 61;
    FOV_UD = 49;

    DOME_FRUSTRA = {
        "frustra": [{
            "name": "default",
            "fov": {
                "up": 45,
                "left": 45,
                "right": 45,
                "down": 45
            },
            "orientation": {
                "heading": 0,
                "pitch": 0,
                "roll": 0
            }
        }],
        "active": 0
    };

    public SceneName: string = "";

    private tilesize = [512,512];

    private versionInfo: {attribute: string, version: string}[]= [];

    constructor(positionService: PositionService){
        super();
        ServiceBarrier.wait().then(() => {

            let ui_visible = new Parameter("UIVisible", true, "boolean", false);
            Services.SettingsService.initializeSetting(ui_visible);

            let dome_mode = new Parameter("DomeMode", "normal", "string", false);
            Services.SettingsService.initializeSetting(dome_mode);

            let camera_rotation_offset = new Parameter("CameraRotationOffset", Vec3.empty(), "vector3D", false);
            Services.SettingsService.initializeSetting(camera_rotation_offset);

            let clearcolor = new Parameter("ClearColor", new Vec3(0,0,0), "vector3D", false);
            Services.SettingsService.initializeSetting(clearcolor);

            let fontsize = new Parameter("FontSize", 11, "number", false);
            fontsize.setRange(7,21);
            fontsize.setStep(1);
            Services.SettingsService.initializeSetting(fontsize);


            let displacement_scale = new Parameter("Exaggeration", 10.0, "number", false);
            displacement_scale.setShaderName("displacement_scale");
            Services.SettingsService.initializeSetting(displacement_scale);

            let light_direction = new Parameter("Light Direction", new Vec3(1.0, 1.0, 1.0), "vector3D", true);
            light_direction.shader_name = "light_direction";
            Services.SettingsService.initializeSetting(light_direction);

            let synchronized = new Parameter("Synchronized", false, "boolean", false);
            Services.SettingsService.initializeSetting(synchronized);
            Services.SettingsService.getSetting("Synchronized").value = false;          
            
            Services.SettingsService.initializeSetting(new Parameter("DomeTilt", 0, "number"));


            window["Services"] = Services;
        });
        this.SERVER_URL = window.location.origin; 
        this.init_barrier = new Barrier();
        this.loadVersionInfo();
        positionService.setCameraPosition({
            Latitude: 57.31,
            Longitude: 3.48,
            Distance: 0.148,
            Azimuth: 0,
            Elevation: 5,
            VerticalPosition: 1
        });

        if(localStorage.getItem("dome_frustra")){
            this.DOME_FRUSTRA = JSON.parse(localStorage.getItem("dome_frustra"));
            console.log("loaded " + this.DOME_FRUSTRA.frustra.length + " frustra (active=" + this.DOME_FRUSTRA.frustra[this.DOME_FRUSTRA.active].name + ")");
        }

        window.addEventListener("beforeunload", () => {
            localStorage.setItem("dome_frustra", JSON.stringify(this.DOME_FRUSTRA, null, 3));
        });

        this.refresh_tile_size().then(() => {});
        this.init_barrier.resolve();

        GLBarrier.wait().then(async () => {
            await (AwaitableDelay(3000));
            console.log("Rendersize: " , Services.RenderService.width, Services.RenderService.height);
            Services.AdaptivePerformanceService.RequestRerender();
        });

        /*GLBarrier.wait().then(async () => {
            await (AwaitableDelay(1000));
            console.log("Resizing");
            Services.RenderService.resized();
        });*/
    }

    getTileWidth(): number{
        return this.tilesize[0];
    }

    getTileHeight(): number{
        return this.tilesize[1];
    }

    getVersionInfo(){
        return this.versionInfo;
    }

    async loadVersionInfo(){
        this.versionInfo = [];
        let versions = await fetch(this.SERVER_URL + "/" + this.VERSIONS_URL).then(r => r.json());
        for(let key of Object.keys(versions)){
            this.versionInfo.push({attribute: key, version: versions[key]});
        }
        this.versionInfo.sort((a,b) => {
            if(a.attribute == "gitversion"){
                return -1;
            }else{
                if(a.attribute < b.attribute){
                    return -1;
                }else if(a.attribute > b.attribute){
                    return 1;
                }else{
                    return 0;
                }
            }
        });
    }

    async refresh_tile_size(){
        this.tilesize = await fetch(this.SERVER_URL + "/" + this.TILESIZE_URL).then(r => r.json());
    }

    async wait_for_layers_to_be_available(paths: string[]){
        while(paths.some(p => !Services.SourceInfoService.get_source_layer_info_by_path(p))){
            //console.log("Still waiting for layers: ", paths.filter(p => !Services.SourceInfoService.get_source_layer_info_by_path(p)));
            await AwaitableDelay(1000);
            await Services.SourceInfoService.refresh_status_entries();
        }
    }

    async initialize(){
        let search_str = window.location.search.substr(1);
        let hash_str = window.location.hash.substr(1);

        this.SceneName = search_str;
        if(this.SceneName != ""){
            try{
                let ilc = await fetch(`scene/${encodeURIComponent(this.SceneName)}`).then(r => r.json())
                this.load_initial_layers(ilc);
            }catch(e){
                console.error(e.message);
                await this.load_initial_layers();
            }
        }else{
            this.SceneName = "default";
            await this.load_initial_layers();
        }

        if(hash_str != ""){
            let parts = hash_str.split("&");
            for(let p of parts){
                let [key, raw_value] = p.split("=");
                key = decodeURIComponent(key);
                let value = decodeURIComponent(raw_value);
                let setting = Services.SettingsService.getSetting(key);
                if(setting){
                    switch(setting.type){
                        case 'boolean':
                            let bool = value == "true";
                            setting.value = bool;
                        break;
                        case 'vector2D':
                            let v2= JSON.parse(value) as number[];
                            setting.value = new Vec2(v2[0], v2[1]);
                            break;
                        case 'vector3D':
                            let v3 = JSON.parse(value) as number[];
                            setting.value = new Vec3(v3[0], v3[1], v3[2]);
                            break;
                        case 'number':
                            let num = parseFloat(value);
                            setting.value = num;
                            break;
                        case 'string':
                        case 'colormap':
                            let str = value as string;
                            setting.value = str;
                            break;
                    }
                }
            }
        }

        if(Services.SettingsService.getValueOrDefault("Synchronized", false) == true){
            Services.SynchronizationService.tryStart();
            console.log("Synchronized");
        }else{
            console.log("Not synchronized");
        }
    }

    async load_initial_layers(config: InitialLayerConfig = null){
        //Load config
        if(config == null){
            try{
                //Try to get the server config
                config = await fetch(Services.InitializationService.SERVER_URL + "/" + Services.InitializationService.INITIAL_LAYERS_URL).then(r => r.json());
            }catch{
                // No initial layers, just create the standard layers
                config = await fetch("default_initial_layers.json").then(r => r.json());
            }
        }
       
        
        try{
        //Extract layer paths and wait for the sources to become available
        let paths = config.layers.flatMap(l => l.slots.map(s => s.path)).filter(s => !!s);
        await this.wait_for_layers_to_be_available(paths);

        //Clear existing layers
        let layers = Services.RenderLayerService.getLayers();
        for (let l of layers){
            Services.RenderLayerService.deleteLayer(l);
        }

        //Create the layers from the config
        for(var layer of config.layers){
            let new_layer = RenderLayerFactory.fromSerializedObject(layer);
            Services.RenderLayerService.addLayer(new_layer);
            //If the layer was previously selected, select it now
            if(new_layer.name == config.selectedLayerName){
                Services.RenderLayerService.selectLayer(new_layer);
            }
            if(layer.overlays)for(let o of layer.overlays){
                Services.OverlayService.addOverlay(new UEC(o.location[0], o.location[1]), o.location[2], new_layer, o.information);
            }

        }

        if(config.dialogs)
            for(var dialog of config.dialogs){
                Services.DialogBoxService.insert(dialog.name, DialogBox.fromSerialized(dialog))
            }

        //Set the camera position
        Services.PositionService.setCameraPosition(config.cameraPosition);
        //Set the time range
        Services.TimeService.setCurrentTimeRange(config.timerange[0], config.timerange[1]);
        }catch(e){
            console.error("Error loading initial layers", e);
            await this.load_initial_layers(await fetch("default_initial_layers.json").then(r => r.json()));
        }
    }

    getInitialLayerConfig(): InitialLayerConfig{
        return {
            cameraPosition: Services.PositionService.getCameraPosition(),
            layers: Services.RenderLayerService.getLayers().map(l => l.serialize()),
            dialogs: Services.DialogBoxService.serialize(),
            selectedLayerName: Services.RenderLayerService.selected_layer.name,
            timerange: Services.TimeService.getCurrentTimeRange(),
        }
    }
}