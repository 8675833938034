


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import { Services } from '../services/Services';
@Component({
    components: {
    },
    props: {
    }
})
export default class LoadingIndicator extends Vue{
    ratio = [0.0, 0.0];
    label_text = "100.0%";
    loading = false;

    async mounted() {
        Services.GLService.addEventListener("FrameDone", (e) => {
            this.update_ratio();
            this.update_label_text();
        })
    }

    update_ratio() {
        this.ratio = Services.TileCacheService.ratio;
        this.loading = Services.TileCacheService.loading;
    }

    update_label_text() {
        this.label_text = FormattingService.num_to_string_unit(this.ratio[0] / this.ratio[1] * 100, "%", 0)
    }
}
