export class DebugEvent extends Event{
    public debugMessage: string;
    constructor(text: string){
        super("Debug");
        this.debugMessage = text;
    }
}


export class DebugService extends EventTarget{
    public debug_text: string = "";
    constructor(){
        super();
    }

    putDebug(s: string){
        this.debug_text += s + "\n";
    }

    commitDebug(){
        this.dispatchEvent(new DebugEvent(this.debug_text));
        this.debug_text = "";
    }
}