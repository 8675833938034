//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

export class DialogBoxEvent extends Event{
    public eventType: "open" | "close";
    public box: DialogBox;
    public arguments: any;
    public id: string;
    constructor(mode, id, popup, args?){
        super({
            "open": "DialogBoxOpen",
            "close": "DialogBoxClose",
            "focus": "DialogBoxFocus"
        }[mode]);
        this.eventType = mode;
        this.box = popup;
        this.arguments = args;
        this.id = id;
    }
}

export class SerializedDialog{
    name: String;

    pos_x: number;
    pos_y: number;
    pos_z: number;

    contained_component: string;
    reference_transform: string;
    
    title: string;
    size_x: number;
    size_y: number;
}

export class DialogBox {
    public pos_x: number;
    public pos_y: number;
    public pos_z: number;

    public containedComponent: string;
    public referenceTransform: string;

    public title: string;

    public size_x: number;
    public size_y: number;

    public data: Object;

    constructor(component: string, x: number, y: number, title: string, anchor?: string, data?: Object) {
        this.referenceTransform = anchor || "translate(0%, 0%)";
        this.pos_x = x / window.innerWidth * 100;
        this.pos_y = y / window.innerHeight * 100;
        this.pos_z = 0;
        this.containedComponent = component;
        this.title = title;
        this.data = data;
    }

    static centered(component: string, title, data?: Object): DialogBox {
        return new DialogBox(component, window.innerWidth / 2, window.innerHeight / 2, title, "translate(-50%, -50%)", data);
    }

    static fromSerialized(s: SerializedDialog): DialogBox {
        return Object.assign(DialogBox.centered("", ""), {
            pos_x: s.pos_x,
            pos_y: s.pos_y,
            pos_z: s.pos_z,

            containedComponent: s.contained_component,
            referenceTransform: s.reference_transform,

            title: s.title,

            size_x: s.size_x,
            size_y: s.size_y
        });

    }

    serialize(): SerializedDialog {
        let s = new SerializedDialog();
        s.pos_x = this.pos_x;
        s.pos_y = this.pos_y;
        s.pos_z = this.pos_z;
        s.contained_component = this.containedComponent;
        s.reference_transform = this.referenceTransform;
        s.title = this.title;
        s.size_x = this.size_x;
        s.size_y = this.size_y;
        if(this.data){
            console.warn(`Not serializing data field for Dialog: ${this.title}`);
            console.warn(this.data);
        }
        return s;
    }
}

export enum DraggingOrientation {
    Left = 1,
    Right = 2,
    Up = 4,
    Down = 8,
}

export class DialogBoxService extends EventTarget{
    public dialogBoxes: Map<String, DialogBox>;

    constructor(){
        super();
        this.dialogBoxes = new Map();
    }

    insert(id: String, box: DialogBox, eventArgs?: any): boolean {
        box.pos_z = this.dialogBoxes.size;
        if(this.dialogBoxes.has(id)){
            let r = this.dialogBoxes.get(id);
            this.dialogBoxes.set(id, box);
            this.dispatchEvent(new DialogBoxEvent("close", id, r));
            this.dispatchEvent(new DialogBoxEvent("open", id, box, eventArgs));
        } else {
            this.dialogBoxes.set(id, box);
            this.dispatchEvent(new DialogBoxEvent("open", id, box, eventArgs));
        }
        return true;
    }
    
    try_insert(id: String, box: DialogBox, eventArgs?: any): boolean {
        if(this.dialogBoxes.has(id)){
            return false;
        }
        box.pos_z = this.dialogBoxes.size;
        this.dialogBoxes.set(id, box);
        this.dispatchEvent(new DialogBoxEvent("open", id, box, eventArgs));
        return true;
    }

    remove(id: String, eventArgs?: any): boolean {
        if(this.dialogBoxes.has(id)){
            let r = this.dialogBoxes.get(id);
            for(let b of this.dialogBoxes.values()) {
                if(b.pos_z > r.pos_z)
                    b.pos_z -= 1;
            }
            this.dialogBoxes.delete(id);
            this.dispatchEvent(new DialogBoxEvent("close", id, r, eventArgs));
            return true;
        }
        return false;
    }

    focus(id: String, eventArgs?: any): boolean {
        if(this.dialogBoxes.has(id)){
            let update_above = this.dialogBoxes.get(id).pos_z;
            for (let b of this.dialogBoxes.values()) {
                if(b.pos_z > update_above)
                    b.pos_z -= 1;
            }
            this.dialogBoxes.get(id).pos_z = this.dialogBoxes.size - 1;
            this.dispatchEvent(new DialogBoxEvent("focus", id, this.dialogBoxes.get(id), eventArgs));
            return true;
        }
        return false;
    }

    serialize(): SerializedDialog[] {
        let r = []
        this.dialogBoxes.forEach((box, name) => {
            if(name=="Settings")return;
            let tmp = box.serialize();
            tmp.name = name;
            r.push(tmp);
        });
        return r;
    }

}