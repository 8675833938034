




























































































































import Vue from 'vue'
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
import { DialogBox } from '../services/DialogBoxService';
import { SourceLayerInfo } from '../services/SourceInfoService';
import { RenderLayerFactory } from '../modules/RenderLayer';

@Component ({
    components: {
        SvgIcon
    }
})
export default class TypeSelectionDialog extends Vue{
    mounted(){
    }


    add_layer(type){
        let data = {sourceSelectionCallback: (_: SourceLayerInfo) => {console.error("Unimplemented layer type.")}, typeFilter: ""};
        let title = "Select Source";
        switch(type){
            case "scalar":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createColormapScalarLayer(sli);                            
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarTiles"};
            break;
            case "image":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createImageLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ColorTiles"};
            break;
            case "points":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {                            
                    let new_layer = RenderLayerFactory.createPointsLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarPoints"};
            break;
            case "lines":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createLinesLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "ScalarLines"};
            break;
            case "vec2points":
                data = {sourceSelectionCallback: (sli: SourceLayerInfo) => {
                    let new_layer = RenderLayerFactory.createVec2PointsLayer(sli);
                    Services.RenderLayerService.addLayer(new_layer);
                    Services.RenderLayerService.selectLayer(new_layer);
                }, typeFilter: "Vector2DPoints"};
            break;
            case "vector2":
                data = {sourceSelectionCallback: (sli1: SourceLayerInfo) => {
                    let sli2;
                    if(sli1.layer_name.includes("U")){
                        let other_sli_name = sli1.layer_name.replace("U", "V");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    } else if (sli1.layer_name.includes("u")){
                        let other_sli_name = sli1.layer_name.replace("u", "v");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    }
                    if(sli2){
                        let new_layer = RenderLayerFactory.createVector2Layer(sli1, sli2);
                        Services.RenderLayerService.addLayer(new_layer);
                        Services.RenderLayerService.selectLayer(new_layer);
                        return;
                    }
                    Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", "Select Meridional Velocity Layer", {
                        sourceSelectionCallback: (sli2: SourceLayerInfo) => {
                            let new_layer = RenderLayerFactory.createVector2Layer(sli1, sli2);
                            Services.RenderLayerService.addLayer(new_layer);
                            Services.RenderLayerService.selectLayer(new_layer);
                        }, typeFilter: "ScalarTiles"}));
                    }, typeFilter: "ScalarTiles"};
                title = "Select Zonal Velocity Layer"
            break;
            case "vector2arrow":
                data = {sourceSelectionCallback: (sli1: SourceLayerInfo) => {
                    let sli2;
                    if(sli1.layer_name.includes("U")){
                        let other_sli_name = sli1.layer_name.replace("U", "V");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    } else if (sli1.layer_name.includes("u")){
                        let other_sli_name = sli1.layer_name.replace("u", "v");
                        sli2 = Services.SourceInfoService.get_all_source_layer_infos().filter(s => s.instance_name == sli1.instance_name && s.layer_name == other_sli_name)[0];
                    }
                    if(sli2){
                        let new_layer = RenderLayerFactory.createVector2ArrowLayer(sli1, sli2);
                        Services.RenderLayerService.addLayer(new_layer);
                        Services.RenderLayerService.selectLayer(new_layer);
                        return;
                    }
                    Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", "Select Meridional Velocity Layer", {
                        sourceSelectionCallback: (sli2: SourceLayerInfo) => {
                            let new_layer = RenderLayerFactory.createVector2ArrowLayer(sli1, sli2);
                            Services.RenderLayerService.addLayer(new_layer);
                            Services.RenderLayerService.selectLayer(new_layer);
                        }, typeFilter: "ScalarTiles"}));
                    }, typeFilter: "ScalarTiles"};
                title = "Select Zonal Velocity Layer"
            break;
            case "difference":
                data = {sourceSelectionCallback: (sli1: SourceLayerInfo) => {
                    Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", "Select Offset Value Layer", {
                    sourceSelectionCallback: (sli2: SourceLayerInfo) => {
                        let new_layer = RenderLayerFactory.createColormapDifferenceLayer(sli1, sli2);
                        Services.RenderLayerService.addLayer(new_layer);
                        Services.RenderLayerService.selectLayer(new_layer);
                        }, typeFilter: "ScalarTiles"}));
                    }, typeFilter: "ScalarTiles"};
                title = "Select Base Value Layer";
            break;
            default:
                throw("Unimplemented.");
            break;
        }
        Services.DialogBoxService.remove("New Layer");
        Services.DialogBoxService.insert("Select Source", DialogBox.centered("SourceSelectionDialog", title, data));
    }

}
