
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import { CameraPositionChangedEvent } from '../services/PositionService';
import { MouseMoveEvent } from '../services/InteractionService';
import { FormattingService } from '../services/FormattingService';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    components: {
        SvgIcon
    }
})
export default class Compass extends Vue{
    private angleZ = 0;
    private angleX = 0;
    private coordstring = "";
    private valuestring = "";
    private heightstring = "";
    private valid_coords = false;
    private rotation_locked = true;

    compass_click(e){
        let pos = Services.PositionService.getCameraPosition();
        if(e.ctrlKey){
            pos.Elevation = 90;
        }
        pos.Azimuth = 0;
        Services.PositionService.setCameraPosition(pos);
        Services.InteractionService.mousedown("compass");
    }

    toggle_rotation_lock(e){
        this.rotation_locked = !this.rotation_locked;
        Services.PositionService.rotation_locked = this.rotation_locked;
    }

    async mounted(){
        Services.PositionService.addEventListener("CameraPositionChanged", (e: CameraPositionChangedEvent) => {
            this.angleZ = e.position.Azimuth;
            this.angleX = 60 - (e.position.Elevation / 3 * 2);
            }
        );
    Services.PositionService.addEventListener("RotationLockChanged", () => {
        this.rotation_locked = Services.PositionService.rotation_locked;
    });

        Services.InteractionService.addEventListener("MouseMove", (e: MouseMoveEvent) => {
            this.updateInspectLabel();
            this.valid_coords = Services.LayerMetadataService.isUpToDate;
        });
        this.updateInspectLabel();
       
    }

    updateInspectLabel(){
        this.coordstring = FormattingService.UEC_to_coord_string(Services.LayerMetadataService.coord, 3);
            let value = null;

            let filter;
            if(Services.RenderLayerService.selected_layer && (filter =Services.RenderLayerService.selected_layer.filterPipeline.find(x => x.name == 'colormap'))){
                let v = Services.LayerMetadataService.value.x1;
                let decs = FormattingService.decimals_for_range(filter.parameters["colormap_min"].value, filter.parameters["colormap_max"].value);
                if (filter.parameters["colormap_max"].unit) {
                    let unit = filter.parameters["colormap_max"].unit;
                    value = FormattingService.num_to_string_unit(v, unit, decs);
                } else {
                    value = FormattingService.number_to_string(v, decs);
                }
            }

            this.heightstring = FormattingService.number_to_string(Services.LayerMetadataService.z,2) + "m";

            if(value){
                this.valuestring = value;
            }
    }
}
