//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import {RenderLayer, RenderLayerFactory} from '../modules/RenderLayer';
import { SourceLayerInfo } from './SourceInfoService';

export class SelectedLayerChangedEvent extends Event{
    selectedLayer: RenderLayer;
    constructor(layer: RenderLayer){
        super("SelectedLayerChanged");
        this.selectedLayer = layer;
    }
}

export class LayersChangedEvent extends Event{
    layers: RenderLayer[];
    new_layer: RenderLayer;
    changed_layer: RenderLayer;
    deleted_layer: RenderLayer;
    constructor(layers: RenderLayer[]){
        super("LayersChanged");
        this.layers = layers;
    }
}

export class RenderLayerService extends EventTarget{
    public old_layers: RenderLayer[] = [];
    public layers: RenderLayer[] = [];
    public selected_layer_id: number = null;
    public selected_layer: RenderLayer = null;

    private vertical_bounds_world_space: [number, number] = [1, 1];

    constructor(){
        super();
    }

    private next_layer_id = 1;

    public getNextId(): number{
        let next_id = this.next_layer_id;
        this.next_layer_id ++;
        return next_id;
    }

    public addLayer(layer: RenderLayer){
        if(layer.id == null){
            layer.id = this.getNextId();
        }
        this.layers.push(layer);
        this.dispatchEvent(new LayersChangedEvent(this.layers));
    }

    public replaceLayer(oldLayer: RenderLayer, newLayer: RenderLayer){
        let old_index = this.layers.indexOf(oldLayer);
        let is_selected = this.getSelectedLayer() == oldLayer;
        this.layers[old_index] = newLayer;
        this.dispatchEvent(new LayersChangedEvent(this.layers));
        if(is_selected){
            this.selectLayer(newLayer);
        }
    }

    public selectLayer(layer: RenderLayer){
        this.selected_layer = layer;
        this.selected_layer_id = layer.id;
        this.dispatchEvent(new SelectedLayerChangedEvent(this.selected_layer));
    }
    
    //TODO: calculate actual world space peaks from all layers, ideally only on layer updates
    get_euclidian_global_height_range(): [number, number]{
        let r: [number, number] = this.layers.reduce((c, l) => {
            if(l.visible){
                let r = l.getVerticalBoundsWorldspace();
                return[Math.min(c[0], r[0]), Math.max(c[1], r[1])];
            }
            return c;
        }, [Infinity, -Infinity]);
        if(!isFinite(r[0]) || !isFinite(r[1]))return [0.9, 1.1];
        return r;
    }

    get_visible_renderlayers(): RenderLayer[] {
        return this.layers.filter(x => x.visible);
    }

    getLayers(): RenderLayer[]{
        return this.layers;
    }


    layerDown(layer: RenderLayer){
        let index = this.layerIndex(layer);
        if(index != this.layers.length -1){
            let next_elem = this.layers[index + 1];
            this.layers[index + 1] = layer;
            this.layers[index] = next_elem;
        }
        this.dispatchEvent(new LayersChangedEvent(this.layers));
    }

    layerUp(layer: RenderLayer){
        let index = this.layerIndex(layer);
        if(index != 0){
            let prev_elem = this.layers[index - 1];
            this.layers[index - 1] = layer;
            this.layers[index] = prev_elem;
        }
        this.dispatchEvent(new LayersChangedEvent(this.layers));
    }
   
    isFirstLayer(layer: RenderLayer){
        return this.layerIndex(layer) == 0;
    }

    isLastLayer(layer: RenderLayer){
        return this.layerIndex(layer) == (this.layers.length - 1);
    }

    isSelectedLayer(layer: RenderLayer){
        return layer == this.selected_layer;
    }

    getSelectedLayer(): RenderLayer{
        return this.selected_layer;
    }

    setSourceOnLayer(layer: RenderLayer, source_slot_name: string, source: SourceLayerInfo){
        if(!layer || !source)return;
        //Todo: Serialize layer, change slot path to what is in source, replace layer with deserialized layer.
        let serialized = layer.serialize();
        let slot_index = serialized.slots.findIndex(s => s.slot == source_slot_name);
        if(slot_index >= 0){
            serialized.slots[slot_index].path = source.getPath();
            let deserialized = RenderLayerFactory.fromSerializedObject(serialized);
            //Reset colormap scaling if unit different
            for(let slot of serialized.slots){
                if(deserialized.source.slots[slot.slot].source?.layer?.unit != layer.source.slots[slot.slot].source?.layer?.unit){
                    let colormapfilter = deserialized.filterPipeline.find(f => f.name == "colormap");
                    if(colormapfilter){
                        colormapfilter.parameters["colormap"].executeAction("Reset");
                    }
                }
            }
            this.replaceLayer(layer, deserialized);
        }else{
            //console.log(`Slot ${source_slot_name} does not exist on layer ${layer.name}, options are`, serialized.slots.map(s => s.slot));
        }
    }

    deleteLayer(layer: RenderLayer){
        this.layers = this.layers.filter(l => l != layer);
        if(this.layers.length == 0){
            this.selected_layer = null;
            this.selected_layer_id = null;
            this.dispatchEvent(new SelectedLayerChangedEvent(null));
        } else if(this.selected_layer.id == layer.id){
            this.selected_layer = this.layers[0];
            this.selected_layer_id = 0;
            this.dispatchEvent(new SelectedLayerChangedEvent(this.selected_layer));
        }
        this.dispatchEvent(new LayersChangedEvent(this.layers));
    }

    layerIndex(layer){
        return this.layers.indexOf(layer);
    }

    get_layer_by_id(id){
        return this.layers.find(l => l.id == id);
    }
}
