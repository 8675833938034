//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

/*
The adaptive performance service offers facilities to measure FPS and based on the measurement, suggest a new render size.
This was initially though to improve performance on lower end devices, but ended up jumping around wildly from resolution to resolution.
The new version of this service (as seen below) just takes as input one of the values from RESOLUTION_SCALES and recommends the scaled down canvas size.
*/


import { Parameter } from "../modules/Parameter";
import { Services, ServiceBarrier } from "./Services";

export class AdaptivePerformanceService{
    //Old constants
    FPS_LOWER_BOUND: number = 20; // Reduce resolution if FPS drops below this value
    FPS_UPPER_BOUND: number = 50; // Increase resolution if FPS rises above this value
    FPS_MEASUREMENTS: number = 1000; //Amout of measurements which are integrated
    MIN_HEIGHT: number = 360; //Do not go below this number of pixels for height

    LOADING_TIMEOUT: number = 2100;  //Do not resize in the fist LOADING_TIMEOUT ms
    RESOLUTION_SCALES = [1, 0.75, 0.66, 0.5, 0.33, 0.25]; //The scaling values allowed for the canvas
    FORCED_RERENDER_INTERVAL = 500; //How often to render a frame regardless of whether we would need to. This helps with tile loading

    //Internal flag, this is false for the LOADING_TIMEOUT so the application can spool up the graphics system without interruption
    private mayResize: boolean = false;

    //The actual size of the canvas element
    private clientWidth: number = 1;
    private clientHeight: number = 1;

    //The render size of the canvas element
    private currentWidth: number = 1;
    private currentHeight: number = 1;
    private aspectRatio: number = 1;

    //A series of measurements to be averaged together
    private measurements: number[] = [];
    //The current measurement timekeeping slot
    private current_measurement: number = performance.now();
    private currently_measuring: boolean = false;
    //The current FPS measurement, updated on EndMeasurement
    private currentFPS: number = (this.FPS_LOWER_BOUND + this.FPS_UPPER_BOUND) / 2;

    private currentFPSLowWarningSent: boolean = false;

    private needsRerender: boolean = true;

    private is_firefox = navigator.userAgent.indexOf("Firefox") != - 1;

    public constructor(){
        setTimeout(() => this.mayResize = true, this.LOADING_TIMEOUT);
        ServiceBarrier.wait().then(() => {
            Services.SettingsService.initializeSetting(new Parameter("AlwaysRerender", false, "boolean"));
            Services.SettingsService.initializeSetting(new Parameter("ScalingFactor", 1.0, "number"));
            window.addEventListener("resize", () => {this.RequestRerender()});
            Services.RenderLayerService.addEventListener("LayersChanged", () => {
                this.RequestRerender();
            })});
    }

    private recommendSize(){
        this.currentHeight = this.clientHeight * Services.SettingsService.getSetting("ScalingFactor").value;
        this.currentWidth = this.clientWidth * Services.SettingsService.getSetting("ScalingFactor").value;
    }

    //Call this to start a FPS measurement
    public StartMeasurement(){
        this.current_measurement = performance.now();
        this.currently_measuring = true;
    }

    //Call this to end a FPS measurement
    public EndMeasurement(){
        if(!this.currently_measuring){
            return;
        }
        this.currently_measuring = false;
        let time = performance.now() - this.current_measurement;
        this.measurements.push(time);
        while(this.measurements.length > this.FPS_MEASUREMENTS){
            this.measurements.shift();
        }
        let time_sum = this.measurements.reduce((a,b) => a + b);
        let time_average = time_sum / this.measurements.length;
        this.currentFPS = 1000 / time_average;
        if(this.mayResize){
            this.recommendSize();
        }
    }

    //Call this if the canvas size changes
    public SetClientDimensions(width: number, height: number){
        if(this.clientHeight < 10){ //Set initial dimensions
            this.currentHeight = height;
            this.currentWidth = width;
        }
        //Set client bounds
        this.clientHeight = height;
        this.clientWidth = width;
        //Set aspect ratio
        this.aspectRatio = width / height;
        //Resize the viewport if new client bounds are smaller than viewport
        this.currentHeight = Math.min(this.clientHeight, this.currentHeight);
        this.currentWidth = this.currentHeight * this.aspectRatio;
    }

    // Returns a good size for the canvas
    public GetRecommendedDimensions(): [number, number]{
        return [this.currentWidth, this.currentHeight];
    }

    //Returns the last FPS measurement
    public GetFPS(){
        return this.currentFPS;
    }

    //Sets the rerender flag
    public RequestRerender(){
        this.needsRerender = true;
    }

    // This returns true if the canvas needs to be rerendererd (or always in the first couple of seconds) and resets the rerender flag
    public NeedsRerender(){
        let needsRerender = this.needsRerender;
        this.needsRerender = false;
        return needsRerender || (!this.mayResize) || Services.SettingsService.getSetting("AlwaysRerender").value;
    }

    public isFirefox(){
        return this.is_firefox;
    }

}