//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Vec2, Vec3, Vec4, Mat3, Mat4, Quaternion } from '../modules/vecmat';
import { Services } from './Services';
import {UEC, Coord} from '../modules/tile';
import { FlexibleTimeBaseService } from './FlexibleTimeBaseService';

export interface CameraPosition{
        Latitude: number;
        Longitude: number;
        Distance: number;
        Azimuth: number;
        Elevation: number;
        VerticalPosition: number;
}

type AnimPolynomial = [number, number, number, number, number];

export class ScreenPosition{
    x: number;
    y: number;
    z: number;
    constructor(x: number, y: number, z: number){
        this.x = x;
        this.y = y;
        this.z = z;
    }

    public toVec3(){
        return new Vec3(this.x, this.y, this.z);
    }

    public static fromVec3(v: Vec3){
        return new ScreenPosition(v.x1, v.x2, v.x3);
    }
}

export class CameraPositionChangedEvent extends Event{
    position: CameraPosition;
    constructor(pos: CameraPosition){
        super("CameraPositionChanged");
        this.position = pos;
    }
}

export class CameraPositionTargetChangedEvent extends Event{
    position: CameraPosition;
    constructor(pos: CameraPosition){
        super("CameraPositionTargetChanged");
        this.position = pos;
    }
}

export class DisplayParametersChangedEvent extends Event{
    constructor(){
        super("DisplayParameterChanged");
    }
}

export class RotationLockChangedEvent extends Event{
    constructor(){
        super("RotationLockChanged");
    }
}

const ELEV_ANG_MIN = 0;
const ELEV_ANG_MAX = Math.PI / 2;
const ANIM_SPEED = 1.5;
const ZOOM_IN_LIMIT = 1e-10;
const ZOOM_OUT_LIMIT = 10;

const SPHERICAL_LIMIT = 0.005;
const POLAR_LIMIT = 0.1;

const CAMERA_FOV = 45;

export class PositionService extends EventTarget{
    animStart: number;

    projection_mode: "EQUIRECT" | "SPHERE" | "POLAR";

    world_rotation: Quaternion;
    world_rotation_pw: AnimPolynomial;
    world_rotation_px: AnimPolynomial;
    world_rotation_py: AnimPolynomial;
    world_rotation_pz: AnimPolynomial;
    world_rotation_filtered: Quaternion;

    camera_elevation: number;
    camera_elevation_p: AnimPolynomial;
    camera_elevation_filtered: number;

    camera_distance: number;
    camera_distance_p: AnimPolynomial;
    camera_distance_filtered: number;

    camera_zposition: number;
    camera_zposition_p: AnimPolynomial;
    camera_zposition_filtered: number;

    world_transform: Mat4 = Mat4.identity();

    camera_transform: Mat4 = Mat4.identity();

    private display_width: number;
    private display_height: number;

    mouse_position: UEC;
    mouse_height: number;

    public nearDistance: number;
    public farDistance: number;

    private rotation_locked_interal: boolean = true;
    public get rotation_locked(){
        return this.rotation_locked_interal;
    }

    public set rotation_locked(value: boolean){
        if(value != this.rotation_locked_interal){
            this.rotation_locked_interal = value;
            this.dispatchEvent(new RotationLockChangedEvent());
        }
    }

    timebaseservice: FlexibleTimeBaseService = null;

    constructor(tbs: FlexibleTimeBaseService){
        super();
        this.timebaseservice = tbs;
    }

    //Ported
    public filterUpdate() {
        let animT = ((this.timebaseservice.now() / 1000) - this.animStart) * ANIM_SPEED;

        this.world_rotation_filtered = (new Quaternion(
            this.evalAnimPolynomial(this.world_rotation_pw, animT),
            this.evalAnimPolynomial(this.world_rotation_px, animT),
            this.evalAnimPolynomial(this.world_rotation_py, animT),
            this.evalAnimPolynomial(this.world_rotation_pz, animT)
        )).normalize();
        this.camera_elevation_filtered = this.evalAnimPolynomial(this.camera_elevation_p, animT);
        this.camera_distance_filtered = this.evalAnimPolynomial(this.camera_distance_p, animT);
        this.camera_zposition_filtered = this.evalAnimPolynomial(this.camera_zposition_p, animT);
        
        this.recalculate_matrices();
        this.dispatchEvent(new CameraPositionChangedEvent(this.getCameraPositionFiltered()));
        if(animT <= 2)
            Services.AdaptivePerformanceService.RequestRerender();
    }

    //Ported
    public forceFilteredToTarget() {
        this.animStart = 0;
        this.world_rotation_filtered = this.world_rotation;
        this.camera_distance_filtered = this.camera_distance;
        this.camera_elevation_filtered = this.camera_elevation;
        this.camera_zposition_filtered = this.camera_zposition;
        this.recalculate_matrices();
    }

    update_projection_mode() {
        if(this.camera_distance_filtered > SPHERICAL_LIMIT)
            this.projection_mode = "SPHERE";
        //else if (true) // check the polar limit here by scalar product of transformed polar axis and viewer z axis
        //   this.projection_mode = "POLAR";
        else 
            this.projection_mode = "EQUIRECT";
    }

    recalculate_world_transform() {
        let pos;
        let scaling_mat = Mat3.identity().mul_number(1/this.camera_distance_filtered).extend();
        let rotmat;
        switch (this.projection_mode) {
            case "EQUIRECT":
                //calculate latlon
                pos = this.getCameraPositionFiltered();
                let uecpos = UEC.from_Coord(new Coord(pos.Latitude, pos.Longitude));
                //move
                let offsetmat = Mat3.identity().translate(new Vec3(-uecpos.x, -uecpos.y, 0.0));
                let xy_correction_mat = new Mat4( - Math.PI * 2 * Math.cos(pos.Latitude / 180 * Math.PI), 0, 0, 0, 0, Math.PI, 0, 0,0, 0, 1, 0, 0,0,0,1); 
                //rotate
                rotmat = Mat3.rot((90 - pos.Azimuth) / 180 * Math.PI, new Vec3(0, 0, 1)).extend();
                //scale
                this.world_transform = scaling_mat.mul_mat4(rotmat).mul_mat4(xy_correction_mat.mul_mat4(offsetmat));
                this.UECHeightToScreenPixels = this.UECHeightToScreenPixels_equirect;
                return;
            case "POLAR":
                pos = this.world_rot_to_uec_pos(this.world_rotation);
                if(pos.y > 0.5) { //South Pole

                } else {

                }
                //calculate latlon
                
                //move

                //rotate

                //scale

                return;
            default:
                //turn
                rotmat = this.world_rotation_filtered.to_rotation_matrix();
                //move down
                let tmat = rotmat.translate(new Vec3(0, 0, -1));
                //scale
                this.world_transform = scaling_mat.mul_mat4(tmat);
                this.UECHeightToScreenPixels = this.UECHeightToScreenPixels_spherical;
                return;
        }
    }

    //Y to the right,
    //X to the camera heading,
    //Z up in the camera view
    public recalculate_camera_transform() {
        let perspective = Mat4.perspective(
            CAMERA_FOV / 180 * Math.PI,            
            this.display_width / this.display_height,
            0.1,
            2.1
            );
        let result = this.calculate_camera_pretransform_internal();
        this.camera_transform = perspective.mul_mat4(result);
    }

    public recalculate_camera_transform_stereo_offset(offset: number) {
        let perspective = Mat4.perspective(
            CAMERA_FOV / 180 * Math.PI,            
            this.display_width / this.display_height,
            0.1,
            2.1
            );
        let result = this.calculate_camera_pretransform_internal();
        let offset_mat = Mat3.rot(Math.atan(offset), new Vec3(0, -1, 0)).translate(new Vec3(offset, 0, 0));
        this.camera_transform = perspective.mul_mat4(offset_mat).mul_mat4(result);
    }

    public recalculate_camera_transform_dome_top(domeTilt: number) {
        let perspective = Mat4.perspective_ext(
            -1, 
            1,
            1,
            -1,
            0.025,
            3
        );
        let result = this.calculate_camera_pretransform_internal();
        this.camera_transform = perspective
            .mul_mat4(
                Mat3.rot(
                    (90 - domeTilt) / 180 * Math.PI,
                    new Vec3(1, 0, 0))
                .extend()
                .mul_mat4(result)
            );
    }

    public recalculate_camera_transform_single_frustum(
        domeTilt: number,
        f: {
            "name": string,
            "fov": {
                "up": number,
                "down": number,
                "left": number,
                "right": number
            },
            "orientation": {
                "heading": number,
                "pitch": number,
                "roll": number
            }
        }
    ) {
        let afc = 2/3;
        let perspective = Mat4.perspective_ext(
            -Math.tan(f.fov.left / 180 * Math.PI),
            Math.tan(f.fov.right / 180 * Math.PI),
            Math.tan(f.fov.up / 180 * Math.PI),
            -Math.tan(f.fov.down / 180 * Math.PI),
            0.025,
            3
        );
        /*let perspective = Mat4.perspective((f.fov.left + f.fov.right) / 180 * Math.PI,
            Math.tan(f.fov.left)
        , 0.025, 3);*/
        let result = this.calculate_camera_pretransform_internal();
        let axes = {
            "NR" :  Mat3.rot(f.orientation.roll / 180 * Math.PI, new Vec3(0, 0, -1)),
            "PR" :  Mat3.rot(f.orientation.roll / 180 * Math.PI, new Vec3(0, 0, 1)),
            "NH" : Mat3.rot(f.orientation.heading / 180 * Math.PI, new Vec3(0, -1, 0)),
            "PH": Mat3.rot(f.orientation.heading / 180 * Math.PI, new Vec3(0, 1, 0)),
            "NP":Mat3.rot(f.orientation.pitch / 180 * Math.PI, new Vec3(1, 0, 0)),
            "PP":Mat3.rot(f.orientation.pitch / 180 * Math.PI, new Vec3(1, 0, 0))
        };
        /*let mt = 
            Mat3.rot(f.orientation.roll / 180 * Math.PI, new Vec3(0, 0, -1)).mul_mat3(
                Mat3.rot(f.orientation.pitch / 180 * Math.PI, new Vec3(1, 0, 0))
            ).mul_mat3(
                Mat3.rot(f.orientation.heading / 180 * Math.PI, new Vec3(0, -1, 0))
            )*/
        
        let orientation_str = "PRPPNH";
        let rot_a = axes[orientation_str.substr(0,2)];
        let rot_b = axes[orientation_str.substr(2,2)];
        let rot_c = axes[orientation_str.substr(4,2)];

        let mt = Mat3.rot(f.orientation.roll / 180 * Math.PI, new Vec3(0, 0, 1))
            .mul_mat3(Mat3.rot(f.orientation.pitch / 180 * Math.PI, new Vec3(1, 0, 0))).mul_mat3(Mat3.rot(f.orientation.heading / 180 * Math.PI, new Vec3(0, -1, 0)));

        this.camera_transform = perspective
        .mul_mat4(mt.extend())
        .mul_mat4(
            Mat3.rot(
                (90 - domeTilt) / 180 * Math.PI,
                new Vec3(1, 0, 0))
            .extend()
            .mul_mat4(result)
        );
    }

    public recalculate_camera_transform_dome_side(domeTilt: number, rot_z: number) {
        let perspective = Mat4.perspective_ext(
            -1, 
            1,
            0,
            -1,
            0.025,
            3
        );
        let mt;
        switch (rot_z) {
            case 1:
                mt = new Mat4(
                    1, 0, 0, 0,
                    0, 0, 1, 0,
                    0, -1, 0, 0,
                    0, 0, 0, 1
                );
                break;
            case 2:
                mt = new Mat4(
                    0, -1, 0, 0,
                    0, 0, 1, 0,
                    -1, 0, 0, 0,
                    0, 0, 0, 1
                );
                break;
            case 3: 
                mt = new Mat4(
                    -1, 0, 0, 0,
                    0, 0, 1, 0,
                    0, 1, 0, 0,
                    0, 0, 0, 1
                );
                break;
            default:
                mt = new Mat4(
                    0, 1, 0, 0,
                    0, 0, 1, 0,
                    1, 0, 0, 0,
                    0, 0, 0, 1
                );
        }
        let result = this.calculate_camera_pretransform_internal();
        this.camera_transform = perspective
            .mul_mat4(mt)
            .mul_mat4(
                Mat3.rot(
                    (90 - domeTilt) / 180 * Math.PI,
                    new Vec3(1, 0, 0))
                .extend()
                .mul_mat4(result)
            );
    }

    calculate_camera_pretransform_internal(): Mat4 {
        let result = Mat3.identity().translate(new Vec3(0, 0, (this.camera_zposition_filtered - 1) * (Services.SettingsService.getValueOrDefault("Exaggeration", 1) / this.camera_distance_filtered)));
        result = Mat3.rot(Math.PI / 2 - this.camera_elevation_filtered, new Vec3(1, 0, 0)).extend().mul_mat4(result);
        result = Mat3.identity().translate(new Vec3(0, 0, 1)).mul_mat4(result);
        return result.mul_mat4(new Mat4(0, -1, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1));
    }

    recalculate_matrices() {
        this.update_projection_mode();
        this.recalculate_camera_transform();
        this.recalculate_world_transform();
    }

    //Ported
    public moveCamera(x: number, y: number) {
        if (x == 0 && y == 0) return;
        const correction_fac = 1.2;
        let pos_old = this.world_rotation;
        let cam_dist = this.camera_distance;
        let yfac = -y / this.display_height * cam_dist * correction_fac;
        let xfac = -x / this.display_width * cam_dist * correction_fac;
        let mixed_axis = new Vec3(-xfac, yfac, 0.0);
        this.world_rotation = Quaternion.from_axis_and_angle(mixed_axis, mixed_axis.abs()).mul(this.world_rotation);
        if(this.rotation_locked){
            let rotmat_old = pos_old.to_rotation_matrix();
            let cam_northing = Math.atan2(rotmat_old.a23, rotmat_old.a13);
            let rotmat_new = this.world_rotation.to_rotation_matrix();
            let cam_northing_new = Math.atan2(rotmat_new.a23, rotmat_new.a13);
            this.world_rotation = Quaternion.from_axis_and_angle(new Vec3(0, 0, 1), - cam_northing_new + cam_northing).mul(this.world_rotation);
        }
        this.updateCameraPolynomials();
        this.dispatchEvent(new CameraPositionTargetChangedEvent(this.getCameraPosition()));
    }

    //Ported
    public tiltCamera(x: number, y: number) {
        if (x == 0 && y == 0) return;
        const correction_fac_az = 2.0;
        const correction_fac_elev = -2.0;
        let yfac = -y / this.display_height * correction_fac_elev;
        let xfac = -x / this.display_width * correction_fac_az;
        this.world_rotation = Quaternion.from_axis_and_angle(new Vec3(0, 0, 1), -xfac).mul(this.world_rotation);
        this.camera_elevation = Math.min(ELEV_ANG_MAX, Math.max(ELEV_ANG_MIN, this.camera_elevation + yfac))
        this.updateCameraPolynomials();
        this.dispatchEvent(new CameraPositionTargetChangedEvent(this.getCameraPosition()));
    }

    public zoomCamera(z: number) {
        this.camera_distance *= Math.exp(
            Math.log(
                1.5
            ) * z
        );
        this.camera_distance = Math.min(ZOOM_OUT_LIMIT, Math.max(ZOOM_IN_LIMIT, this.camera_distance));
        this.updateCameraPolynomials();
        this.dispatchEvent(new CameraPositionTargetChangedEvent(this.getCameraPosition()));
    }

    //Ported
    public setCameraPosition(pos: CameraPosition, external: boolean = false) {
        let oldrot = this.world_rotation;
        this.camera_elevation = pos.Elevation * Math.PI / 180;
        let az = (180 - pos.Azimuth) * Math.PI / 180;
        let lon = pos.Longitude * Math.PI / 180;
        let lat = (90 - pos.Latitude) * Math.PI / 180;
        let rot = Quaternion.from_axis_and_angle(new Vec3(0, 0, 1), -lon);
        rot = Quaternion.from_axis_and_angle(new Vec3(0, 1, 0), -lat).mul(rot);
        let newrot = Quaternion.from_axis_and_angle(new Vec3(0, 0, 1), az).mul(rot);
        this.camera_distance = Math.min(ZOOM_OUT_LIMIT, Math.max(ZOOM_IN_LIMIT, pos.Distance));
        this.camera_zposition = pos.VerticalPosition;

        if(oldrot && oldrot.sub(newrot).length() < oldrot.sub(newrot.neg()).length())
            this.world_rotation = newrot;
        else
            this.world_rotation = newrot.neg();
        
        this.updateCameraPolynomials();
        if(!external){
            this.dispatchEvent(new CameraPositionTargetChangedEvent(pos));
        }
    }

    //Ported
    updateCameraPolynomials(){
        let n = this.timebaseservice.now();        
        let animT = ((n / 1000) - this.animStart) * ANIM_SPEED;
        this.animStart = n / 1000;

        if(this.world_rotation_pw){
            this.world_rotation_pw = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.world_rotation_pw, animT),
                this.evalAnimPolynomialDeriv(this.world_rotation_pw, animT),
                this.world_rotation.w,
                0,
                0
            );        
            this.world_rotation_px = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.world_rotation_px, animT),
                this.evalAnimPolynomialDeriv(this.world_rotation_px, animT),
                this.world_rotation.x,
                0,
                0
            );        
            this.world_rotation_py = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.world_rotation_py, animT),
                this.evalAnimPolynomialDeriv(this.world_rotation_py, animT),
                this.world_rotation.y,
                0,
                0
            );        
            this.world_rotation_pz = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.world_rotation_pz, animT),
                this.evalAnimPolynomialDeriv(this.world_rotation_pz, animT),
                this.world_rotation.z,
                0,
                0
            );       
            this.camera_distance_p = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.camera_distance_p, animT),
                this.evalAnimPolynomialDeriv(this.camera_distance_p, animT),
                this.camera_distance,
                0,
                0
            );
            this.camera_elevation_p = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.camera_elevation_p, animT),
                this.evalAnimPolynomialDeriv(this.camera_elevation_p, animT),
                this.camera_elevation,
                0,
                0
            );
            this.camera_zposition_p = this.genAnimPolynomial(
                this.evalAnimPolynomial(this.camera_zposition_p, animT),
                this.evalAnimPolynomialDeriv(this.camera_zposition_p, animT),
                this.camera_zposition,
                0,
                0
            );
        }else{
            //if camera_position_px isn't set, assume that none are set
            //these polynomials just return the constant value, so their derivatives are 0.
            this.world_rotation_pw = [0, 0, 0, 0, this.world_rotation.w];
            this.world_rotation_px = [0, 0, 0, 0, this.world_rotation.x];
            this.world_rotation_py = [0, 0, 0, 0, this.world_rotation.y];
            this.world_rotation_pz = [0, 0, 0, 0, this.world_rotation.z];
            this.camera_distance_p = [0, 0, 0, 0, this.camera_distance];
            this.camera_elevation_p = [0, 0, 0, 0, this.camera_elevation];
            this.camera_zposition_p = [0, 0, 0, 0, this.camera_zposition];
        }
    }

    world_rot_to_uec_pos(rot: Quaternion): UEC{
        let rotmat = rot.to_rotation_matrix();
        let rot_x = new Vec3(rotmat.a11, rotmat.a21, rotmat.a31);
        let rot_y = new Vec3(rotmat.a12, rotmat.a22, rotmat.a32);
        let rot_z = new Vec3(rotmat.a13, rotmat.a23, rotmat.a33);
        let lat_phi = Math.asin(rot_z.x3);
        let rot_xy = new Vec3(0 - rot_z.x1, 0 - rot_z.x2, 1 - rot_z.x3);
        let lon_phi = Math.atan2(rot_y.dot(rot_xy), rot_x.dot(rot_xy));
        return UEC.from_Coord(new Coord(
            lat_phi / Math.PI * 180,
            lon_phi / Math.PI * 180
        ));
    }

    private calculateCameraPositionInternalRad(rotation: Quaternion, elevation: number, distance: number, zposition: number): CameraPosition {
        let rotmat = rotation.to_rotation_matrix();
        let rot_x = new Vec3(rotmat.a11, rotmat.a21, rotmat.a31);
        let rot_y = new Vec3(rotmat.a12, rotmat.a22, rotmat.a32);
        let rot_z = new Vec3(rotmat.a13, rotmat.a23, rotmat.a33);

        let lat_phi = Math.asin(rot_z.x3);

        let cam_northing = Math.atan2(-rot_z.x2, rot_z.x1);

        let rot_xy = new Vec3(0 - rot_z.x1, 0 - rot_z.x2, 1 - rot_z.x3);

        let lon_phi = Math.atan2(rot_y.dot(rot_xy), rot_x.dot(rot_xy));
        
        return {
            Latitude: lat_phi,
            Longitude: lon_phi,
            Distance: distance,
            Elevation: elevation,
            Azimuth: cam_northing,
            VerticalPosition: zposition
        };
    }

    //probably just move this to the public getters
    calculateCameraPositionInternal(rotation: Quaternion, elevation: number, distance: number, zposition: number): CameraPosition {
        let r = this.calculateCameraPositionInternalRad(rotation, elevation, distance, zposition);
        return {
            Latitude: r.Latitude * 180 / Math.PI,
            Longitude: r.Longitude * 180 / Math.PI,
            Distance: distance,
            Elevation: r.Elevation * 180 / Math.PI,
            Azimuth: r.Azimuth * 180 / Math.PI,
            VerticalPosition: zposition
        };
    }

    public getCameraPosition(): CameraPosition {
        return this.calculateCameraPositionInternal(this.world_rotation, this.camera_elevation, this.camera_distance, this.camera_zposition);
    }

    public getCameraPositionFiltered(): CameraPosition {
        return this.calculateCameraPositionInternal(this.world_rotation_filtered, this.camera_elevation_filtered, this.camera_distance_filtered, this.camera_zposition_filtered)
    }

    /*
     * Sets the screen size. This is NOT the canvas pixel size.
     */
    public setScreenDimensions(width: number, height: number){
        this.display_width = width;
        this.display_height = height;
        this.dispatchEvent(new DisplayParametersChangedEvent());
        this.recalculate_matrices();
    }

    // NOT THE CANVAS PIXEL SIZE
    public getScreenDimensions(){
        return ({
            width: this.display_width,
            height: this.display_height
        });
    }


    UECHeightToScreenPixels_equirect(position: UEC, height: number): ScreenPosition{
        let location_to_screenspace = (p: Vec2, h: number) => {
            let sp = this.world_transform.mul_vec4(Vec4.from_vff(p, h - 1, 1));
            sp = this.camera_transform.mul_vec4(sp);
            sp.x1 /= sp.x4;
            sp.x2 /= -sp.x4;
            sp.x3 /= sp.x4;
            sp.x1 += 1;
            sp.x2 += 1;
            sp.x1 /= 2;
            sp.x2 /= 2;
            sp.x1 *= this.display_width;
            sp.x2 *= this.display_height;
            sp.x3 = ((1 - sp.x3) * 1000) | 0;
            return sp.rgb();
        };
        let screenspace_pos = location_to_screenspace(new Vec2(position.x, position.y),height);
        return ScreenPosition.fromVec3(screenspace_pos);
    }

    public UECHeightToScreenPixels;

    UECHeightToScreenPixels_spherical(position: UEC, height: number): ScreenPosition{
        let location_to_screenspace = (p: Vec2, h: number) => {
            var px = (p.x1 + 0.5) * 2.0 * Math.PI;
            var py = -(p.x2 - 0.5) * Math.PI;
            let cy = Math.cos(py);
            var sp = new Vec4(
                Math.cos(px) * cy * h,
                Math.sin(px) * cy * h,
                Math.sin(py) * h,
                1.0
            );
            sp = this.world_transform.mul_vec4(sp);
            sp = this.camera_transform.mul_vec4(sp);
            sp.x1 /= sp.x4;
            sp.x2 /= -sp.x4;
            sp.x3 /= sp.x4;
            sp.x1 += 1;
            sp.x2 += 1;
            sp.x1 /= 2;
            sp.x2 /= 2;
            sp.x1 *= this.display_width;
            sp.x2 *= this.display_height;
            sp.x3 = ((1 - sp.x3) * 1000) | 0;
            return sp.rgb();
        };
        let screenspace_pos = location_to_screenspace(new Vec2(position.x, position.y),height);
        return ScreenPosition.fromVec3(screenspace_pos);
    }

    //calculate the coefficients of the fourth order animation polynomial
    //x0, x1: values at start and end
    //v0, v1: first order derivatives at start and end. 
    //a1: second order derivative at the end.
    genAnimPolynomial(x0: number, v0: number, x1: number, v1: number, a1: number): AnimPolynomial {
        let e = x0;
        let d = v0;
        let c = -(-12 * x1 + 12 * x0 + 6 * v1 + 6 * v0 - a1) / 2;
        let b = -8 * x1 + 8 * x0 + 5 * v1 + 3 * v0 - a1;
        let a = -(-6 * x1 + 6 * x0 + 4 * v1 + 2 * v0 - a1) / 2;
        return [a, b, c, d, e];
    }

    //Calculate the value of the animation polynomial at time t
    evalAnimPolynomial(p: AnimPolynomial, t: number): number {
        t = Math.min(1, Math.max(0, t));
        return (((p[0] * t + p[1]) * t + p[2]) * t + p[3]) * t + p[4];
    }

    //Calculate the derivative of the animation polynomial at time t
    evalAnimPolynomialDeriv(p: AnimPolynomial, t: number): number {
        if(t > 1 || t < 0) return 0;
        return ((p[0] * 4 * t + p[1] * 3) * t + p[2] * 2) * t + p[3];
    }
}