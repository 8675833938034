
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
@Component({
    props:{
        name: String
    }
})
export default class SvgIcon extends Vue {
    private bloburl: string = "";

    mounted() {
        Services.StaticToBlobService.getBlobUrl("/static/icons.svg", (b) => {
            this.bloburl = b;
            this.$forceUpdate();
        });
    }
}

