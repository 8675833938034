//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { LayerFilter } from "./RenderFilter";
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";

export class HillShadingFilter extends LayerFilter{
    constructor(){
        super(Services.GLService.Modules.effects.hill_shading, {
            "light_direction": Services.SettingsService.getSetting("Light Direction"),
            "shading_intensity": new Parameter("Shading Intensity", 0.5, "number", true, false).setShaderName("shading_intensity").setRange(0, 1),
            "shading_falloff": new Parameter("Shading Falloff", 0.0, "number", true, false).setShaderName("shading_falloff").setRange(-1, 1),
            "brightness": new Parameter("Brightness", 1.0, "number", true, false).setShaderName("brightness").setRange(0, 5)
        });
        this.human_readable_name = "Hill Shading";
    }
}