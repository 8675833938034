







import { DebugEvent } from '../services/DebugService';
import Vue from 'vue'
import Component from 'vue-class-component';
import {Services} from '../services/Services';
@Component({
})
export default class DebugComponent extends Vue{
    debugMessage: string = "";

    mounted(){
        Services.DebugService.addEventListener("Debug", (e: DebugEvent) => {
            this.debugMessage = e.debugMessage;
        })
    }
}
