//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { ServiceBarrier, Services } from './Services';

export class MouseMoveEvent extends Event{
    x: number;
    y: number;
    constructor(x: number, y: number){
        super("MouseMove");
        this.x = x;
        this.y = y;
    }
}

export class KeyDownEvent extends Event{
    key: string;
    keycode: string;
    event: KeyboardEvent;
    constructor(event: KeyboardEvent){
        super("KeyDown");
        this.event = event;
        this.key = event.key;
        this.keycode = event.code;
    }
}

export class MouseDownEvent extends Event{
    x: number;
    y: number;
    left: boolean;
    right: boolean;
    constructor(x: number, y: number, left: boolean, right: boolean){
        super("MouseDown");
        this.x = x;
        this.y = y;
        this.left = left;
        this.right = right;
    }
}

export class InteractionStateChangedEvent extends Event{
    old: InteractionState;
    state: InteractionState;
    constructor(old: InteractionState, state: InteractionState) {
        super("InteractionStateChanged");
        this.old = old;
        this.state = state;
    }
}

export enum InteractionState{
    Move,
    Selection,
    Overlay
}

export class InteractionService extends EventTarget{

    private target: string;
    public mouseMoveHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public mouseUpHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public mouseDownHandlers: Map<string, (e: MouseEvent)=>void> = new Map();
    public keyDownHandlers: Map<string, (e: KeyboardEvent)=>void> = new Map();
    public lastMousePosition: {x: number, y: number} = {x: 0, y: 0};
    public gamepadid: number;

    private interactionState: InteractionState = InteractionState.Move

    constructor(){
        super();
        document.addEventListener("mousemove", (e) => {
            this.lastMousePosition = {x: e.clientX, y: e.clientY};
            this.dispatchEvent(new MouseMoveEvent(e.clientX,e.clientY));
            if(this.mouseMoveHandlers.has(this.target)) this.mouseMoveHandlers.get(this.target)(e)
            else this.defaultHandler(e);
            Services.AdaptivePerformanceService.RequestRerender();
        });
        document.addEventListener("mouseup", (e) => {
            if(this.mouseUpHandlers.has(this.target)) this.mouseUpHandlers.get(this.target)(e)
            else this.defaultHandler(e);
        });
        document.addEventListener("mousedown", e => {
            if(this.mouseDownHandlers.has(this.target)) this.mouseDownHandlers.get(this.target)(e)
            this.dispatchEvent(new MouseDownEvent(e.clientX, e.clientY, e.buttons == 1, e.buttons==2));
        });
        document.addEventListener("keydown", e => {
            if(this.keyDownHandlers.has(this.target)) this.keyDownHandlers.get(this.target)(e)
            this.dispatchEvent(new KeyDownEvent(e));
        })
        this.mouseDownHandlers.set("threedee", (e) => {
            if (this.interactionState == InteractionState.Overlay) {
                if (e.buttons == 1){
                    Services.RenderService.createOverlay = true;
                    Services.AdaptivePerformanceService.RequestRerender();
                }
            } else if (this.interactionState == InteractionState.Selection) {
                if (e.buttons == 1) {
                    Services.SelectionService.startRectangleSelection();
                }
            }
        })
        this.mouseMoveHandlers.set("threedee", (e) => {
            if(this.interactionState == InteractionState.Move){
                if (e.buttons == 1){
                    if(e.ctrlKey){
                        Services.PositionService.tiltCamera(e.movementX, e.movementY)
                    } else {
                        Services.PositionService.moveCamera(e.movementX, e.movementY)
                    }
                }else if (e.buttons == 4){
                    Services.PositionService.tiltCamera(e.movementX, e.movementY)
                } else if(e.buttons == 2){
                    Services.RenderService.createOverlay = true;
                    Services.AdaptivePerformanceService.RequestRerender();
                }
            } else if (this.interactionState == InteractionState.Selection) {
                if (e.buttons == 1) {
                    Services.SelectionService.updateRectangleSelection();
                }
            }
        });
        //Handle new gamepad connections
        window.addEventListener("gamepadconnected", (e: GamepadEvent) => {
            console.log(`Connected gamepad ID ${e.gamepad.id} with ${e.gamepad.buttons.length} buttons and ${e.gamepad.axes.length} axes`);
            this.gamepadid = e.gamepad.index;
        });
        //Handle gamepad disconnections
        window.addEventListener("gamepaddisconnected", (e: GamepadEvent) => {
            console.log(`Disconnected gamepad with ID ${e.gamepad.id}`);
            if(this.gamepadid == e.gamepad.index){
                this.gamepadid = null;
                //Look for new gamepad
                //@ts-ignore
                for(let gp of Navigator.getGamepads()){
                    if(gp != null){
                        this.gamepadid = gp.index;
                        console.log(`Switching to gamepad ID {} with ${gp.buttons.length} buttons and ${gp.axes.length} axes`);
                    }
                }
            }
        });

        ServiceBarrier.wait().then(() => {
            requestAnimationFrame(() => this.updateGamepad());
        });
    }

    private updateGamepad(){
        let toggle_rotation_lock_energy = 0;
        let gamepad = this.getGamepad();
        if(gamepad != null){
            //See https://luser.github.io/gamepadtest/ for button config
            let left_stick_x = gamepad.axes[0] || 0;
            let left_stick_y = gamepad.axes[1] || 0;
            let right_stick_x = gamepad.axes[2] || 0;
            let right_stick_y = gamepad.axes[3] || 0;

            let speed = 10;
            let threshold = 0.2;
            let zoom_speed = 0.2;

            if((Math.abs(left_stick_x) > threshold) || Math.abs(left_stick_y) > threshold){
                Services.PositionService.tiltCamera(left_stick_x * speed * -1, left_stick_y * speed);
            }
            if((Math.abs(right_stick_x) > threshold) || Math.abs(right_stick_y) > threshold){
                Services.PositionService.moveCamera(right_stick_x * speed * -1, right_stick_y * speed * -1);
            }

            let left_back_trigger = gamepad.buttons[6];
            let right_back_trigger = gamepad.buttons[7];

            if(Math.abs(left_back_trigger?.value || 0) > threshold){
                Services.PositionService.zoomCamera(-1 * left_back_trigger.value * zoom_speed);
            }
            
            if(Math.abs(right_back_trigger?.value || 0) > threshold){
                Services.PositionService.zoomCamera(right_back_trigger.value * zoom_speed);
            }

            let button_a = gamepad.buttons[0];
            let button_b = gamepad.buttons[1];
            let button_c = gamepad.buttons[2];
            let button_d = gamepad.buttons[3];

            if(button_a?.pressed){
                let pos = Services.PositionService.getCameraPosition();
                pos.Azimuth = 0;
                pos.Elevation = 90;
                Services.PositionService.setCameraPosition(pos);
                Services.InteractionService.mousedown("compass");
            }

            toggle_rotation_lock_energy += button_b?.pressed ? 1 : -1;
            if(toggle_rotation_lock_energy < 0){
                toggle_rotation_lock_energy = 0;
            }

            if(toggle_rotation_lock_energy > 2){
                Services.PositionService.rotation_locked = !Services.PositionService.rotation_locked;
                toggle_rotation_lock_energy = -100;
            }

            let button_left_shoulder = gamepad.buttons[4];
            let button_right_shoulder = gamepad.buttons[5];
            let dpad_left = gamepad.buttons[14];
            let dpad_right =gamepad.buttons[15];
            let dpad_up = gamepad.buttons[12];
            let dpad_down = gamepad.buttons[13];

            if(button_left_shoulder?.pressed){Services.TimeService.moveTimeStart();}
            if(button_right_shoulder?.pressed){Services.TimeService.moveTimeEnd();}
            if(dpad_left?.pressed){Services.TimeService.moveTimeEarlier();}
            if(dpad_right?.pressed){Services.TimeService.moveTimeLater();}
            if(dpad_up?.pressed){Services.TimeService.increaseTimeRange();}
            if(dpad_down?.pressed){Services.TimeService.reduceTimeRange();}


            //console.log(`A0=${gamepad.axes[0]} A1=${gamepad.axes[1]}`);
        }
        requestAnimationFrame(() => this.updateGamepad());
    }

    public getGamepad(): Gamepad{
        if(this.gamepadid == null){
            return null;
        }else{
            return navigator.getGamepads()[this.gamepadid];
        }
    }

    mousedown(target: string, e?: MouseEvent){
        if(e){
            this.lastMousePosition = {x: e.clientX, y: e.clientY}
        }
        this.target = target;
    }

    setInteractionState(state: InteractionState){
        if(state != this.interactionState){
            this.dispatchEvent(new InteractionStateChangedEvent(this.interactionState, state));
            this.interactionState = state;
        }
    }

    getInteractionState() {
        return this.interactionState;
    }

    defaultHandler(e: MouseEvent){
    }

    getTarget(): string{
        return this.target;
    }

}