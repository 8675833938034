







































import { DialogBox } from '../services/DialogBoxService';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Services } from '../services/Services';
import SvgIcon from './guikit/svgIcon.vue';
@Component({
    components: {
        SvgIcon
    }
})
export default class LogoComponent extends Vue{

     openabout() {
        Services.DialogBoxService.insert("About", DialogBox.centered("AboutDialogue", "About the Digital Earth Viewer"));
    }
}
