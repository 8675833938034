//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { CompositionFilter } from "../modules/renderfilters/RenderFilter";
import { RenderLayer } from "../modules/RenderLayer";
import { RenderSource } from "../modules/rendersources/RenderSource";
import { UEC, UECArea } from "../modules/tile";
import { Mat4 } from "../modules/vecmat";
import { GLBarrier, Services } from "./Services";

export class ExtentVisualizationService{
    renderLayer: ExtentRenderLayer;
    constructor(){
        GLBarrier.wait().then(() => {
            this.renderLayer = new ExtentRenderLayer();
        });
    }

    public showExtent(extent: UECArea, zrange: [number, number]){
        this.renderLayer.setExtent(extent);
        this.renderLayer.setZRange(zrange);
        this.renderLayer.setVisible(true);
    }

    public hideExtent(){
        this.renderLayer.setVisible(false);
    }
}



class ExtentRenderLayer extends RenderLayer {
    source: ExtentRenderSource;

    constructor(){
        super();
        this.source = new ExtentRenderSource();
        this.compositionFilter = new CompositionFilter(Services.GLService.Modules.compositing.selection);
    }

    setExtent(area: UECArea) {
        this.source.extent = area;
    }

    setZRange(zrange: [number, number]){
        this.source.zrange = zrange;
    }

    setColor(color:  number[]){
        this.source.color = color;
    }

    setVisible(visible: boolean){
        this.source.render = visible;
    }
}

class ExtentRenderSource extends RenderSource {
    public extent: UECArea;
    public zrange: [number, number] = null;
    public color = [0.0, 1.0, 0.0, 1.0];
    public render: boolean = false;
    constructor(){
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.selection;
        this.name = "SelectionRenderSource";
        this.parameters = {};
        this.slots = {};
        this.extent = new UECArea(new UEC(0,0), new UEC(0,0));
    }

    execute(context: {[name: string]: WebGLRenderingContext | any; }) {
        if(!this.render){
            return;
        }
        let srect = this.extent;
        if(srect.extent.x == 0 || srect.extent.y == 0)return;
        super.execute(context);
        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        let buff = Services.GLService.Geometries.selection_box;
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 3, context.gl.FLOAT, false, 0, 0);

        let cam_position = Services.PositionService.getCameraPositionFiltered();
        switch (Services.PositionService.projection_mode) {
            case "EQUIRECT": {
                if(cam_position.Longitude < -90 && srect.position.x > 0.5) {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x - 1, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                } else if (cam_position.Longitude > 90 && srect.position.x < 0.5) {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x + 1, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                } else {
                    context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(srect.extent.x, 0, 0, srect.position.x, 0, srect.extent.y, 0, srect.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                }
                break;
            }
            case "SPHERE": {
                break;
            }
            case "POLAR": {
                break;
            }
        }
        let minmax = Services.RenderLayerService.get_visible_renderlayers().map(r => r.source.getVerticalBoundsWorldSpace()).reduce((minmax, cv) => [Math.min(cv[0], minmax[0]), Math.max(cv[1], minmax[1])], [1, 1]);
        if(this.zrange){
            minmax = this.zrange;
        }
        context.gl.uniform1f(this.shader.uniforms["selection_zmin"], minmax[0]);
        context.gl.uniform1f(this.shader.uniforms["selection_height"], minmax[1] - minmax[0]);

        context.gl.uniform2f(this.shader.uniforms["selection_coord"], srect.position.x, srect.position.y);
        context.gl.uniform2f(this.shader.uniforms["selection_size"], srect.extent.x, srect.extent.y);

        context.gl.uniform4f(this.shader.uniforms["selection_color"], this.color[0], this.color[1], this.color[2], this.color[3]);

        context.gl.drawArrays(buff.mode, buff.start, buff.length);
    }
}