
























































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';

@Component({
    components: {
        SvgIcon,
        ScrollBox
    }
})
export default class AboutDialogue extends Vue{
    hide() {
        Services.DialogBoxService.remove("About");
        window.localStorage.setItem("license_displayed", "true");
    }    
}
