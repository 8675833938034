







import Vue from 'vue';
import { Overlay } from "../../services/OverlayService";
import { FormattingService } from "../../services/FormattingService";
import Component from 'vue-class-component';
import { Services } from '../../services/Services';
import { GlobalTimeRangeChangedEvent } from '../../services/TimeService';
import { SourceLayerInfo } from '../../services/SourceInfoService';
import { SamplePoint } from '../../services/PointSamplingService';
@Component({
    props:{
        overlay: Overlay
    }
})
export default class ScalarOverlay extends Vue {
    raw_value: SamplePoint = null;
    timeout = null;
    async mounted(){
        /*
        await this.sample_value();
        Services.TimeService.addEventListener("GlobalTimeRangeChanged", async (e: GlobalTimeRangeChangedEvent) => {
            if (this.timeout != null){
                clearTimeout(this.timeout);
            } 
            this.timeout = setTimeout(async () => {
                await this.sample_value(); 
                this.timeout = null; 
            }, 500);
        });
        */
    }

    /*
    async sample_value(){
        let sli:SourceLayerInfo = this.$props.overlay.sourcelayerinfo;
        this.raw_value = await Services.PointSamplingService.sample_nearest(sli, this.$props.overlay.location,this.$props.overlay.height, Services.TimeService.getMeanTime(),Services.TimeService.getMeanTime());
    }
    */

    get value() {
        if(this.raw_value != null){
            return FormattingService.num_to_string_unit(this.raw_value.value[0], this.$props.overlay.unit, 3)
        }else{
            return FormattingService.num_to_string_unit(this.$props.overlay.information[0], this.$props.overlay.unit, 3);
        }
    }
}
