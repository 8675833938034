//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { PositionService } from './PositionService';
import { InitializationService } from './InitializationService';
import { InteractionService } from './InteractionService';
import { GLService } from './GLService';
import { ColormapService } from './ColormapService';
import { TileCacheService } from './TileCacheService';
import { TimeService } from './TimeService';
import { RenderLayerService } from './RenderLayerService';
import { SourceInfoService } from './SourceInfoService';
import { OverlayService } from './OverlayService';
import { DialogBoxService } from './DialogBoxService';
import { AdaptivePerformanceService } from './AdaptivePerformanceService';
import { RequiredTilesService } from './RequiredTilesService';
import { Barrier } from '../modules/barrier';
import { RenderService } from './RenderService';
import { UIDService } from './UIDService';
import { StitchedTilesService } from './StitchedTilesService';
import { LayerMetaDataService} from './LayerMetadataService';
import { PointSamplingService } from './PointSamplingService';
import { ParameterBroadcastService } from './ParameterBroadcastService';
import { ParticlesService } from './ParticlesService';
import { CameraService } from './CameraService';
import { StaticToBlobService } from './StaticToBlobService';
import { SelectionService } from './SelectionService';
import {ProgressReportingDownloadService} from './ProgressReportingDownloadService';
import { PlaybackService } from './PlaybackService';
import { ExtentVisualizationService } from './ExtentVisualizationService';
import { SettingsService } from './SettingsService';
import {TextRenderingService} from './TextRenderingService';
import { DebugService } from './DebugService';
import { SynchronizationService } from './SynchronizationService';
import { RecordingService } from './RecordingService';
import { FlexibleTimeBaseService } from './FlexibleTimeBaseService';

//Barrier for primary Service initialization
export const ServiceBarrier = new Barrier();
//Barrier that shows that webgl context dependent services have been intitialized
export const GLBarrier = new Barrier();

class ServicesClass{
    public PositionService: PositionService;
    public InitializationService: InitializationService; 
    public InteractionService: InteractionService;
    public GLService: GLService;
    public ColormapService: ColormapService;
    public TileCacheService: TileCacheService;
    public TimeService: TimeService;
    public RenderLayerService: RenderLayerService;
    public SourceInfoService: SourceInfoService;
    public OverlayService: OverlayService;
    public DialogBoxService: DialogBoxService;
    public RequiredTilesService: RequiredTilesService;
    public AdaptivePerformanceService: AdaptivePerformanceService;
    public RenderService: RenderService;
    public UIDService: UIDService;
    public StitchedTilesService: StitchedTilesService;
    public LayerMetadataService: LayerMetaDataService;
    public PointSamplingService: PointSamplingService;
    public ParameterBroadcastService: ParameterBroadcastService;
    public ParticlesService: ParticlesService;
    public CameraService: CameraService;
    public StaticToBlobService: StaticToBlobService;
    public SelectionService: SelectionService;
    public ProgressReportingDownloadService: ProgressReportingDownloadService;
    public PlaybackService: PlaybackService;
    public ExtentVisualizationService: ExtentVisualizationService;
    public SettingsService: SettingsService;
    public TextRenderingService: TextRenderingService;
    public DebugService: DebugService;
    public SynchronizationService: SynchronizationService;
    public RecordingService: RecordingService;
    public FlexibleTimeBaseService: FlexibleTimeBaseService;

    constructor(){
        this.UIDService = new UIDService();
        this.FlexibleTimeBaseService = new FlexibleTimeBaseService();
        this.SettingsService = new SettingsService();
        this.ProgressReportingDownloadService = new ProgressReportingDownloadService();
        this.ParameterBroadcastService = new ParameterBroadcastService();
        this.PositionService = new PositionService(this.FlexibleTimeBaseService);
        this.LayerMetadataService = new LayerMetaDataService();
        this.InitializationService = new InitializationService(this.PositionService);
        this.InteractionService = new InteractionService();
        this.TimeService = new TimeService();
        this.RenderLayerService = new RenderLayerService();
        this.SourceInfoService = new SourceInfoService();
        this.OverlayService = new OverlayService();
        this.DialogBoxService = new DialogBoxService();
        this.RequiredTilesService = new RequiredTilesService();
        this.AdaptivePerformanceService = new AdaptivePerformanceService();
        this.PointSamplingService = new PointSamplingService();
        this.CameraService = new CameraService();
        this.StaticToBlobService = new StaticToBlobService();
        this.SelectionService = new SelectionService();
        this.PlaybackService = new PlaybackService();
        this.ExtentVisualizationService = new ExtentVisualizationService();
        this.TextRenderingService = new TextRenderingService();
        this.DebugService = new DebugService();
        this.SynchronizationService = new SynchronizationService();
        this.RecordingService = new RecordingService();
        ServiceBarrier.resolve();
    }

    initGL(gl: WebGLRenderingContext){
        this.GLService = new GLService(gl);
        this.ColormapService = new ColormapService(this.GLService.GL);
        this.TileCacheService = new TileCacheService(this.GLService.GL);
        this.RenderService = new RenderService(this.GLService.getContext());
        this.StitchedTilesService = new StitchedTilesService(this.GLService.getContext());
        this.ParticlesService = new ParticlesService(this.GLService.getContext());
        GLBarrier.resolve();
    }
}

export const Services = new ServicesClass();


