




























import Vue from 'vue';
import Component from 'vue-class-component';
import { RenderLayer } from '../modules/RenderLayer';
import {LayersChangedEvent, SelectedLayerChangedEvent} from '../services/RenderLayerService'
import LayerComponent from './layercomponent.vue';
import SvgIcon from './guikit/svgIcon.vue';
import ScrollBox from './guikit/scrollbox.vue';
import { Services } from '../services/Services';
import { Coord, UEC } from '../modules/tile';
import { DialogBox } from '../services/DialogBoxService';

@Component({
    components: {
        LayerComponent,
        SvgIcon,
        ScrollBox
    }
})
export default class LayerStack extends Vue{
    hidden = true;
    layers: RenderLayer[] = [];
    selectedLayer: RenderLayer = null;
    selectedLayerId: number = -99;

    rerender(){
        Services.AdaptivePerformanceService.RequestRerender();
    }

    mounted(){
        this.layers = Services.RenderLayerService.getLayers();
        this.selectedLayer = Services.RenderLayerService.getSelectedLayer();
        Services.RenderLayerService.addEventListener(
            "LayersChanged",
            (e: LayersChangedEvent) => {
                this.layers = e.layers;
                this.$forceUpdate();
            }
        );
        Services.RenderLayerService.addEventListener(
            "SelectedLayerChanged",
            (e:SelectedLayerChangedEvent) => {
                this.selectedLayer = e.selectedLayer;
                this.selectedLayerId = e.selectedLayer.id;
                this.$forceUpdate();
            }
        );
    }

    isFirstLayer(layer: RenderLayer){
        return this.layers.indexOf(layer) == 0;
    }

    isLastLayer(layer: RenderLayer){
        return this.layers.indexOf(layer) == (this.layers.length -1);
    }

    toggle_hidden(){
        this.hidden = !this.hidden;
        Services.AdaptivePerformanceService.RequestRerender();
    }

    hideExtent(){
        Services.ExtentVisualizationService.hideExtent();
    }

    showExtent(layer: RenderLayer){
        Services.ExtentVisualizationService.showExtent(layer.getExtent(), layer.getVerticalBoundsWorldspace());
    }
    
    layerUp(layer: RenderLayer){
        Services.RenderLayerService.layerUp(layer);
        Services.AdaptivePerformanceService.RequestRerender();
    }

    layerDown(layer: RenderLayer){
        Services.RenderLayerService.layerDown(layer);
        Services.AdaptivePerformanceService.RequestRerender();
    }

    layerSettings(layer: RenderLayer){
        this.selectLayer(layer);
        Services.DialogBoxService.try_insert("layer-settings", new DialogBox("LayerComponent", window.innerWidth / 2, window.innerHeight / 2, "Layer Settings", "translate(-50%, -50%)"))
    }

    layerDelete(layer: RenderLayer){
        Services.RenderLayerService.deleteLayer(layer);
        Services.AdaptivePerformanceService.RequestRerender();
    }

    selectLayer(layer: RenderLayer){
        if(Services.RenderLayerService.getSelectedLayer() != layer){
            Services.RenderLayerService.selectLayer(layer);
            Services.AdaptivePerformanceService.RequestRerender();
        }
    }

    mousedown_evt(e){
        Services.InteractionService.mousedown("layerstack");
        return true;
    }


    zoomToLayer(layer: RenderLayer){
        this.hideExtent();
        this.selectLayer(layer);
        //BUG: when displacement is larger than data, will likely bound on displacement and not on data
        let extent = layer.getExtent();
        let center = new UEC(extent.position.x + (0.5 * extent.extent.x), extent.position.y + (0.5 * extent.extent.y));
        let coord = Coord.from_UEC(center);
        let position = Services.PositionService.getCameraPosition();
        position.Latitude = coord.lat;
        position.Longitude = coord.lon;
        position.Elevation = 45;
        position.Azimuth = 315;
        position.Distance = Math.max(extent.extent.x * 2 * Math.PI, extent.extent.y * Math.PI); //Works only on 45° fov
        if(position.Distance == 0){
            position.Distance = 0.1;
        }
        let zbounds = layer.getVerticalBoundsNative()
        if(zbounds)
            position.VerticalPosition = (zbounds[0] + zbounds[1]) / 2;
        Services.PositionService.setCameraPosition(position);
    }
    
}
