//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import * as d3 from '../d3.js';

type TripleArray= [number, number, number][];

function makeScale(data, accessor, range){
    return d3.scaleLinear()
        .domain(d3.extent(data, accessor))
        .range(range).nice()
        }

function handleMouseOverScatter(d, i) {  // Add interactivity

    // Use D3 to select element, change color and size
    d3.select(this)
        .attr("fill-opacity", "1")
        .attr("stroke", "black")
        .attr("r", "5");
    var div = d3.select("#d3-tooltip")
            //@ts-ignore
     div.transition()
               .duration(50)
               .style("opacity", 1)
              
;
    
    let num = d[0].toString() + "°, " + d[1].toString();
    div.html(num)
            //@ts-ignore
               .style("left", (d3.event.pageX + 10) + "px")
            //@ts-ignore
               .style("top", (d3.event.pageY - 15) + "px");
      }

function handleMouseOutScatter(d, i) {
    // Use D3 to select element, change color back to normal
    d3.select(this)
        .attr("fill-opacity", "0.5")
        .attr("r", "2")
        .attr("stroke", "none")
    var div = d3.select("#d3-tooltip")
        //@ts-ignore
   div.transition()
       .duration(50)
       .style("opacity", 0);
  }

function handleMouseOverXY(d, i) {  // Add interactivity

    // Use D3 to select element, change color and size
    d3.select(this)
        .attr("fill-opacity", "1")
        .attr("fill", "red")
    
    var div = d3.select("#d3-tooltip")
            //@ts-ignore
    div.transition()
        .duration(50)
        .style("opacity", 1)
              
;
    
    let num = d[0].toString() + ", " + d[1].toString();
    div.html(num)
            //@ts-ignore
               .style("left", (d3.event.pageX + 10) + "px")
                       //@ts-ignore
               .style("top", (d3.event.pageY - 15) + "px");
      }

function handleMouseOutXY(d, i) {
    // Use D3 to select element, change color back to normal
    d3.select(this)
        .attr("fill-opacity", "0")
    var div = d3.select("#d3-tooltip")
            //@ts-ignore
    div.transition()
       .duration(50)
       .style("opacity", 0);
  }

function handleMouseOverFeather(d, i) {  // Add interactivity

    // Use D3 to select element, change color and size
    d3.select(this)
        .attr("opacity", "1")
        .attr("stroke", "black")
        .attr("stroke-width", "2")
    
     var div = d3.select("#d3-tooltip")
             //@ts-ignore
     div.transition()
               .duration(50)
               .style("opacity", 1)
              
;
    
    let num = d[2].toString() + "°, " + d[1].toString() + "mm/s";
    div.html(num)
            //@ts-ignore
               .style("left", (d3.event.pageX + 10) + "px")
                       //@ts-ignore
               .style("top", (d3.event.pageY - 15) + "px");
      }

function handleMouseOutFeather(d, i) {
    // Use D3 to select element, change color back to normal
    d3.select(this)
        .attr("opacity", "0.4")
        .attr("stroke", "blue")
        .attr("stroke-width", "1")
    
    var div = d3.select("#d3-tooltip")
            //@ts-ignore
    div.transition()
       .duration(50)
       .style("opacity", 0);
  }

function arrayAccum(array){
    return array.map(function(d,i,arr){ 
        return d3.sum(arr.slice(0,i+1))
    });
}

export function drawScatterPlot(data: TripleArray, svg, xLabel, yLabel){
    
    //d3 vars
    var pxX = svg.getAttribute("width")
    var pxY = svg.getAttribute("height")
    let idx = Array(data.length).fill(0).map((_, idx) => idx)
    
    var scX = makeScale(data, d=>d[0], [pxX*0.1,pxX*0.9]);
    var scY = makeScale(data, d=>d[1], [pxY*0.9,pxY*0.1]);
    var scZ =  d3.scaleSequential().domain(d3.extent(data, d=>d[2]))
    .interpolator(d3.interpolateViridis);

    svg = d3.select(svg);
    let g = svg.append("g")

    if(d3.select("#d3-tooltip")[0]==null){
        var div = svg.append("div")
         .attr("id", "d3-tooltip")
         .style("opacity", 0);    
    }
    //circles
    g.selectAll("circle").remove();

    g.selectAll("circle").data(data).enter()
        .append("circle")
        .attr("r", "2")
        .attr("fill", d => scZ(d[2]))
        .attr("fill-opacity", "0.5")
        .attr("cx", d=>scX(d[0]))
        .attr("cy", d => scY(d[1]))
        .on('mouseover', handleMouseOverScatter)
        .on('mouseout', handleMouseOutScatter);

    //axes
    d3.select(".y.axis").remove();
    d3.select(".y.axis").remove();

    var axYMaker = d3.axisLeft(scY);
    var axXMaker = d3.axisBottom(scX)

    svg.append("g").attr("class", "y axis")
        .attr("id","axY").call(axYMaker)
        .attr("transform", "translate("+pxX*0.1+",0)");

    svg.append("g").attr("class", "x axis")
        .attr("id","axX").call(axXMaker)
        .attr("transform", "translate(0,"+pxY*0.90+")");

    svg.select(".y.axis")
        .transition()
        .duration(10)
        .call(axYMaker);

    svg.select(".x.axis")
        .transition()
        .duration(10)
        .call(axXMaker);

    svg.append("text")             
    .attr("transform",
        "translate(" + (pxX/2) + " ," + 
                       (pxY - pxY*0.025) + ")")
    .style("text-anchor", "middle")
    .text(xLabel);

    svg.append("text")             
    .attr("transform",
        "translate(" + (pxX*0.07) + " ," + 
                       (pxY*0.5) + ")" +
        "rotate(" + 270 + ")")
    .style("text-anchor", "middle")
    .text(yLabel);
    
}

export function drawXYPlot(data, svg, xLabel, yLabel){
    
    //d3 vars
    var pxX: number = parseFloat(document.getElementById('graph1').getAttribute("width"))
    var pxY: number = parseFloat(document.getElementById('graph1').getAttribute("height"))

    var scX = makeScale(data, d=>d[0], [pxX*0.1,pxX*0.9]);
    var scY = makeScale(data, d=>d[1], [pxY*0.9,pxY*0.1]);
        //@ts-ignore
    var svg = d3.select("#graph1");
    let g = svg.append("g")

    if(d3.select("#d3-tooltip")[0]==null){
        var div = d3.select("body").append("div")
         .attr("id", "d3-tooltip")
         .style("opacity", 0);    
    }
    //circles
            g.selectAll("circle").remove();
            
            g.selectAll("circle").data(data).enter()
                .append("circle")
                .attr("r", "5")
                .attr("fill", "red")
                .attr("fill-opacity", "0")
                .attr("cx", d=>scX(d[0]))
                .attr("cy", d => scY(d[1]))
                .on('mouseover', handleMouseOverXY)
                .on('mouseout', handleMouseOutXY);

            //lines
            var lineMkr = d3.line().curve(d3.curveStep)
        //@ts-ignore
                        .x(d=>scX(d[0]))
                        .y(d => scY(d[1]))
            
            g.selectAll("path").remove();
            g.append("path").attr("fill", "none")
                            .attr("stroke", "black")
                            .attr("d", lineMkr(data));
    
    d3.select(".y.axis").remove();
    d3.select(".y.axis").remove();

    var axYMaker = d3.axisLeft(scY);
    var axXMaker = d3.axisBottom(scX)

    svg.append("g").attr("class", "y axis")
        .attr("id","axY").call(axYMaker)
        .attr("transform", "translate("+pxX*0.1+",0)");

    svg.append("g").attr("class", "x axis")
        .attr("id","axX").call(axXMaker)
        .attr("transform", "translate(0,"+pxY*0.90+")");

    svg.select(".y.axis")
        .transition()
        .duration(10)
        .call(axYMaker);

    svg.select(".x.axis")
        .transition()
        .duration(10)
        .call(axXMaker);
    
    svg.append("text")             
      .attr("transform",
            "translate(" + (pxX/2) + " ," + 
                           (pxY - pxY*0.025) + ")")
      .style("text-anchor", "middle")
      .text(xLabel);
    
    svg.append("text")             
      .attr("transform",
            "translate(" + (pxX*0.07) + " ," + 
                           (pxY*0.5) + ")" +
            "rotate(" + 270 + ")")
      .style("text-anchor", "middle")
      .text(yLabel);
}

export function drawFeatherPlot(data, svg){
    
    //d3 vars
    var pxX: number = parseFloat(document.getElementById('graph1').getAttribute("width"))
    var pxY: number = parseFloat(document.getElementById('graph1').getAttribute("height"))

    var scIdx = makeScale(data, d=>d[0], [pxX*0.1,pxX*0.9])
    var scMags = d3.scaleLinear()
                    .domain([0,d3.max(data, d=>d[1])])
                    .range([0,pxY*0.4]) // scale from pxY*0.1 to pxY*0.9/2
    var scAxY = d3.scaleLinear()
                .domain([-d3.max(data, d=>d[1]),d3.max(data, d=>d[1])])
                .range([pxY*0.9,pxY*0.1])
        //@ts-ignore
    var svg = d3.select("#graph1");
    let g = svg.append("g")

    if(d3.select("#d3-tooltip")[0]==null){
        var div = d3.select("body").append("div")
         .attr("id", "d3-tooltip")
         .style("opacity", 0);    
    }
    
    //arrows
    g.selectAll("use").remove();

    g.selectAll("use").data(data).enter()
        .append("use")
        .attr("href", "#arrow")
        .attr("stroke", "blue")
        .attr("opacity", "0.4")
        .attr("stroke-width", "1")
        .attr("transform", 
              d=>"translate("+scIdx(d[0])+","+pxY*0.5+") "+
                "rotate("+d[2]+","+0+","+0+") "+
                "scale(1,-"+scMags(d[1])+")")
        .on('mouseover', handleMouseOverFeather)
        .on('mouseout', handleMouseOutFeather);

    g.append("use")
        .attr("href", "#arrow")
        .attr("stroke", "red")
        .attr("stroke-width", "1")
    .attr("transform", 
              "translate("+scIdx(0)+","+pxY*0.5+") "+
                "rotate(45,"+0+","+0+") "+
                "scale(1,-"+scMags(d3.mean(data, d=>d[1]))+")")
    
    //axes
    d3.select(".y.axis").remove();
    d3.select(".y.axis").remove();

    var axYMaker = d3.axisLeft(scAxY);
    var axXMaker = d3.axisBottom(scIdx)

    svg.append("g").attr("class", "y axis")
    .attr("id","axY").call(axYMaker)
    .attr("transform", "translate("+pxX*0.1+",0)");

    svg.append("g").attr("class", "x axis")
    .attr("id","axX").call(axXMaker)
    .attr("transform", "translate(0,"+pxY*0.5+")");

    svg.select(".y.axis")
    .transition()
    .duration(10)
    .call(axYMaker);

    svg.select(".x.axis")
    .transition()
    .duration(10)
    .call(axXMaker);
    svg.append("text")             
        .attr("transform",
            "translate(" + (pxX*0.07) + " ," + 
                           (pxY*0.5) + ")" +
            "rotate(" + 270 + ")")
        .style("text-anchor", "middle")
        .text("magnitude [mm/s]");
}
