























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { FormattingService } from '../services/FormattingService';
import { Services } from '../services/Services';
import { SelectedLayerChangedEvent } from '../services/RenderLayerService';
import { CurrentTimeChangedEvent, GlobalTimeRangeChangedEvent } from '../services/TimeService';
import { RenderLayer } from '../modules/RenderLayer';
import { PlaybackStateChangedEvent } from '../services/PlaybackService';

@Component({
    components: {
    },
    props: {
        value: Number
    }
})
export default class TimeSlider extends Vue{
    playback_state: boolean = false;

    container_width = 1;
    container_left = 0;
    zoom_ratio = 1

    value_min = 0;
    value_max = 0;

    label_min = "";
    label_gmin = "";
    label_max = "";
    label_gmax = "";
    label_mid = "";

    render_layer_ranges: {name: string, min: number, max: number}[] = [];
    overall_range: [number, number] = [Infinity, -Infinity];

    is_active(l: RenderLayer){
        return Services.RenderLayerService.isSelectedLayer(l);
    }

    width_from_range(start, end): number {
        if(!isFinite(start)) start = this.overall_range[0];
        if(!isFinite(end)) end = this.overall_range[1];
        let r = (end - start) / (this.overall_range[1] - this.overall_range[0]);
        if(!isFinite(r))return 1;
        return r;
    }
    
    left_from_range(start): number {
        if(!isFinite(start)) start = this.overall_range[0];
        let r = (start - this.overall_range[0]) / (this.overall_range[1] - this.overall_range[0]);
        if(!isFinite(r))return 0;
        return r;
    }

    pointer_left(time): number {
        time = (time - this.overall_range[0]) / (this.overall_range[1] - this.overall_range[0])
        let visbounds = document.getElementById("timeslider-container").getBoundingClientRect();
        let innerbounds = document.getElementById("timeslider-fullrange-container").getBoundingClientRect();
        let r = (time * innerbounds.width - (visbounds.x - innerbounds.x)) / visbounds.width;
        if(!isFinite(r))return 0.5;
        return r;
    }

    update_labels(){
        this.label_min = FormattingService.time_to_string(Services.TimeService.value_min);
        this.label_max = FormattingService.time_to_string(Services.TimeService.value_max);
        this.label_mid = this.label_min + " - " + this.label_max;
        document.getElementById("timeslider-ranged-min-anchor").style.left = this.pointer_left(Services.TimeService.value_min) * 100 + "%";
        document.getElementById("timeslider-ranged-max-anchor").style.left = this.pointer_left(Services.TimeService.value_max) * 100 + "%";
        document.getElementById("timeslider-single-anchor").style.left = this.pointer_left((Services.TimeService.value_min + Services.TimeService.value_max) / 2) * 100 + "%";
        requestAnimationFrame(this.update_labels);
    }

    async mounted(){
        Services.TimeService.addEventListener("GlobalTimeRangeChanged", (e: GlobalTimeRangeChangedEvent) => {
            this.render_layer_ranges = e.renderLayerRanges;
            if(Services.RenderLayerService.getSelectedLayer()){
                let r = Services.RenderLayerService.getSelectedLayer().getTimeRange();
                if(r) this.overall_range = r;
                else this.overall_range = Services.TimeService.overall_range;
                //Filtered formatting for both of the labels
                let gmin_value = this.overall_range[0];
                if((!!gmin_value) && (!isNaN(gmin_value)) && (isFinite(gmin_value))){
                    this.label_gmin = FormattingService.time_to_string_shorter(gmin_value);
                }
                let gmax_value = this.overall_range[1];
                if((!!gmax_value) && (!isNaN(gmax_value)) && (isFinite(gmax_value))){
                    this.label_gmax = FormattingService.time_to_string_shorter(gmax_value);
                }
                if(gmin_value == gmax_value){
                    this.label_gmin = "";
                    this.label_gmax = "";
                }
            }else{
                this.overall_range = Services.TimeService.overall_range;
            }
        });
        Services.TimeService.addEventListener("CurrentTimeChanged", (e:CurrentTimeChangedEvent) => {
            if(e.secondary){
                return;
            }
            this.value_min = e.time_min;
            this.value_max = e.time_max;
        });
        Services.RenderLayerService.addEventListener("SelectedLayerChanged", (e: SelectedLayerChangedEvent) => {
            if(Services.RenderLayerService.getSelectedLayer()){
                let r = Services.RenderLayerService.getSelectedLayer().getTimeRange();
                if(r) this.overall_range = r;
                else this.overall_range = Services.TimeService.overall_range;
            }else{
                this.overall_range = Services.TimeService.overall_range;
            }
        });
        Services.InteractionService.mouseMoveHandlers.set("timeslider_both", (e: MouseEvent) => {
            if(e.buttons == 1)this.slider_move(e, "both");
        });
        Services.InteractionService.mouseMoveHandlers.set("timeslider_min", (e: MouseEvent) => {
            if(e.buttons == 1)this.slider_move(e, "min");
        })
        Services.InteractionService.mouseMoveHandlers.set("timeslider_max", (e: MouseEvent) => {
            if(e.buttons == 1)this.slider_move(e, "max");
        })
        requestAnimationFrame(this.update_labels);
        Services.PlaybackService.addEventListener("PlaybackStateChanged", (e: PlaybackStateChangedEvent) => this.playback_state = e.state);
    }

    slider_move(e, end) {
        let clientRect = document.getElementById("timeslider-fullrange-container").getBoundingClientRect();
        let mouseX = e.clientX - clientRect.x;
        let xpos = Math.min(1.0, Math.max(0.0, mouseX / clientRect.width));
        if(end == "min") {
            this.value_min = Math.min(xpos * (this.overall_range[1] - this.overall_range[0]) + this.overall_range[0], this.value_max);
            this.value_max = Math.min(this.value_max, this.overall_range[1]);
            }
        if(end == "max") {
            this.value_max = Math.max(xpos * (this.overall_range[1] - this.overall_range[0]) + this.overall_range[0], this.value_min);
            this.value_min = Math.max(this.value_min, this.overall_range[0]);
            }
        if(end == "both") {
            let rlen = this.value_max - this.value_min;
            this.value_min = Math.max(this.overall_range[0], xpos * (this.overall_range[1] - this.overall_range[0]) + this.overall_range[0] - rlen / 2);
            this.value_max = Math.min(this.overall_range[1], xpos * (this.overall_range[1] - this.overall_range[0]) + this.overall_range[0] + rlen / 2);
        }
        Services.TimeService.setCurrentTimeRange(this.value_min, this.value_max, true);
        Services.AdaptivePerformanceService.RequestRerender();
}

    mousedown_evt(e){
        let clientRect = document.getElementById("timeslider-fullrange-container").getBoundingClientRect();
        let mouseX = e.clientX - clientRect.x;
        let xpos = Math.min(1.0, Math.max(0.0, mouseX / clientRect.width));
        let minref = this.pointer_left(Services.TimeService.value_min);
        let maxref = this.pointer_left(Services.TimeService.value_max);
        if(xpos < (minref != maxref ? minref : minref - 0.05)){
            Services.InteractionService.mousedown("timeslider_min");
            if(e.buttons == 1)this.slider_move(e, "min");
        }else if(xpos > (minref != maxref ? maxref : maxref + .05)){
            Services.InteractionService.mousedown("timeslider_max");
            if(e.buttons == 1)this.slider_move(e, "max");
        }else {
            Services.InteractionService.mousedown("timeslider_both");
            if(e.buttons == 1)this.slider_move(e, "both");
        }
    }

    mousewheel_evt(e){
        let d = e.deltaY ? Math.sign(e.deltaY): 0;
        this.zoom_ratio *= Math.exp(d * -0.1);
    }

    playbutton_click(e){
        Services.PlaybackService.togglePlaying();
    }
}
