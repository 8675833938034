
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import SvgIcon from './guikit/svgIcon.vue';
import { Coord } from '../modules/tile';
import { FormattingService as formatter } from '../services/FormattingService';
import { Overlay } from '../services/OverlayService';
import { Services } from '../services/Services';

/*
 * Import and register your custom overlays here.
 */ 
import ScalarOverlay from './overlays/scalarOverlay.vue';
import ColorOverlay from './overlays/colorOverlay.vue';
import TurbidityOverlay from './overlays/turbidityOverlay.vue';
@Component({
    components: {
        SvgIcon,
        ScalarOverlay,
        ColorOverlay,
        TurbidityOverlay
    }
})
export default class Overlays extends Vue{

    overlays: Overlay[] = [];

    position_from_overlay(overlay: Overlay): {x:string, y: string, z: string}{
        let position = {
            x: overlay.screen_position.x1 + "px",
            y: overlay.screen_position.x2 + "px",
            z: overlay.hovered ? "2000" : overlay.screen_position.x3 + ""
        };
        return position;
    }

    remove_overlay(overlay: Overlay) {
        Services.OverlayService.removeOverlay(overlay);
    }

    coordinates(overlay: Overlay): string{
        return formatter.UEC_to_coord_string(overlay.location, 5) + ", " + formatter.num_to_string_unit(overlay.height, "m", 3);
    }

    move_to(overlay: Overlay) {
        let coord = Coord.from_UEC(overlay.location);
        let old_position =  Services.PositionService.getCameraPosition();
        Services.PositionService.setCameraPosition({
            Latitude: coord.lat,
            Longitude: coord.lon,
            Elevation: old_position.Elevation,
            Azimuth: old_position.Azimuth,
            Distance: old_position.Distance,
            VerticalPosition: overlay.layer.source.applyScaling(overlay.height)
        });
    }

    mousedown_evt(){
        Services.InteractionService.mousedown("overlay");
    }

    async mounted(){
        this.overlays = Services.OverlayService.getOverlays();
        Services.OverlayService.addEventListener("OverlaysChanged", () => this.$forceUpdate());
    }

    mouseenter(overlay){
        overlay.hovered = true;
    }

    mouseleave(overlay){
        overlay.hovered = false;
    }
}
